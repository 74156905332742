import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import he from "he";
import {
  Spinner,
  Pane,
  Tooltip,
  Position,
  Paragraph,
  Icon,
  SelectMenu,
  IconButton,
  SegmentedControl,
  Avatar
} from "evergreen-ui";
import * as dateFns from "date-fns";
import Helmet from "react-helmet";
import useViewportSizes from "use-viewport-sizes";

import extractDomain from "extract-domain";

import { useFetchAPI } from "../../../helpers/data";
import { CSMS, domainToCustomerId } from "../../../constants";

import { Row, Card, Header, Center, Main } from "../../../components/base";
import LifecycleStagePill from "../../../components/LifecycleStagePill";
import { useParams } from "react-router-dom";
import { useHover } from "../../../hooks";

import { COLOR_RANGE, RITEKIT_URL } from "../../../constants";

import {
  Sparkline,
  LineSeries,
  BarSeries,
  HorizontalReferenceLine,
  BandLine,
  PatternLines,
  PointSeries,
  WithTooltip
} from "@data-ui/sparkline";
import { allColors } from "@data-ui/theme";
import { SMALL_FONT_SIZE, FADED_GRAY } from "../../../constants/styles";

const FORMATTED_EVENT_NAMES = {
  // CLICKED_CREATE_NEW_FLOW: {
  //   name: "Created new flow",
  //   color: "#0091ae"
  // },
  // PUBLISHED_CRX: {
  //   name: "Published flow",
  //   color: "#6a78d1"
  // },
  // SUBMITTED_A_SUPPORT_TICKET: {
  //   name: "Submitted a support ticket",
  //   color: "#e06324"
  // }
};

const TIME_SCALE = {
  RAW: "Raw",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ALL_TIME: "All-time"
};

// const NEWS_URL = "https://dashboard.clearbit.com/demos/news?domain=";

const StyledHeader = styled(Header)`
  color: #626e93;
  padding-bottom: 13px;
`;

const ListItemBubble = styled(Pane)`
  border-radius: 3px;
  width: 8px;
  height: 8px;
  margin-right: 11px;
  opacity: 0.7;
  flex-shrink: 0;
`;

const H1 = styled.h1`
  color: #374151;
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 4px;
`;

const Caption = styled.div`
  text-transform: uppercase;
  color: #9fa6b2;
  font-size: 10px;
  font-weight: 600;
`;

const Panel = styled(Card)`
  display: flex;
  flex-direction: column;

  padding: ${props => props.paddingY || 23}px 16px;
  padding-bottom: ${props => props.paddingBottom || props.paddingY || 23}px;
  flex-grow: 1;

  & + &,
  & + .panel,
  .panel + {
    margin-left: 42px;
  }
`;

const Col = styled(Pane)`
  & + ${Panel}, ${Panel} + &,
  & + & {
    margin-left: 42px;
  }
`;

const PropertyLabel = styled(Pane)`
  color: #7e89a9;
  font-size: 13px;
  font-weight: 600;
`;

const PropertyValue = styled(Pane)`
  color: #475872;
  font-size: 14px;
`;

const PropertyRow = styled(Row)`
  padding: 10px 17px;
  margin-left: -17px;
  margin-right: -17px;
  width: calc(100% + 34px);
  align-items: center;

  & + & {
    border-top: 1px solid rgba(231, 236, 243, 0.6);
  }
`;

const PriorityScore = {};
PriorityScore.ComboIcon = ({ priority }) => (
  <Row alignItems="center">
    {/* <PriorityScore.Icon priority={priority} /> */}
    <PriorityScore.Text priority={priority} />
  </Row>
);

PriorityScore.Icon = ({ priority }) => (
  <Pane position="relative" width={16} height={16} marginRight={7}>
    <Pane
      position="absolute"
      borderRadius="50%"
      width={12}
      height={12}
      left={2}
      top={2}
      backgroundColor="white"
      zIndex={1}
    />
    <Pane
      position="absolute"
      borderRadius="50%"
      width={16}
      height={16}
      background="conic-gradient(rgba(181,190,212,0) 0deg, #FF8F59 360deg)"
    />
    <StyledPriorityIcon score={priority} />
  </Pane>
);

const StyledPriorityIcon = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: conic-gradient(
    rgba(255, 255, 255, 0) 0deg,
    rgba(255, 255, 255, 0) ${props => (props.score / 100) * 360}deg,
    rgba(255, 255, 255, 1) 0deg
  );
`;

PriorityScore.Text = ({ priority }) => (
  <StyledPriorityText>{priority}</StyledPriorityText>
);

const StyledPriorityText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #475872;
`;

const Block = styled(Pane)`
  background-color: ${props => COLOR_RANGE[props.index]};
  height: 22px;
  width: calc(${props => props.size}% - 2px);
  opacity: 0.7;

  margin-right: 2px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &:hover,
  &.hover {
    opacity: 0.5;
  }
`;

const getNameForUser = user => {
  let name =
    user.fullname || user.name || (user.traits ? user.traits.NAME : "");
  if (!name && user.firstname) {
    name = user.firstname;

    if (user.lastname) {
      name = name + " " + user.lastname;
    }
  }
  return name;
};

const Snippet = styled(Pane)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

function AccountDetail({ owners, userDomain, user, Nav }) {
  let { accountId } = useParams();
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Account Detail");
      window.analytics.track("Page loaded", {
        name: "Account Detail",
        url: window.location.pathname,
        email: user.email,
        customerId: domainToCustomerId[userDomain]
      });
    }
  }, []);

  const account = useFetchAPI(
    "account",
    `/account?account_id=${accountId}`,
    {},
    { identifier: userDomain, autoRefresh: true, triggerRefresh },
    accountId,
    triggerRefresh
  );
  const highlights = useFetchAPI(
    "highlights",
    `/highlights?account_id=${accountId}`,
    null,
    {
      identifier: userDomain,
      getDate: true,
      autoRefresh: true,
      triggerRefresh
    },
    accountId,
    triggerRefresh
  );
  const emails = useFetchAPI(
    "emails",
    `/emails?account_id=${accountId}`,
    null,
    { identifier: userDomain, autoRefresh: true, triggerRefresh },
    accountId,
    triggerRefresh
  );
  const threads = useFetchAPI(
    "threads",
    `/threads?account_id=${accountId}`,
    null,
    { identifier: userDomain, autoRefresh: true, triggerRefresh },
    accountId,
    triggerRefresh
  );
  const calendar = useFetchAPI(
    "calendar",
    `/calendar?account_id=${accountId}`,
    null,
    { identifier: userDomain, autoRefresh: true, triggerRefresh },
    accountId,
    triggerRefresh
  );

  const tickets = useFetchAPI(
    "tickets",
    `/tickets?account_id=${accountId}`,
    null,
    { identifier: userDomain, autoRefresh: true, triggerRefresh },
    accountId,
    triggerRefresh
  );

  const [vpWidth, vpHeight] = useViewportSizes(500);

  useEffect(() => {
    const isWaitingOnData =
      !account ||
      !highlights ||
      !highlights.value ||
      !Object.keys(account).length ||
      !emails ||
      !threads ||
      !calendar ||
      !account.metadata;
    if (loading && !isWaitingOnData) {
      setLoading(false);
    }
  }, [account, highlights, emails, threads, calendar]);

  useEffect(() => {
    setLoading(true);
  }, [accountId]);

  console.log("threads", threads);
  console.log("calendar", calendar);

  console.log("emails", emails);
  console.log("tickets", tickets);

  // const highlights = {};
  // const behaviors = useFetchAPI("behaviors", `/behaviors`, [], {}, accountId);

  console.log(accountId, account);
  console.log(highlights);

  if (account.users) {
    account.users = account.users.filter(e => !!e.email);
  }

  if (loading) {
    return (
      <>
        <Nav pageTitle="Customer Detail" />
        <Main>
          <Center height="93%" justifyContent="center">
            <Spinner />
          </Center>
        </Main>
      </>
    );
  }

  // setChildren(<Pane>OK {highlights.date}</Pane>);

  account._computedName =
    account.metadata.custom_label_dimensions_clearbit_company_name ||
    account.metadata.custom_label_dimensions_name ||
    account.name ||
    account.metadata.name ||
    account.metadata.domain;

  account._computedDomain =
    account.metadata.custom_label_dimensions_clearbit_company_domain ||
    account.metadata.custom_label_dimensions_website ||
    account.metadata.traits.WEBSITE ||
    account.metadata.domain ||
    account.metadata.traits.TRAITS_COMPANY_WEBSITE ||
    account.metadata.traits.EMAIL ||
    account.metadata.traits.DOMAIN ||
    (account.users && extractDomain(account.users[0].email));

  if (account._computedDomain) {
    const extractedDomain =
      extractDomain(account._computedDomain) ||
      (account.metadata.traits && account.metadata.traits.DOMAIN);
    if (extractedDomain) {
      account._computedDomain = "www." + extractedDomain;
    }
  }

  const threads2 = (account.emails || []).reduce((sum, curr) => {
    let existingThread = sum.find(
      thread => thread[0] && thread[0].gmail_thread_id === curr.gmail_thread_id
    );
    if (existingThread) {
      existingThread.push(curr);
      return sum;
    }

    return [...sum, [curr]];
  }, []);
  console.log("threads2", threads2);

  const {
    userActivity,
    sessions,
    keyEventTypes,
    mostActiveUserChangeEvents,
    aggregatedKeyEvents,
    currentChampion,
    timeSeries
  } = processEvents({
    highlights: highlights.value,
    emails,
    users: account.users,
    userDomain,
    stats: account.stats
  });

  const cases =
    userDomain === "shipbob.com"
      ? aggregatedKeyEvents.filter(
          e => e.type === "CASE" && !!e.properties.SUBJECT
        )
      : null;

  const StyledRefreshButton = styled(Pane)`
    display: flex;
    margin: 0 12px;
    padding: 0 12px;
    border-radius: 4px;
    position: relative;
    cursor: default;
    min-width: 120px;

    .on-hover {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 12px;
      right: 12px;
      bottom: 0;
    }

    &.show-refresh-button {
      cursor: pointer;

      &:hover {
        background-color: #f9fafb;

        .time {
          opacity: 0;
        }
        .on-hover {
          opacity: 1;
        }
      }
    }
  `;

  const RefreshButton = () => {
    const stale =
      dateFns.differenceInHours(
        Date.now(),
        dateFns.fromUnixTime(highlights.date / 1000)
      ) > 0;

    const recentFetch =
      dateFns.differenceInMinutes(
        Date.now(),
        dateFns.fromUnixTime(highlights.date / 1000)
      ) < 2;

    const hideRefreshButton = !!triggerRefresh || stale;

    return (
      <StyledRefreshButton
        fontSize={12}
        title="Time since data refreshed"
        className={hideRefreshButton ? "" : "show-refresh-button"}
        onClick={() => setTriggerRefresh(triggerRefresh + 1)}
      >
        <Pane
          className="on-hover"
          color="#6B7280"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon="repeat" size={11} marginRight={8} title="Refresh data" />
          <Pane is="span">Refresh data</Pane>
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          className="time"
          width="100%"
          justifyContent="center"
        >
          <Icon
            icon="time"
            size={11}
            marginRight={8}
            color="#9FA6B2"
            title="Time since last refresh"
          />
          <Pane is="span" color="#9FA6B2">
            {dateFns.formatDistanceToNow(
              dateFns.fromUnixTime(highlights.date / 1000)
            )}{" "}
            ago{hideRefreshButton && !recentFetch && ", refreshing…"}
          </Pane>
        </Pane>
      </StyledRefreshButton>
    );
  };

  const FORMAT = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
  };

  const FORMAT_MIN_SIZE = {
    [FORMAT.LARGE]: 1100,
    [FORMAT.MEDIUM]: 765,
    [FORMAT.SMALL]: 0
  };

  const format = Object.keys(FORMAT_MIN_SIZE).find(
    f => FORMAT_MIN_SIZE[f] < vpWidth
  );

  console.log("width", vpWidth, format);

  const MiddleColContents = () => (
    <>
      {["h1insights.com", "upflow.io", "quantif.ai"].includes(userDomain) &&
        threads &&
        !!threads.length && (
          <Pane marginBottom={12}>
            <EmailPanel3 threads={threads} userEmail={user.email} />
          </Pane>
        )}
      {[
        "h1insights.com",
        "upflow.io",
        "instabug.com",
        "eventgeek.com"
      ].includes(userDomain) &&
        timeSeries && (
          <Pane marginBottom={12}>
            <MetricsPanel
              timeSeries={timeSeries}
              users={account.users}
              userDomain={userDomain}
            />
          </Pane>
        )}
      {userDomain === "shipbob.com" && (
        <Pane marginBottom={12}>
          <RevenueMetricsPanel
            timeSeries={timeSeries}
            users={account.users}
            stats={account.stats}
          />
        </Pane>
      )}
    </>
  );

  const RightColContents = () => (
    <>
      {["h1insights.com", "upflow.io", "shipbob.com", "quantif.ai"].includes(
        userDomain
      ) && (
        <Pane marginBottom={12}>
          <CalendarPanel calendar={calendar} userEmail={user.email} />
        </Pane>
      )}
      {["h1insights.com", "quantif.ai"].includes(userDomain) && (
        <Pane marginBottom={12}>
          <TicketsPanel
            tickets={tickets}
            userEmail={user.email}
            userDomain={userDomain}
          />
        </Pane>
      )}
      {["shipbob.com"].includes(userDomain) && (
        <Pane marginBottom={12}>
          <EmailPanel3 threads={threads} userEmail={user.email} />
        </Pane>
      )}
      {userDomain === "shipbob.com" && <CasesPanel cases={cases} />}

      {!["shipbob.com"].includes(userDomain) && (
        <HighlightsPanel
          userDomain={userDomain}
          users={account.users}
          emails={emails || []}
          keyEventTypes={keyEventTypes}
          sessions={sessions}
          aggregatedKeyEvents={aggregatedKeyEvents}
          mostActiveUserChangeEvents={mostActiveUserChangeEvents}
        />
      )}
    </>
  );

  return (
    <>
      <Nav pageTitle="Customer Detail">
        {highlights.date && <RefreshButton />}
      </Nav>
      <Main>
        <Row height="100%" alignItems="start" paddingBottom={71} paddingX={20}>
          <Helmet defer={false} title={`${account._computedName} | Quantifai`}>
            <link
              rel="shortcut icon"
              href={`https://logo.clearbit.com/${account._computedDomain}`}
            />
          </Helmet>
          <Col
            className="panel"
            flexBasis={
              format === "large" ? "30%" : format === "medium" ? "45%" : "100%"
            }
            maxWidth={
              format === "large"
                ? "30%"
                : format === "medium"
                ? "calc(50% - 21px)"
                : "100%"
            }
          >
            <Pane marginBottom={12}>
              <PropertiesPanel
                owners={owners}
                account={account}
                currentChampion={currentChampion}
                userDomain={userDomain}
              />
            </Pane>
            {["shipbob.com"].includes(userDomain) && account.health_scores && (
              <Pane marginBottom={12}>
                <PriorityScorePanel3
                  userDomain={userDomain}
                  account={account}
                />
              </Pane>
            )}
            {["upflow.io"].includes(userDomain) && account.health_scores && (
              <Pane marginBottom={12}>
                <PriorityScorePanel2
                  userDomain={userDomain}
                  account={account}
                />
              </Pane>
            )}
            {[
              "h1insights.com",
              "upflow.io",
              "instabug.com",
              "eventgeek.com"
            ].includes(userDomain) && (
              <Pane marginBottom={12}>
                <UsersPanel
                  account={account}
                  highlights={highlights.value}
                  userDomain={userDomain}
                  timeSeries={timeSeries}
                  userActivity={userActivity}
                  userDomain={userDomain}
                />
              </Pane>
            )}
            {format === "small" && <MiddleColContents />}
            {format !== "large" && <RightColContents />}
          </Col>
          {format !== "small" && (
            <Col
              flexBasis={format === "large" ? "40%" : "45%"}
              className="panel"
              maxWidth={format === "large" ? "35%" : "calc(50% - 21px)"}
            >
              <MiddleColContents />
            </Col>
          )}
          {format === "large" && (
            <Col flexBasis="30%" className="panel" maxWidth="32%">
              <RightColContents />
            </Col>
          )}
        </Row>
      </Main>
    </>
  );
}

const CasesPanel = ({ cases }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!cases || !cases.length) {
    return <></>;
  }

  const sortedCases = [...cases].sort((a, b) =>
    dateFns.compareDesc(
      dateFns.parseISO(a.timestamp),
      dateFns.parseISO(b.timestamp)
    )
  );

  console.log("sortedCases", sortedCases);

  const mostRecentTicket = sortedCases[0];

  return (
    <>
      <Panel padding={16} position="relative" zIndex={1011}>
        <Center marginBottom={12}>
          <H1>Tickets</H1>
        </Center>

        <Pane
          fontSize={14}
          color="#374151"
          // cursor="pointer"
          // onClick={() => {
          //   window.open(
          //     `https://h1insights.zendesk.com/agent/tickets/${mostRecentTicket.properties.TICKET_ID}`,
          //     "_blank"
          //   );
          // }}
        >
          <Pane color="#374151">
            <Pane
              className="email-meta"
              fontSize={12}
              marginBottom={4}
              display="flex"
            >
              <EmailMetaFrom
                opacity={0.6}
                className="email-meta-from"
                flexGrow={1}
              >
                {mostRecentTicket.email ||
                  mostRecentTicket.properties.SUPPLIED_EMAIL}
              </EmailMetaFrom>
              <Pane
                className="email-meta-date"
                flexShrink={0}
                color={
                  mostRecentTicket.properties.STATUS !== "Closed"
                    ? "hsla(38, 63%, 54%, 1)"
                    : "inherit"
                }
                fontWeight={
                  mostRecentTicket.properties.STATUS !== "Closed" ? 600 : 400
                }
                opacity={
                  mostRecentTicket.properties.STATUS !== "Closed" ? 1 : 0.6
                }
                title={getFormattedEmailDate(
                  dateFns.getUnixTime(
                    dateFns.parseISO(mostRecentTicket.properties.CREATED_DATE)
                  ),
                  true
                )}
              >
                {mostRecentTicket.properties.STATUS !== "Closed" &&
                  `${mostRecentTicket.properties.STATUS}, `}
                {getFormattedEmailDate(
                  dateFns.getUnixTime(
                    dateFns.parseISO(mostRecentTicket.properties.CREATED_DATE)
                  )
                )}
              </Pane>
            </Pane>
            <Pane className="email-content">
              <Pane
                className="email-content-subject"
                alignItems="baseline"
                display="flex"
              >
                <Pane fontWeight={600} marginY={3} marginRight={5}>
                  {mostRecentTicket.properties.SUBJECT}
                </Pane>
              </Pane>

              <Pane
                className="email-content-snippet"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={mostRecentTicket.properties.DESCRIPTION}
              >
                {mostRecentTicket.properties.DESCRIPTION ||
                  mostRecentTicket.properties
                    .DESCRIPTION_AND_COUNT_OF_DAMAGED_GOODS_C}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Panel>
      {sortedCases.length > 1 && (
        <OverflowPane
          height={
            isExpanded
              ? sortedCases.length > 12
                ? 422
                : (sortedCases.length - 1) * 31 + 10
              : sortedCases.length > 3
              ? 102
              : sortedCases.length * 34
          }
          overflow={isExpanded ? "auto" : "hidden"}
        >
          {sortedCases.slice(1, 1000).map((e, i) => (
            <ThreadSnip
              className="thread-snip"
              zIndex={1010 - i}
              key={e.event_id}
              title={e.properties.DESCRIPTION || e.properties.SUBJECT}
              marginBottom={isExpanded && i == sortedCases.length - 2 ? 10 : 0}
              // cursor="pointer"
              // onClick={() => {
              //   window.open(
              //     `https://h1insights.zendesk.com/agent/tickets/${e.properties.TICKET_ID}`,
              //     "_blank"
              //   );
              // }}
            >
              <Row>
                <Pane overflow="hidden" textOverflow="ellipsis">
                  {e.properties.SUBJECT}
                </Pane>
                <Pane className="thread-snip-snippet">
                  {e.properties.DESCRIPTION ||
                    e.properties.DESCRIPTION_AND_COUNT_OF_DAMAGED_GOODS_C}
                </Pane>
                <Pane
                  fontWeight={e.properties.STATUS !== "Closed" ? 600 : 400}
                  color={
                    e.properties.STATUS !== "Closed"
                      ? "hsla(38, 63%, 54%, 1)"
                      : "inherit"
                  }
                  opacity={e.properties.STATUS !== "Closed" ? 1 : 0.6}
                  flexShrink={0}
                  title={getFormattedEmailDate(
                    dateFns.getUnixTime(
                      dateFns.parseISO(e.properties.CREATED_DATE)
                    ),
                    true
                  )}
                >
                  {e.properties.STATUS !== "Closed" &&
                    `${e.properties.STATUS}, `}
                  {getFormattedEmailDate(
                    dateFns.getUnixTime(
                      dateFns.parseISO(e.properties.CREATED_DATE)
                    )
                  )}
                </Pane>
              </Row>
            </ThreadSnip>
          ))}
        </OverflowPane>
      )}
      {sortedCases.length > 4 && (
        <ThreadSnip
          className="threads-show-all"
          zIndex={0}
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          title={`${
            sortedCases.length - 4
          } more cases since ${getFormattedEmailDate(
            dateFns.getUnixTime(
              dateFns.parseISO(
                sortedCases[sortedCases.length - 1].properties.CREATED_DATE
              )
            ),
            true
          )}`}
        >
          {!isExpanded && (
            <Center opacity={0.8}>{sortedCases.length - 4} more cases</Center>
          )}
          {isExpanded && <Center opacity={0.8}>Show less cases</Center>}
        </ThreadSnip>
      )}
    </>
  );
};

const TicketsPanel = ({ tickets, userDomain }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!tickets || !tickets.length) {
    return <></>;
  }

  const sortedTickets = [...tickets].sort((a, b) =>
    dateFns.compareDesc(
      dateFns.parseISO(a.timestamp),
      dateFns.parseISO(b.timestamp)
    )
  );

  const mostRecentTicket = sortedTickets[0];

  const getCloseStatus = ticket => {
    if (userDomain === "quantif.ai") {
      return ticket.properties["state.name"] === "Done";
    }
    return ["closed", "solved"].includes(ticket.properties.STATUS);
  };

  return (
    <>
      <Panel padding={16} position="relative" zIndex={1011}>
        <Center marginBottom={12}>
          <H1>Tickets</H1>
        </Center>

        <Pane
          fontSize={14}
          color="#374151"
          cursor="pointer"
          onClick={() => {
            window.open(
              `https://h1insights.zendesk.com/agent/tickets/${mostRecentTicket.properties.TICKET_ID}`,
              "_blank"
            );
          }}
        >
          <Pane color="#374151">
            <Pane
              className="email-meta"
              fontSize={12}
              marginBottom={4}
              display="flex"
            >
              <EmailMetaFrom
                opacity={0.6}
                className="email-meta-from"
                flexGrow={1}
              >
                {mostRecentTicket.properties.NAME ||
                  mostRecentTicket.properties["assignee.name"]}
              </EmailMetaFrom>
              <Pane
                className="email-meta-date"
                flexShrink={0}
                color={
                  getCloseStatus(mostRecentTicket)
                    ? "inherit"
                    : "hsla(38, 63%, 54%, 1)"
                }
                fontWeight={getCloseStatus(mostRecentTicket) ? 400 : 600}
                opacity={getCloseStatus(mostRecentTicket) ? 0.6 : 1}
                title={getFormattedEmailDate(
                  dateFns.getUnixTime(
                    dateFns.parseISO(
                      mostRecentTicket.properties.CREATED_AT ||
                        mostRecentTicket.properties.createdAt
                    )
                  ),
                  true
                )}
              >
                {!getCloseStatus(mostRecentTicket) &&
                  `${capitalise(
                    mostRecentTicket.properties.STATUS ||
                      mostRecentTicket.properties["state.name"]
                  )}, `}
                {getFormattedEmailDate(
                  dateFns.getUnixTime(
                    dateFns.parseISO(
                      mostRecentTicket.properties.CREATED_AT ||
                        mostRecentTicket.properties.createdAt
                    )
                  )
                )}
              </Pane>
            </Pane>
            <Pane className="email-content">
              <Pane
                className="email-content-subject"
                alignItems="baseline"
                display="flex"
              >
                <Pane fontWeight={600} marginY={3} marginRight={5}>
                  {mostRecentTicket.subject ||
                    mostRecentTicket.properties.TITLE}
                </Pane>
              </Pane>

              <Pane
                className="email-content-snippet"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={mostRecentTicket.properties.DESCRIPTION}
              >
                {mostRecentTicket.properties.DESCRIPTION}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Panel>
      <OverflowPane
        height={
          isExpanded
            ? sortedTickets.length > 12
              ? 422
              : (sortedTickets.length - 1) * 31 + 10
            : sortedTickets.length > 3
            ? 102
            : sortedTickets.length * 34
        }
        overflow={isExpanded ? "auto" : "hidden"}
      >
        {sortedTickets.slice(1, 1000).map((e, i) => (
          <ThreadSnip
            className="thread-snip"
            zIndex={1010 - i}
            key={e.properties.TICKET_ID || e.properties.ID}
            title={e.properties.DESCRIPTION || e.subject}
            marginBottom={isExpanded && i == sortedTickets.length - 2 ? 10 : 0}
            cursor="pointer"
            onClick={() => {
              window.open(
                `https://h1insights.zendesk.com/agent/tickets/${e.properties.TICKET_ID}`,
                "_blank"
              );
            }}
          >
            <Row>
              <Pane overflow="hidden" textOverflow="ellipsis">
                {e.subject || e.properties.TITLE}
              </Pane>
              <Pane className="thread-snip-snippet">
                {e.properties.DESCRIPTION}
              </Pane>
              <Pane
                fontWeight={getCloseStatus(e) ? 400 : 600}
                color={getCloseStatus(e) ? "inherit" : "hsla(38, 63%, 54%, 1)"}
                opacity={getCloseStatus(e) ? 0.6 : 1}
                flexShrink={0}
                title={getFormattedEmailDate(
                  dateFns.getUnixTime(
                    dateFns.parseISO(
                      e.properties.CREATED_AT || e.properties.createdAt
                    )
                  ),
                  true
                )}
              >
                {!getCloseStatus(e) &&
                  `${
                    capitalise(e.properties.STATUS) ||
                    e.properties["state.name"]
                  }, `}
                {getFormattedEmailDate(
                  dateFns.getUnixTime(
                    dateFns.parseISO(
                      e.properties.CREATED_AT || e.properties.createdAt
                    )
                  )
                )}
              </Pane>
            </Row>
          </ThreadSnip>
        ))}
      </OverflowPane>
      {sortedTickets.length > 4 && (
        <ThreadSnip
          className="threads-show-all"
          zIndex={0}
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          title={`${
            sortedTickets.length - 4
          } more tickets since ${getFormattedEmailDate(
            dateFns.getUnixTime(
              dateFns.parseISO(
                sortedTickets[sortedTickets.length - 1].properties.CREATED_AT ||
                  sortedTickets[sortedTickets.length - 1].properties.createdAt
              )
            ),
            true
          )}`}
        >
          {!isExpanded && (
            <Center opacity={0.8}>
              {sortedTickets.length - 4} more tickets
            </Center>
          )}
          {isExpanded && <Center opacity={0.8}>Show less tickets</Center>}
        </ThreadSnip>
      )}
    </>
  );
};
const renderTooltip = ({ datum }) => (
  <Pane
    fontSize={14}
    fontWeight={800}
    style={{
      letterSpacing: "0.3px"
    }}
    color="#475872"
  >
    {datum.All.date && (
      <Pane is="span" fontWeight={600}>
        {datum.All.date}:{" "}
      </Pane>
    )}
    <Pane is="span">{datum.y || "--"}</Pane>
  </Pane>
);

const renderStatTooltip = ({ datum }) => (
  <Pane
    fontSize={14}
    fontWeight={800}
    style={{
      letterSpacing: "0.3px"
    }}
    color="#475872"
  >
    {datum.date && (
      <Pane is="span" fontWeight={600}>
        {datum.date}:{" "}
      </Pane>
    )}
    <Pane is="span">{datum.y || "--"}</Pane>
  </Pane>
);
const renderForecastTooltip = forecastRange => ({ datum }) => (
  <Pane
    fontSize={14}
    fontWeight={800}
    style={{
      letterSpacing: "0.3px"
    }}
    color="#475872"
    padding={4}
  >
    <Pane>
      {datum.All.date && (
        <Pane is="span" fontWeight={600}>
          {datum.All.date}:{" "}
        </Pane>
      )}
      <Pane is="span">${datum.y || "--"}</Pane>
    </Pane>
    <Pane fontWeight={400} marginTop={6}>
      Forecasted ${forecastRange.find(f => f.key === datum.All.date).value}
    </Pane>
  </Pane>
);

const H5 = styled.h5`
  text-transform: uppercase;
  color: hsla(216, 23%, 36%, 0.7);
  font-size: 12px;
  font-weight: 700;
  margin-left: 28px;
  margin-bottom: 0;
`;

const RELATIVE_DATE_RANGE = {
  LAST_TWO_WEEKS: "Last 2 weeks",
  LAST_MONTH: "Last month",
  THIS_QUARTER: "This quarter",
  LAST_SIX_MONTHS: "Last 6 months",
  ALL_TIME: "All-time"
};

const getTimeSeriesForTimeRange = (timeSeries, timeScale, dateRange) => {
  const specificTimeSeries = timeSeries[timeScale];
  const firstDate = dateFns.parseISO(specificTimeSeries[0]["All"].dateISO);
  const currentDate = new Date();

  let newFirstDate, range;
  switch (dateRange) {
    case RELATIVE_DATE_RANGE.LAST_TWO_WEEKS:
      newFirstDate = dateFns.subDays(currentDate, 14);
      break;
    case RELATIVE_DATE_RANGE.LAST_MONTH:
      newFirstDate = dateFns.subDays(currentDate, 30);
      break;
    case RELATIVE_DATE_RANGE.THIS_QUARTER:
      newFirstDate = dateFns.addDays(dateFns.startOfQuarter(currentDate), 0);
      break;
    case RELATIVE_DATE_RANGE.LAST_SIX_MONTHS:
      newFirstDate = dateFns.subDays(currentDate, 180);
      break;
    case RELATIVE_DATE_RANGE.ALL_TIME:
    default:
      newFirstDate = firstDate;
  }

  range = dateFns.differenceInCalendarDays(newFirstDate, firstDate);

  switch (timeScale) {
    case TIME_SCALE.MONTHLY:
      range = Math.round(range / 30);
      break;
    case TIME_SCALE.WEEKLY:
      range = Math.round(range / 7);
      break;
    case TIME_SCALE.DAILY:
    default:
  }

  return specificTimeSeries.slice(range);
};

const formatDollars = (dollars, fixed = 0, isCents = false) => {
  let formattedDollars = "";
  if (isNaN(Math.round(dollars))) {
    return "";
  }
  if (dollars >= 1000000) {
    formattedDollars = `$${
      Math.round(dollars / (1000000 / Math.pow(10, fixed))) /
      Math.pow(10, fixed)
    }M`;
  } else if (dollars >= 1000) {
    formattedDollars = `$${
      Math.round(dollars / (1000 / Math.pow(10, fixed))) / Math.pow(10, fixed)
    }K`;
  } else {
    formattedDollars = `$${isCents ? dollars.toFixed(2) : dollars}`;
  }
  return formattedDollars;
};

const RevenueMetricsPanel = ({ timeSeries, users, stats }) => {
  const [timeScale, setTimeScale] = useState(TIME_SCALE.WEEKLY);
  const [filteredUser, setFilteredUser] = useState("All");
  const [dateRange, setDateRange] = useState(RELATIVE_DATE_RANGE.THIS_QUARTER);

  if (!timeSeries) {
    return (
      <Panel position="relative">
        <Center>
          <H1>Performance Metrics</H1>
          <Caption>No events yet</Caption>
        </Center>
      </Panel>
    );
  }

  const metrics = [
    // {
    //   label: "Revenue run rate this quarter",
    //   colors: allColors.violet,
    //   key: "REVENUE_THIS_QUARTER_C",
    //   prefix: "$",
    //   hideBand: true,
    //   forecastKey: "REVENUE_FORECAST",
    //   format: "dollars"
    // },
    {
      label: "Average domestic cost per order",
      colors: allColors.indigo,
      key: "ALL_AVERAGE_DOMESTIC_SHIPPING_COST",
      upperBoundKey:
        "ROLLING_UPPER_THRESHOLD_WITHOUT_OUTLIER_AVERAGE_DOMESTIC_SHIPPING_COST",
      lowerBoundKey:
        "ROLLING_LOWER_THRESHOLD_WITHOUT_OUTLIER_AVERAGE_DOMESTIC_SHIPPING_COST",
      type: "stats",
      form: "line",
      format: "cents"
    },
    {
      label: "Case to order ratio",
      colors: allColors.blue,
      key: "CASE_ORDER_RATIO_THIS_QUARTER_C",
      hideBand: true,
      target: false,
      showDate: true
    },
    {
      label: "NPS",
      colors: allColors.cyan,
      key: "ALL_NPS",
      type: "stats"
    },
    {
      label: "ShipBob satisfaction",
      colors: allColors.teal,
      key: "ALL_SB_SAT",
      type: "stats",
      max: 5
    }
    // {
    //   label: "Average cost per order this quarter",
    //   colors: allColors.blue,
    //   key: "AVERAGE_COST_PER_ORDER_THIS_QUARTER_C",
    //   prefix: "$",
    //   hideBand: true
    // }

    // {
    //   label: "Average cost per order change (Q/Q)",
    //   colors: allColors.blue,
    //   key: "AVERAGE_COST_PER_ORDER_CHANGE_C",
    //   suffix: "%"
    // },
    // {
    //   label: "Case:order ratio change (Q/Q)",
    //   colors: allColors.blue,
    //   key: "CASE_ORDER_RATIO_CHANGE_C",
    //   suffix: "%"
    // }
  ];

  let dateRangeTimeSeries = getTimeSeriesForTimeRange(
    timeSeries,
    timeScale,
    dateRange
  );

  console.log(
    "dateRangeTimeSeries",
    timeSeries,
    timeScale,
    dateRange,
    dateRangeTimeSeries
  );

  return (
    <Panel position="relative">
      <Pane position="absolute" left={16} top={16}>
        <SelectMenu
          title="Events for"
          position="bottom-left"
          options={Object.values(RELATIVE_DATE_RANGE).map(bucket => ({
            label: bucket,
            value: bucket
          }))}
          onSelect={({ value }) => setDateRange(value)}
          selected={dateRange}
        >
          <IconButton icon="calendar" />
        </SelectMenu>
      </Pane>

      <Pane position="absolute" right={16} top={16}>
        <SelectMenu
          title="Select user"
          position="bottom-right"
          options={[
            {
              label: "All",
              value: "All"
            },
            ...users.map(user => ({
              label:
                getNameForUser(user) ||
                user.email ||
                (user.traits ? user.traits.EMAIL : ""),
              value: user.email || (user.traits ? user.traits.EMAIL : "")
            }))
          ]}
          onSelect={({ value }) => setFilteredUser(value)}
          selected={filteredUser}
        >
          <IconButton icon="user" />
        </SelectMenu>
      </Pane>
      <Center paddingBottom={10}>
        <H1>Performance Metrics</H1>

        {/*         <SegmentedControl */}
        {/*           width={240} */}
        {/*           marginY={14} */}
        {/*           options={Object.values(TIME_SCALE) */}
        {/*             .filter(bucket => !["Raw", "All-time"].includes(bucket)) */}
        {/*             .map(bucket => ({ */}
        {/*               label: bucket, */}
        {/*               value: bucket */}
        {/*             }))} */}
        {/*           value={timeScale} */}
        {/*           onChange={value => setTimeScale(value)} */}
        {/*         /> */}
      </Center>

      {metrics.map(metric => {
        if (!dateRangeTimeSeries.length) {
          return <></>;
        }

        if (metric.type === "stats") {
          let index = 0;

          if (
            !stats[metric.key] ||
            (!metric.lowerBoundKey && stats[metric.key].every(o => !o))
          ) {
            return <></>;
          }

          const parsedDates = stats[metric.key].map(s =>
            dateFns.fromUnixTime(s.timestamp / 1000)
          );

          const g = dateRangeTimeSeries.map(d => {
            const parsedIteratorDate = dateFns.parseISO(d["All"].dateISO);
            const i = parsedDates.findIndex(
              s => dateFns.compareAsc(s, parsedIteratorDate) > 0
            );
            let dIndex = null;
            if (i > 0) {
              dIndex = i - 1;
            } else if (i !== 0) {
              dIndex = parsedDates.length - 1;
            }

            const o = {
              date: d["All"].date,
              dateISO: d["All"].dateISO,
              [metric.key]: dIndex === null ? 0 : stats[metric.key][dIndex]
            };

            if (metric.lowerBoundKey) {
              o[metric.lowerBoundKey] =
                dIndex === null ? 0 : stats[metric.lowerBoundKey][dIndex];
              o[metric.upperBoundKey] =
                dIndex === null ? 0 : stats[metric.upperBoundKey][dIndex];
            }

            return o;
          });

          let min = metric.min;
          let max = metric.max;

          if (metric.lowerBoundKey) {
            const sortedActual = [...g]
              .map(o => o[metric.key])
              .sort((a, b) => {
                if (a.value > b.value) {
                  return 1;
                }
                if (b.value > a.value) {
                  return -1;
                }
                return 0;
              });

            min = [...g]
              .map(o => o[metric.lowerBoundKey])
              .sort((a, b) => {
                if (a.value > b.value) {
                  return 1;
                }
                if (b.value > a.value) {
                  return -1;
                }
                return 0;
              })[0].value;

            max = [...g]
              .map(o => o[metric.upperBoundKey])
              .sort((a, b) => {
                if (a.value < b.value) {
                  return 1;
                }
                if (b.value < a.value) {
                  return -1;
                }
                return 0;
              })[0].value;

            min = Math.min(min, sortedActual[0].value);
            max = Math.max(max, sortedActual[sortedActual.length - 1].value);

            console.log("min", min);
            console.log("max", max);
          }

          // return <></>;

          return (
            <SessionBlock key={metric.key} afterTop={26}>
              <Pane
                fontWeight={500}
                position="relative"
                zIndex={2}
                fontSize={14}
                paddingTop={10}
                paddingBottom={6}
              >
                <Pane is="span" backgroundColor="white" paddingRight={10}>
                  <Pane is="span">{metric.label}</Pane>
                </Pane>
              </Pane>
              <Pane
                fontSize={14}
                marginTop={12}
                marginBottom={24}
                color="#475872"
                textAlign="center"
                position="relative"
              >
                {metric.lowerBoundKey && (
                  <Pane
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  >
                    <Sparkline
                      ariaLabel={`${metric.label} activity`}
                      margin={{
                        top: 24,
                        bottom: 24
                      }}
                      width={360}
                      height={100}
                      data={g}
                      valueAccessor={datum => datum[metric.lowerBoundKey].value}
                      min={min || 0}
                      max={max || 10}
                    >
                      <LineSeries
                        strokeWidth={1}
                        strokeDasharray="4 4"
                        stroke={metric.colors[8]}
                        showArea={false}
                      />
                    </Sparkline>
                  </Pane>
                )}
                {metric.upperBoundKey && (
                  <Pane
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  >
                    <Sparkline
                      ariaLabel={`${metric.label} activity`}
                      margin={{
                        top: 24,
                        bottom: 24
                      }}
                      width={360}
                      height={100}
                      data={g}
                      valueAccessor={datum => datum[metric.upperBoundKey].value}
                      min={min || 0}
                      max={max || 10}
                    >
                      <LineSeries
                        strokeWidth={1}
                        strokeDasharray="4 4"
                        stroke={metric.colors[8]}
                        showArea={false}
                      />
                    </Sparkline>
                  </Pane>
                )}

                <WithTooltip renderTooltip={renderStatTooltip}>
                  {({ onMouseMove, onMouseLeave, tooltipData }) => (
                    <Sparkline
                      ariaLabel={`${metric.label} activity`}
                      margin={{
                        top: 24,
                        bottom: 24
                      }}
                      width={360}
                      height={100}
                      data={g}
                      valueAccessor={datum =>
                        datum[metric.key] ? datum[metric.key].value : 0
                      }
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                      min={min || 0}
                      max={max || 10}
                    >
                      {metric.form === "line" && (
                        <LineSeries
                          stroke={metric.colors[7]}
                          showArea={false}
                        />
                      )}
                      {metric.form === "line" && (
                        <PointSeries
                          points={["all"]}
                          fill={metric.colors[7]}
                          size={1.5}
                          stroke={metric.colors[7]}
                          renderLabel={val => (
                            <Pane is="tspan" fontWeight={600} fontSize={10}>
                              {metric.format === "cents" &&
                                formatDollars(val, 0, true)}
                              {metric.format !== "cents" && val}
                            </Pane>
                          )}
                        />
                      )}
                      {metric.form !== "line" && (
                        <BarSeries
                          fill={metric.colors[7]}
                          renderLabel={(val, i) =>
                            g[i][metric.key] && (
                              <Pane is="tspan" fontWeight={600} fontSize={10}>
                                {val}
                              </Pane>
                            )
                          }
                        />
                      )}

                      <PointSeries
                        points={["all"]}
                        fill="white"
                        size={0}
                        stroke={metric.colors[7]}
                        renderLabel={(val, i) => (
                          <Pane
                            is="tspan"
                            y={100}
                            dy={-12}
                            fontWeight={500}
                            fontSize={9}
                          >
                            {dateFns.format(
                              dateFns.parseISO(g[i].dateISO),
                              "MM/dd"
                            )}
                          </Pane>
                        )}
                      />
                    </Sparkline>
                  )}
                </WithTooltip>
              </Pane>
            </SessionBlock>
          );
        }

        const sortedValues = [...dateRangeTimeSeries].sort((a, b) => {
          if (a[filteredUser][metric.key] < b[filteredUser][metric.key]) {
            return -1;
          } else if (
            a[filteredUser][metric.key] > b[filteredUser][metric.key]
          ) {
            return 1;
          }
          return 0;
        });

        console.log("sortedValues", sortedValues);

        const minDataValue = sortedValues[0][filteredUser][metric.key];
        const maxDataValue =
          sortedValues[sortedValues.length - 1][filteredUser][metric.key];
        const forecastValue =
          sortedValues[sortedValues.length - 1][filteredUser][
            metric.forecastKey
          ];

        let forecastRange,
          minRunRateDataValue,
          maxRunRateDataValue,
          minRunRateChangeDataValue,
          maxRunRateChangeDataValue;

        if (metric.forecastKey) {
          const LENGTH_QUARTER_IN_DAYS = 66;
          const startOfQ = dateFns.startOfQuarter(Date.now());

          dateRangeTimeSeries.forEach((d, i) => {
            const daysSinceStartOfQ = dateFns.differenceInDays(
              dateFns.parseISO(d[filteredUser].dateISO),
              startOfQ
            );
            const percentageComplete =
              daysSinceStartOfQ / LENGTH_QUARTER_IN_DAYS;
            const runrate = Math.round(
              d[filteredUser][metric.key] / percentageComplete
            );

            d[filteredUser]["_" + metric.forecastKey] = runrate;
            d[filteredUser]["_" + metric.forecastKey + "_change"] = Math.round(
              100 * (runrate / forecastValue)
            );
            if (i === 0) {
              minRunRateDataValue = runrate;
              maxRunRateDataValue = runrate;
              minRunRateChangeDataValue =
                d[filteredUser]["_" + metric.forecastKey + "_change"];
              maxRunRateChangeDataValue =
                d[filteredUser]["_" + metric.forecastKey + "_change"];
            } else {
              minRunRateDataValue = Math.min(runrate, minRunRateDataValue);
              maxRunRateDataValue = Math.max(runrate, maxRunRateDataValue);
              minRunRateChangeDataValue = Math.min(
                d[filteredUser]["_" + metric.forecastKey + "_change"],
                minRunRateChangeDataValue
              );
              maxRunRateChangeDataValue = Math.max(
                d[filteredUser]["_" + metric.forecastKey + "_change"],
                maxRunRateChangeDataValue
              );
            }
          });
        }

        const minValue = metric.forecastKey ? 0 : minDataValue;

        const maxValue =
          metric.forecastKey && forecastValue > maxDataValue
            ? forecastValue
            : maxDataValue;

        const minRunRate = 0;
        const maxRunRate = Math.max(maxRunRateDataValue, forecastValue);
        const minRunRateChange = 0;
        const maxRunRateChange = Math.max(maxRunRateChangeDataValue, 100);

        console.log(
          "values",
          [minValue, maxValue],
          [minRunRate, maxRunRate],
          [minRunRateChange, maxRunRateChange]
        );

        console.log("dateRangeTimeSeries", dateRangeTimeSeries);

        if (dateRangeTimeSeries.every(o => !o[filteredUser][metric.key])) {
          return <></>;
        }

        return (
          <SessionBlock key={metric.key + dateRange} afterTop={26}>
            <Pane
              fontWeight={500}
              position="relative"
              zIndex={2}
              fontSize={14}
              paddingTop={10}
              paddingBottom={6}
            >
              <Pane is="span" backgroundColor="white" paddingRight={10}>
                <Pane is="span">{metric.label}</Pane>
              </Pane>
            </Pane>
            <Pane
              fontSize={14}
              marginTop={12}
              marginBottom={24}
              color="#475872"
              textAlign="center"
              position="relative"
            >
              <WithTooltip renderTooltip={renderTooltip}>
                {({ onMouseMove, onMouseLeave, tooltipData }) => (
                  <Sparkline
                    ariaLabel={`${metric.label} activity`}
                    margin={{
                      top: 24,
                      bottom: 24
                    }}
                    width={360}
                    height={100}
                    data={dateRangeTimeSeries}
                    valueAccessor={datum =>
                      metric.forecastKey
                        ? datum[filteredUser]["_" + metric.forecastKey]
                        : datum[filteredUser][metric.key]
                    }
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                    min={metric.forecastKey ? minRunRate : minValue}
                    max={metric.forecastKey ? maxRunRate : maxValue}
                  >
                    {/* this creates a <defs> referenced for fill */}
                    {/* display innerquartiles of the data */}
                    {!metric.hideBand && (
                      <PatternLines
                        id={`unique_pattern_id_${metric.key}`}
                        height={6}
                        width={6}
                        stroke={metric.colors[6]}
                        strokeWidth={1}
                        orientation={["diagonal"]}
                      />
                    )}
                    {!metric.hideBand && (
                      <BandLine
                        band="innerquartiles"
                        fill={`url(#unique_pattern_id_${metric.key})`}
                      />
                    )}

                    {/* display the median */}

                    {/* <HorizontalReferenceLine */}
                    {/*   stroke={allColors.grape[8]} */}
                    {/*   strokeWidth={1} */}
                    {/*   strokeDasharray="4 4" */}
                    {/*   reference={forecastValue} */}
                    {/* /> */}
                    {/* <BarSeries fill={metric.colors[7]} /> */}

                    {!!metric.hideBand && metric.target !== false && (
                      <HorizontalReferenceLine
                        stroke={metric.colors[8]}
                        strokeWidth={1}
                        strokeDasharray="4 4"
                        reference={forecastValue}
                        renderLabel={val => (
                          <Pane
                            is="tspan"
                            fontWeight={600}
                            fontSize={10}
                            fill={metric.colors[8]}
                          >
                            {metric.format === "dollars"
                              ? formatDollars(val)
                              : val}
                          </Pane>
                        )}
                      />
                    )}
                    {!!metric.hideBand && (
                      <BarSeries
                        fill={metric.colors[7]}
                        renderLabel={val => (
                          <Pane is="tspan" fontWeight={600} fontSize={10}>
                            {metric.format === "dollars"
                              ? formatDollars(val)
                              : val || ""}
                          </Pane>
                        )}
                      />
                    )}

                    {/* Series children are passed the data from the parent Sparkline */}
                    {!metric.hideBand && (
                      <LineSeries showArea={false} stroke={metric.colors[7]} />
                    )}

                    {!metric.hideBand && (
                      <PointSeries
                        points={["all"]}
                        fill={metric.colors[7]}
                        size={1.5}
                        stroke={metric.colors[7]}
                      />
                    )}

                    {!metric.hideBand && (
                      <PointSeries
                        points={["first"]}
                        fill="white"
                        size={3}
                        stroke={metric.colors[7]}
                        renderLabel={val => (
                          <Pane
                            is="tspan"
                            x={15}
                            y={timeScale === TIME_SCALE.WEEKLY ? 78 : 55}
                            dy={timeScale === TIME_SCALE.WEEKLY ? -12 : 12}
                            fontWeight={500}
                            fontSize={9}
                          >
                            {dateFns.format(
                              dateFns.parseISO(
                                dateRangeTimeSeries[0]["All"].dateISO
                              ),
                              "yyyy/MM/dd"
                            )}
                          </Pane>
                        )}
                      />
                    )}
                    {!!metric.showDate && (
                      <PointSeries
                        points={["all"]}
                        fill="white"
                        size={0}
                        stroke={metric.colors[7]}
                        renderLabel={(val, i) => (
                          <Pane
                            is="tspan"
                            y={100}
                            dy={-12}
                            fontWeight={500}
                            fontSize={9}
                          >
                            {dateFns.format(
                              dateFns.parseISO(
                                dateRangeTimeSeries[i]["All"].dateISO
                              ),
                              "MM/dd"
                            )}
                          </Pane>
                        )}
                      />
                    )}
                    {!metric.hideBand && (
                      <PointSeries
                        points={["max", "last"]}
                        fill={metric.colors[3]}
                        size={5}
                        stroke="#fff"
                        renderLabel={val => (
                          <Pane is="tspan" fontWeight={600}>
                            {val ? metric.prefix : ""}
                            {val}
                            {val ? metric.suffix : ""}
                          </Pane>
                        )}
                      />
                    )}
                  </Sparkline>
                )}
              </WithTooltip>

              {metric.forecastKey && (
                <WithTooltip renderTooltip={renderTooltip}>
                  {({ onMouseMove, onMouseLeave, tooltipData }) => (
                    <Sparkline
                      ariaLabel={`${metric.label} change`}
                      margin={{
                        top: 24,
                        bottom: 24
                      }}
                      width={360}
                      height={100}
                      data={dateRangeTimeSeries}
                      valueAccessor={datum =>
                        datum[filteredUser][
                          "_" + metric.forecastKey + "_change"
                        ]
                      }
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                      min={metric.forecastKey ? minRunRateChange : minValue}
                      max={metric.forecastKey ? maxRunRateChange : maxValue}
                    >
                      <HorizontalReferenceLine
                        stroke={metric.colors[8]}
                        strokeWidth={1}
                        strokeDasharray="4 4"
                        reference={100}
                        renderLabel={val => (
                          <Pane
                            is="tspan"
                            fontWeight={600}
                            fontSize={10}
                            fill={metric.colors[8]}
                          >
                            {val}%
                          </Pane>
                        )}
                      />

                      {/* Series children are passed the data from the parent Sparkline */}
                      <LineSeries showArea={false} stroke={metric.colors[7]} />

                      <PointSeries
                        points={["all"]}
                        fill={metric.colors[7]}
                        size={2.5}
                        stroke={metric.colors[7]}
                        renderLabel={val => (
                          <Pane is="tspan" fontWeight={600}>
                            {val}%
                          </Pane>
                        )}
                      />

                      <PointSeries
                        points={["all"]}
                        fill="white"
                        size={0}
                        stroke={metric.colors[7]}
                        renderLabel={(val, i) => (
                          <Pane
                            is="tspan"
                            y={100}
                            dy={-12}
                            fontWeight={500}
                            fontSize={9}
                          >
                            {dateFns.format(
                              dateFns.parseISO(
                                dateRangeTimeSeries[i]["All"].dateISO
                              ),
                              "MM/dd"
                            )}
                          </Pane>
                        )}
                      />
                    </Sparkline>
                  )}
                </WithTooltip>
              )}
            </Pane>
          </SessionBlock>
        );
      })}
    </Panel>
  );
};

const MetricsPanel = ({ timeSeries, users, userDomain }) => {
  const [timeScale, setTimeScale] = useState(
    userDomain === "instabug.com"
      ? TIME_SCALE.DAILY
      : // : userDomain === "eventgeek.com"
        // ? TIME_SCALE.MONTHLY
        TIME_SCALE.WEEKLY
  );
  const [filteredUser, setFilteredUser] = useState("All");
  const [dateRange, setDateRange] = useState(RELATIVE_DATE_RANGE.ALL_TIME);

  if (!timeSeries) {
    return (
      <Panel position="relative">
        <Center>
          <H1>Usage Metrics</H1>
          <Caption>No events yet</Caption>
        </Center>
      </Panel>
    );
  }

  let metrics = [];

  if (userDomain === "h1insights.com") {
    metrics = [
      {
        label: "All Activity",
        colors: allColors.grape,
        key: "overall"
      },
      {
        label: "Searches",
        colors: allColors.violet,
        key: "searches"
      },
      {
        label: "Profile Views",
        colors: allColors.indigo,
        key: "profileViews"
      },
      {
        label: "Logins",
        colors: allColors.blue,
        key: "signins"
      },
      {
        label: "Emails",
        colors: allColors.cyan,
        key: "emails"
      }
    ];
  }
  if (userDomain === "upflow.io") {
    metrics = [
      {
        label: "All Activity",
        colors: allColors.grape,
        key: "overall"
      },
      {
        label: "Actions Performed",
        colors: allColors.violet,
        key: "actions"
      },
      {
        label: "App opens",
        colors: allColors.indigo,
        key: "signins"
      }
    ];
  }

  if (userDomain === "instabug.com") {
    metrics = [
      {
        label: "All Activity",
        colors: allColors.grape,
        key: "overall"
      }
    ];
  }
  if (userDomain === "eventgeek.com") {
    metrics = [
      {
        label: "All Activity",
        colors: allColors.grape,
        key: "overall"
      },
      {
        label: "Events Created",
        colors: allColors.violet,
        key: "eventsCreated"
      },
      {
        label: "Budget Activtiy",
        colors: allColors.violet,
        key: "budgetActivity"
      },
      {
        label: "Contact Activity",
        colors: allColors.violet,
        key: "contactActivity"
      },
      {
        label: "Publish Activity",
        colors: allColors.violet,
        key: "publishActivity"
      },
      {
        label: "Meeting Activity",
        colors: allColors.violet,
        key: "meetingActivity"
      }
    ];
  }

  const dateRangeTimeSeries = getTimeSeriesForTimeRange(
    timeSeries,
    timeScale,
    dateRange
  );

  return (
    <Panel position="relative">
      <Pane position="absolute" left={16} top={16}>
        <SelectMenu
          title="Events for"
          position="bottom-left"
          options={Object.values(RELATIVE_DATE_RANGE).map(bucket => ({
            label: bucket,
            value: bucket
          }))}
          onSelect={({ value }) => setDateRange(value)}
          selected={dateRange}
        >
          <IconButton icon="calendar" />
        </SelectMenu>
      </Pane>

      <Pane position="absolute" right={16} top={16}>
        <SelectMenu
          title="Select user"
          position="bottom-right"
          options={[
            {
              label: "All",
              value: "All"
            },
            ...users.map(user => ({
              label:
                getNameForUser(user) ||
                user.email ||
                (user.traits ? user.traits.EMAIL : ""),
              value: user.email || (user.traits ? user.traits.EMAIL : "")
            }))
          ]}
          onSelect={({ value }) => setFilteredUser(value)}
          selected={filteredUser}
        >
          <IconButton icon="user" />
        </SelectMenu>
      </Pane>
      <Center>
        <H1>Usage Metrics</H1>

        <SegmentedControl
          width={240}
          marginY={14}
          options={Object.values(TIME_SCALE)
            .filter(bucket => !["Raw", "All-time"].includes(bucket))
            .map(bucket => ({
              label: bucket,
              value: bucket
            }))}
          value={timeScale}
          onChange={value => setTimeScale(value)}
        />
      </Center>

      {metrics
        .filter(
          metric =>
            !dateRangeTimeSeries.every(
              datum => !datum[filteredUser][metric.key]
            )
        )
        .map(metric => (
          <SessionBlock key={metric.key + dateRange}>
            <Pane
              fontWeight={600}
              position="relative"
              zIndex={2}
              fontSize={11}
              paddingBottom={6}
            >
              <Pane is="span" backgroundColor="white" paddingRight={10}>
                <Pane opacity={0.7} is="span">
                  {metric.label}
                </Pane>
              </Pane>
            </Pane>

            <Pane fontSize={14} marginY={12} color="#475872" textAlign="center">
              <WithTooltip renderTooltip={renderTooltip}>
                {({ onMouseMove, onMouseLeave, tooltipData }) => (
                  <Sparkline
                    ariaLabel={`${metric.label} activity`}
                    margin={{
                      top: 24,
                      bottom: 24
                    }}
                    width={300}
                    height={100}
                    data={dateRangeTimeSeries}
                    valueAccessor={datum => datum[filteredUser][metric.key]}
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                  >
                    {/* this creates a <defs> referenced for fill */}
                    <PatternLines
                      id={`unique_pattern_id_${metric.key}`}
                      height={6}
                      width={6}
                      stroke={metric.colors[6]}
                      strokeWidth={1}
                      orientation={["diagonal"]}
                    />
                    {/* display innerquartiles of the data */}
                    <BandLine
                      band="innerquartiles"
                      fill={`url(#unique_pattern_id_${metric.key})`}
                    />
                    {/* display the median */}
                    {/* <HorizontalReferenceLine */}
                    {/*   stroke={allColors.grape[8]} */}
                    {/*   strokeWidth={1} */}
                    {/*   strokeDasharray="4 4" */}
                    {/*   reference="median" */}
                    {/* /> */}
                    {/* Series children are passed the data from the parent Sparkline */}
                    <LineSeries showArea={false} stroke={metric.colors[7]} />

                    <PointSeries
                      points={["all"]}
                      fill={metric.colors[7]}
                      size={1.5}
                      stroke={metric.colors[7]}
                    />

                    <PointSeries
                      points={["first"]}
                      fill="white"
                      size={3}
                      stroke={metric.colors[7]}
                      renderLabel={val => (
                        <Pane
                          is="tspan"
                          x={15}
                          y={timeScale === TIME_SCALE.WEEKLY ? 78 : 55}
                          dy={timeScale === TIME_SCALE.WEEKLY ? -12 : 12}
                          fontWeight={500}
                          fontSize={10}
                        >
                          {dateFns.format(
                            dateFns.parseISO(
                              dateRangeTimeSeries[0]["All"].dateISO
                            ),
                            "yyyy/MM/dd"
                          )}
                        </Pane>
                      )}
                    />

                    <PointSeries
                      points={["max", "last"]}
                      fill={metric.colors[3]}
                      size={5}
                      stroke="#fff"
                      renderLabel={val => (
                        <Pane is="tspan" fontWeight={600}>
                          {val}
                        </Pane>
                      )}
                    />
                  </Sparkline>
                )}
              </WithTooltip>
            </Pane>
          </SessionBlock>
        ))}
    </Panel>
  );
};

const processEvents = ({ highlights, emails, users, userDomain, stats }) => {
  // if (!emails.length) {
  if (
    (!["quantif.ai", "instabug.com"].includes(userDomain) && !stats) ||
    (!highlights.key_events && !emails.length)
  ) {
    return {
      userActivity: null,
      sessions: null,
      keyEventTypes: null,
      aggregatedKeyEvents: null,
      currentChampion: null,
      mostActiveUserChangeEvents: null,
      timeSeries: null
    };
  }

  const userActivity = {};
  const sessions = {
    [TIME_SCALE.RAW]: {},
    [TIME_SCALE.DAILY]: {},
    [TIME_SCALE.WEEKLY]: {},
    [TIME_SCALE.MONTHLY]: {},
    [TIME_SCALE.ALL_TIME]: {}
  };

  let keyEventTypes = {};
  const keyEvents = highlights.key_events || [];
  let newStatEvents = [];

  if (userDomain === "shipbob.com") {
    const allTimeStatsObjs = Object.keys(stats)
      .filter(s => s.indexOf("ALL_") === 0)
      .map(s => ({
        key: s,
        values: typeof stats[s] === "string" ? JSON.parse(stats[s]) : stats[s]
      }));

    const allTimeStats = {};

    allTimeStatsObjs.forEach(s => {
      s.values.forEach(k => {
        if (!allTimeStats[k.timestamp]) {
          allTimeStats[k.timestamp] = {};
        }
        allTimeStats[k.timestamp] = {
          ...allTimeStats[k.timestamp],
          [s.key]: k.value
        };
      });
    });
    console.log("allTimeStatsObjs", allTimeStatsObjs);
    console.log("allTimeStats", allTimeStats);

    const allTimeStatsArray = Object.keys(allTimeStats)
      .sort()
      .reduce(
        (sum, curr, i, src) => [
          ...sum,
          {
            timestamp: curr,
            values:
              i === 0
                ? allTimeStats[curr]
                : {
                    ...sum[i - 1].values,
                    ...allTimeStats[curr]
                  }
          }
        ],
        []
      );
    console.log("allTimeStatsArray", allTimeStatsArray);

    let a = {
      ALL_MC_SAT: {
        label: "Merchant Care Satisfaction",
        p: "positive"
      },
      ALL_NPS: {
        label: "NPS",
        p: "positive"
      },
      ALL_AVERAGE_COST_PER_ORDER_CHANGE: {
        label: "Average Cost Per Order Change",
        p: "positive",
        suffix: "%"
      },
      ALL_CASE_ORDER_RATIO_CHANGE: {
        label: "Case:Order Ratio Change",
        p: "positive",
        suffix: "%"
      },
      ALL_REVENUE_CHANGE: {
        label: "Revenue Change",
        p: "positive",
        suffix: "%"
      },
      ALL_SB_SAT: {
        label: "ShipBob Satisfaction",
        p: "positive"
      }
    };

    // let a = allShipBobStats.reduce(
    //   (sum, curr) => ({ ...sum, [curr.key]: { ...curr, p: "positive" } }),
    //   {}
    // );

    allTimeStatsArray.forEach(s => {
      Object.keys(s.values).forEach(n => {
        let p = "neutral";
        const h = n.toLowerCase();
        if (
          [
            "all_revenue_this_quarter",
            "all_revenue_last_quarter",
            "all_shipments_this_quarter",
            "all_shipments_last_quarter",
            "all_revenue_change_vs_prev_quarter",
            "all_revenue_forecast",
            "all_lifetime_revenue",
            "all_lifetime_shipments"
          ].includes(h)
        ) {
          return;
        }
        if (h === "all_mc_sat") {
          if (s.values[n] < 4.6) {
            p = "negative";
          }
          if (s.values[n] > 4.8) {
            p = "positive";
          }
        } else if (h === "all_sb_sat") {
          if (s.values[n] < 4.6) {
            p = "negative";
          }
          if (s.values[n] > 4.8) {
            p = "positive";
          }
        } else if (h === "all_nps") {
          if (s.values[n] < 7) {
            p = "negative";
          }
          if (s.values[n] >= 8) {
            p = "positive";
          }
        } else if (h === "all_revenue_change") {
          if (s.values[n] < -20) {
            p = "negative";
          }
          if (s.values[n] > 20) {
            p = "positive";
          }
        } else if (h === "all_average_cost_per_order_change") {
          if (s.values[n] > 20) {
            p = "negative";
          }
          if (s.values[n] < -20) {
            p = "positive";
          }
        } else if (h === "all_case_order_ratio_change") {
          if (s.values[n] >= 20) {
            p = "negative";
          }
          if (s.values[n] < -20) {
            p = "positive";
          }
        }

        if (!a[n]) {
          // console.log(p, n, a);
        } else {
          if (p !== a[n].p) {
            let netPolarity = "positive";

            let change = "raising ";
            if (
              p === "negative" ||
              (p === "neutral" && a[n].p === "positive")
            ) {
              change = "lowering ";
              netPolarity = "negative";
            }

            newStatEvents = [
              ...newStatEvents,
              {
                metadata: {
                  EVENT_TEXT:
                    a[n].label +
                    " is " +
                    s.values[n] +
                    (a[n].suffix || "") +
                    ", " +
                    change +
                    "health"
                },
                type: h,
                polarity: p,
                netPolarity,
                event: "_QUANTIFAI_HEALTH_CHANGE",
                timestamp: dateFns.formatISO(
                  dateFns.fromUnixTime(Math.round(s.timestamp / 1000))
                ),
                user_id: users[0].group_id,
                _override_name: "Company Health Update",
                email: users[0].group_id
              }
            ];
          }
          a[n].p = p;
        }
      });
    });

    console.log(newStatEvents, "newStatEvents");
  }

  let aggregatedKeyEventsObj = [
    ...newStatEvents,
    ...keyEvents,
    ...emails
  ].reduce((sum, curr) => {
    if (
      (userDomain !== "quantif.ai" &&
        [
          "Click Any Element",
          "Submit Any Form",
          "Change Any Field",
          "user.search.setSearchView",
          "user.search.results.setPageNum",
          "Non-NYC Usage",
          "View Contact Details",
          "IDENTIFY",
          "Tooltip.open",
          "ActionContext.tabClick",
          "Change Filter",
          "cog_hover",
          "referral banner render",
          "referral banner dismiss",
          "Page render",
          "Add Payment Button Click",
          "Save Payment Button Click",
          "Csv File Selection",
          "Payment linker dialog open from detailed view",
          "created",
          "QBR" // QBRs can exist as a future event which can be problematic
        ].includes(curr.event)) ||
      ["PAGE"].includes(curr.type)
    ) {
      return sum;
    }
    if (userDomain === "shipbob.com") {
      if (["CREATED"].includes(curr.event) || !!curr.email_id) {
        return sum;
      }
    }

    const dateKey = {
      [TIME_SCALE.RAW]: {},
      [TIME_SCALE.DAILY]: {},
      [TIME_SCALE.WEEKLY]: {},
      [TIME_SCALE.MONTHLY]: {},
      [TIME_SCALE.ALL_TIME]: {}
    };

    dateKey[TIME_SCALE.DAILY].value = dateFns.format(
      dateFns.parseISO(curr.timestamp),
      "yyyy/MM/dd"
    );
    dateKey[TIME_SCALE.DAILY].iso = dateFns.format(
      dateFns.parseISO(curr.timestamp),
      "yyyy-MM-dd"
    );

    dateKey[TIME_SCALE.WEEKLY].value =
      dateFns.format(
        dateFns.startOfISOWeek(dateFns.parseISO(curr.timestamp)),
        "yyyy/MM/dd"
      ) +
      " - " +
      dateFns.format(
        dateFns.endOfISOWeek(dateFns.parseISO(curr.timestamp)),
        "yyyy/MM/dd"
      );

    dateKey[TIME_SCALE.WEEKLY].iso = dateFns.format(
      dateFns.startOfISOWeek(dateFns.parseISO(curr.timestamp)),
      "yyyy-MM-dd"
    );

    dateKey[TIME_SCALE.MONTHLY].value = dateFns.format(
      dateFns.parseISO(curr.timestamp),
      "yyyy/MM"
    );

    dateKey[TIME_SCALE.MONTHLY].iso = dateFns.format(
      dateFns.startOfMonth(dateFns.parseISO(curr.timestamp)),
      "yyyy-MM-dd"
    );

    let event = curr.type === "PAGE" ? "Viewed Page" : curr.event;
    let valueToStore = {
      ...curr
    };

    if (curr.event === "PUBLISHED_CRX") {
      event = "PUBLISHED_FLOW";
      valueToStore.metadata.EVENT_TEXT = "Published Flow";
    }
    let id = dateKey[TIME_SCALE.DAILY].value;
    if (curr.event === "Start New Session") {
      event = "signin";
    }
    if (curr.event === "Search") {
      id += curr.properties._query;
    }
    if (curr.event === "search") {
      if (!curr.properties) {
        return sum;
      }
      event = "search-" + curr.properties.SEARCH_TYPE;
      id += curr.properties.QUERY + curr.properties.SEARCH_TYPE;
    }
    if (curr.event === "ViewProfile") {
      event = "profile.view";
      id += curr.properties._kolName;
    }
    if (curr.event === "profile.view") {
      if (!curr.properties) {
        return sum;
      }
      id +=
        curr.properties.PROFILE_FIRST_NAME + curr.properties.PROFILE_LAST_NAME;
    }
    if (curr.event === "CAMPAIGN_RECIPIENT_ACTIVITY") {
      id += curr.properties.SUBJECT_LINE + curr.type;
    }
    if (curr.event === "_QUANTIFAI_HEALTH_CHANGE") {
      // console.log(curr);
      id += curr.type;
      // console.log(id);
    }

    if (curr.event === "PUBLISHED_FLOW" && sum[id] && sum[id].value) {
      valueToStore = sum[id].value;
    }

    if (!!curr.email_id) {
      // Salesforce email
      const isEmailFromCustomerCustomer = !curr.from_address.includes(
        "@h1insights.com"
      );

      event = isEmailFromCustomerCustomer ? "sent_email" : "received_email";

      const allEmails = [
        curr.from_address,
        ...(Array.isArray(curr.to_address) ? curr.to_address : []),
        ...(Array.isArray(curr.cc_address) ? curr.cc_address : [])
      ];

      const customerEmails = allEmails.filter(email =>
        users.find(user => user.email === email)
      );

      valueToStore.metadata = {
        EVENT_TEXT: event
      };
      valueToStore.email = customerEmails;

      id +=
        curr.content.SUBJECT + curr.from_address + curr.properties.CREATED_DATE;
    }

    valueToStore.event = event;

    id += event + (curr.source_user_id || curr.user_id);

    if (
      !curr.email_id &&
      !valueToStore.email.includes("@") &&
      valueToStore.properties
    ) {
      valueToStore.email = valueToStore.properties.CONTACT_EMAIL;
    }

    //     let sessionKey = event + valueToStore.email;
    //
    //     if (curr.type === "open" || curr.type === "click") {
    //       sessionKey = sessionKey + "mailchimp";
    //     }

    Object.values(TIME_SCALE)
      .filter(bucket => !["Raw", "All-time"].includes(bucket))
      .forEach(bucket => {
        if (!sessions[bucket][dateKey[bucket].value]) {
          sessions[bucket][dateKey[bucket].value] = {
            aggregate: {},
            individual: {},
            events: {},
            isoDate: dateKey[bucket].iso
          };
        }

        if (
          !sessions[bucket][dateKey[bucket].value].events[valueToStore.event]
        ) {
          sessions[bucket][dateKey[bucket].value].events[
            valueToStore.event
          ] = 0;
        }

        sessions[bucket][dateKey[bucket].value].events[valueToStore.event] += 1;

        if (
          !sessions[bucket][dateKey[bucket].value].aggregate[valueToStore.email]
        ) {
          sessions[bucket][dateKey[bucket].value].aggregate[
            valueToStore.email
          ] = {};
        }

        sessions[bucket][dateKey[bucket].value].aggregate[valueToStore.email][
          event
        ] = {
          value: valueToStore,
          frequency: sessions[bucket][dateKey[bucket].value].aggregate[
            valueToStore.email
          ][event]
            ? sessions[bucket][dateKey[bucket].value].aggregate[
                valueToStore.email
              ][event].frequency + 1
            : 1
        };

        sessions[bucket][dateKey[bucket].value].individual[id] = {
          value: valueToStore,
          frequency: sessions[bucket][dateKey[bucket].value].individual[id]
            ? sessions[bucket][dateKey[bucket].value].individual[id].frequency +
              1
            : 1
        };
      });

    if (!sessions[TIME_SCALE.ALL_TIME]["All-time"]) {
      sessions[TIME_SCALE.ALL_TIME]["All-time"] = {
        aggregate: {},
        count: 0,
        individual: {}
      };
    }

    if (
      !sessions[TIME_SCALE.ALL_TIME]["All-time"].aggregate[valueToStore.email]
    ) {
      sessions[TIME_SCALE.ALL_TIME]["All-time"].aggregate[
        valueToStore.email
      ] = {};
    }

    sessions[TIME_SCALE.ALL_TIME]["All-time"].aggregate[valueToStore.email][
      event
    ] = {
      value: valueToStore,
      frequency: sessions[TIME_SCALE.ALL_TIME]["All-time"].aggregate[
        valueToStore.email
      ][event]
        ? sessions[TIME_SCALE.ALL_TIME]["All-time"].aggregate[
            valueToStore.email
          ][event].frequency + 1
        : 1
    };

    sessions[TIME_SCALE.ALL_TIME]["All-time"].individual[id] = {
      value: valueToStore,
      frequency: sessions[TIME_SCALE.ALL_TIME]["All-time"].individual[id]
        ? sessions[TIME_SCALE.ALL_TIME]["All-time"].individual[id].frequency + 1
        : 1
    };

    keyEventTypes[event] = event;

    if (event !== "CAMPAIGN_RECIPIENT_ACTIVITY") {
      if (userActivity[valueToStore.email]) {
        if (userActivity[valueToStore.email] < valueToStore.timestamp) {
          userActivity[valueToStore.email] = valueToStore.timestamp;
        }
      } else {
        userActivity[valueToStore.email] = valueToStore.timestamp;
      }
    }

    return {
      ...sum,
      [id]: {
        value: valueToStore,
        frequency: sum[id] ? sum[id].frequency + 1 : 0
      }
    };
  }, {});

  console.log("sessions", sessions);

  console.log("aggregatedKeyEventsObj", aggregatedKeyEventsObj);
  const aggregatedKeyEvents = Object.values(aggregatedKeyEventsObj).map(
    curr => ({
      ...curr.value,
      frequency: curr.frequency
    })
  );
  console.log("aggregatedKeyEvents", aggregatedKeyEvents);
  console.log("keyEventTypes", keyEventTypes);

  let currentChampion;
  const mostActiveUserChangeEvents = !highlights.most_active_user
    ? []
    : highlights.most_active_user
        .sort((a, b) =>
          dateFns.compareAsc(dateFns.parseISO(a.date), dateFns.parseISO(b.date))
        )
        .reduce((sum, curr, i, all) => {
          const user = users.find(
            user =>
              (user.traits && user.traits.EMAIL === curr.email) ||
              user.id === curr.user_id
          );
          if (!user) {
            return sum;
          }

          if (user.email && user.email.includes("@appcues.com")) {
            return sum;
          }
          if (
            !!sum.length &&
            curr.email === highlights.most_active_user[i - 1].email
          ) {
            return sum;
          }
          if (
            !!sum.length &&
            curr.user_id === highlights.most_active_user[i - 1].user_id
          ) {
            return sum;
          }

          const name = getNameForUser(user);

          currentChampion = {
            ...user,
            formattedName: name
          };

          return [
            ...sum,
            {
              metadata: {
                EVENT_TEXT: `${name || user.email} became the champion`
              },
              event: "_QUANTIFAI_CHAMPION_CHANGE",
              timestamp: curr.date,
              user_id: user.user_id,
              email: user.email
            }
          ];
        }, []);
  console.log("currentChampion", currentChampion);

  const timeSeries = !Object.keys(keyEventTypes).length
    ? null
    : [TIME_SCALE.DAILY, TIME_SCALE.WEEKLY, TIME_SCALE.MONTHLY]
        .map(timeScale => {
          const dates = Object.values(sessions[timeScale])
            .map(k => k.isoDate)
            .sort();

          if (timeScale === TIME_SCALE.DAILY) {
            const firstDate = dateFns.parseISO(dates[0]);
            const lastDate = dateFns.parseISO(dates[dates.length - 1]);
            const timespan =
              dateFns.differenceInCalendarDays(lastDate, firstDate) + 1;

            return {
              [timeScale]: !timespan
                ? null
                : Array(timespan)
                    .fill()
                    .map((_, i) => {
                      const date = dateFns.format(
                        dateFns.addDays(firstDate, i),
                        "yyyy/MM/dd"
                      );

                      const dateISO = dateFns.format(
                        dateFns.addDays(firstDate, i),
                        "yyyy-MM-dd"
                      );

                      return getDisplayData(
                        users,
                        date,
                        dateISO,
                        getEvents,
                        sessions[timeScale][date],
                        userDomain
                      );
                    })
            };
          }
          if (timeScale === TIME_SCALE.WEEKLY) {
            const firstDate = dateFns.parseISO(dates[0]);
            const lastDate = dateFns.parseISO(dates[dates.length - 1]);
            const timespan =
              dateFns.differenceInCalendarWeeks(lastDate, firstDate) + 1;

            return {
              [timeScale]: !timespan
                ? null
                : Array(timespan)
                    .fill()
                    .map((_, i) => {
                      const nextWeek = dateFns.addWeeks(firstDate, i);

                      const date =
                        dateFns.format(
                          dateFns.startOfISOWeek(nextWeek),
                          "yyyy/MM/dd"
                        ) +
                        " - " +
                        dateFns.format(
                          dateFns.endOfISOWeek(nextWeek),
                          "yyyy/MM/dd"
                        );

                      const dateISO = dateFns.format(
                        dateFns.startOfISOWeek(nextWeek),
                        "yyyy-MM-dd"
                      );

                      return getDisplayData(
                        users,
                        date,
                        dateISO,
                        getEvents,
                        sessions[timeScale][date],
                        userDomain
                      );
                    })
            };
          }
          if (timeScale === TIME_SCALE.MONTHLY) {
            const firstDate = dateFns.parseISO(dates[0]);
            const lastDate = dateFns.parseISO(dates[dates.length - 1]);
            const timespan =
              dateFns.differenceInCalendarMonths(lastDate, firstDate) + 1;

            return {
              [timeScale]: !timespan
                ? null
                : Array(timespan)
                    .fill()
                    .map((_, i) => {
                      const nextMonth = dateFns.addMonths(firstDate, i);

                      const date = dateFns.format(
                        dateFns.startOfMonth(nextMonth),
                        "yyyy/MM"
                      );

                      const dateISO = dateFns.format(
                        dateFns.startOfMonth(nextMonth),
                        "yyyy-MM-dd"
                      );

                      return getDisplayData(
                        users,
                        date,
                        dateISO,
                        getEvents,
                        sessions[timeScale][date],
                        userDomain
                      );
                    })
            };
          }
        })
        .reduce(
          (sum, curr) => ({
            ...sum,
            ...curr
          }),
          {}
        );

  return {
    userActivity,
    sessions,
    keyEventTypes,
    aggregatedKeyEvents,
    currentChampion,
    mostActiveUserChangeEvents,
    timeSeries
  };
};

const addEvents = (session, events, getLatest = false) =>
  session
    ? Object.values(session.individual)
        .filter(event => !events || events.includes(event.value.event))
        .reduce(
          (sum, curr) =>
            getLatest
              ? Math.round(curr.value.properties.value)
              : sum + Math.round(curr.value.properties.value),
          0
        )
    : 0;

const getEvents = (session, events) =>
  session
    ? Object.keys(session.events)
        .filter(event => !events || events.includes(event))
        .reduce((sum, curr) => sum + session.events[curr], 0)
    : 0;

const getUserEvents = (session, userEmail, events) =>
  session && session.aggregate && session.aggregate[userEmail]
    ? Object.keys(session.aggregate[userEmail])
        .filter(event => !events || events.includes(event))
        .reduce(
          (sum, curr) => sum + session.aggregate[userEmail][curr].frequency,
          0
        )
    : 0;

const getDisplayData = (
  users,
  date,
  dateISO,
  getEventFunc,
  session,
  userDomain
) =>
  [
    {
      All: {
        date,
        dateISO,
        overall: getEventFunc(session),
        searches: getEventFunc(session, [
          "Search",
          "search-Name",
          "search-Keyword"
        ]),
        profileViews: getEventFunc(session, [
          "profile.view",
          "provile.viewContactDetails"
        ]),
        emails: getEventFunc(session, ["received_email", "sent_email"]),
        signins: getEventFunc(session, ["signin", "App Open"]),
        eventsCreated:
          userDomain === "eventgeek.com" &&
          getEventFunc(session, ["Created Event"]),
        budgetActivity:
          userDomain === "eventgeek.com" &&
          getEventFunc(session, [
            "Created Budget Category",
            "Deleted Budget Category",
            "Downloaded Budget",
            "Updated Team Budget Amount",
            "Updated Org Budget Report View",
            "Saved Org Budget Report View"
          ]),
        contactActivity:
          userDomain === "eventgeek.com" &&
          getEventFunc(session, [
            "Added Contact to Event",
            "Created Contact",
            "Updated Contact",
            "Removed Contact from Event",
            "Deleted Contact",
            "Imported Contacts",
            "Downloaded Contacts",
            "Enabled Contact Sync",
            "Disabled Contact Sync"
          ]),
        publishActivity:
          userDomain === "eventgeek.com" &&
          getEventFunc(session, ["Published Shared Event Calendar"]),
        meetingActivity:
          userDomain === "eventgeek.com" &&
          getEventFunc(session, [
            "Updated Meeting",
            "Created Meeting",
            "Deleted Meeting"
          ]),
        REVENUE_CHANGE_C: addEvents(session, ["REVENUE_CHANGE_C"], true),
        CASE_ORDER_RATIO_CHANGE_C: addEvents(session, [
          "CASE_ORDER_RATIO_CHANGE_C"
        ]),
        CASE_ORDER_RATIO_CHANGE_C: addEvents(session, [
          "CASE_ORDER_RATIO_CHANGE_C"
        ]),
        REVENUE_THIS_QUARTER_C: addEvents(
          session,
          ["REVENUE_THIS_QUARTER_C"],
          true
        ),
        AVERAGE_COST_PER_ORDER_THIS_QUARTER_C: addEvents(
          session,
          ["AVERAGE_COST_PER_ORDER_THIS_QUARTER_C"],
          true
        ),
        AVERAGE_COST_PER_ORDER_CHANGE_C: addEvents(session, [
          "AVERAGE_COST_PER_ORDER_CHANGE_C"
        ]),
        REVENUE_FORECAST: addEvents(session, ["REVENUE_FORECAST"], true),
        SHIPMENTS_THIS_QUARTER_C: addEvents(
          session,
          ["SHIPMENTS_THIS_QUARTER_C"],
          true
        ),
        CASE_ORDER_RATIO_THIS_QUARTER_C: addEvents(
          session,
          ["CASE_ORDER_RATIO_THIS_QUARTER_C"],
          true
        ),
        actions: getEventFunc(session, ["Perform Action"]),
        "Add Mass Payment Success": getEventFunc(session, [
          "Add Mass Payment Success"
        ])
      }
    },
    ...users.map(user => ({
      [user.email]: {
        overall: getUserEvents(session, user.email),
        searches: getUserEvents(session, user.email, [
          "Search",
          "search-Name",
          "search-Keyword"
        ]),
        profileViews: getUserEvents(session, user.email, [
          "profile.view",
          "provile.viewContactDetails"
        ]),
        emails: getUserEvents(session, user.email, [
          "received_email",
          "sent_email"
        ]),
        signins: getUserEvents(session, user.email, ["signin"])
      }
    }))
  ].reduce(
    (sum, curr) => ({
      ...sum,
      ...curr
    }),
    {}
  );

const UserRow = styled(Row)`
  border-bottom-width: 1px;
  &:not(:last-child) {
    border-bottom-style: solid;
  }
  padding-top: 8px;
  padding-bottom: 8px;
  border-color: #eee;
  position: relative;

  .onHover {
    opacity: 0;
    transition: opacity ease 0.2s;
    cursor: pointer;
  }
  &:hover .onHover {
    opacity: 0.6;

    &:hover {
      opacity: 0.85;
    }
  }
`;

const getUserTraits = (user, userDomain) => {
  let email, name, title;

  if (userDomain === "eventgeek.com") {
    email = user.email;
    name =
      (user.traits.PIPEDRIVE && user.traits.PIPEDRIVE.NAME) ||
      user.traits.first_name + " " + user.traits.last_name;
    title = email;
  } else {
    email = user.traits.EMAIL;

    name =
      user.traits.NAME || user.traits.FIRST_NAME || user.traits.LAST_NAME
        ? capitalise(
            user.traits.NAME ||
              user.traits.FIRST_NAME + " " + user.traits.LAST_NAME
          )
        : email;

    title = capitalise(user.traits.TITLE || user.traits.POSITION, true, "_");
  }

  return {
    email,
    name,
    title
  };
};

const UsersPanel = ({
  account,
  highlights,
  timeSeries,
  userActivity,
  userDomain
}) => {
  const timeScale = ["upflow.io", "instabug.com"].includes(userDomain)
    ? TIME_SCALE.DAILY
    : TIME_SCALE.WEEKLY;
  console.log("timeSeries", timeSeries);

  if (!timeSeries) {
    return <></>;
  }

  return (
    <Panel>
      <Center>
        <H1>Users</H1>
        {!account.users.length && <Caption>No users listed</Caption>}
      </Center>
      <Pane fontSize={14} marginTop={12} color="#475872">
        {Object.values(
          account.users.reduce(
            (sum, curr) => ({
              ...sum,
              [curr.user_id]: curr
            }),
            {}
          )
        )
          .sort((a, b) => {
            const { email: aEmail } = getUserTraits(a, userDomain);
            const { email: bEmail } = getUserTraits(b, userDomain);

            const aDate = userActivity[aEmail] || "";
            const bDate = userActivity[bEmail] || "";

            if (aDate > bDate) {
              return -1;
            }
            if (bDate > aDate) {
              return 1;
            }
            return 0;
          })
          .map((u, i) => {
            const user = getUserTraits(u, userDomain);

            return (
              <UserRow key={i} minHeight={52} alignItems="center">
                {/* <AvatarPlaceholder */}
                {/*   backgroundColor={COLOR_RANGE[i % COLOR_RANGE.length]} */}
                {/* > */}
                {/*   {user.fullname || user.name || user.email || user.traits.NAME */}
                {/*     ? (user.fullname || */}
                {/*         user.name || */}
                {/*         user.email || */}
                {/*         user.traits.NAME)[0] */}
                {/*     : "-"} */}
                {/* </AvatarPlaceholder> */}

                <Pane marginLeft={10}>
                  <Pane position="relative">
                    <Pane
                      width={5}
                      height={5}
                      position="absolute"
                      left={-10}
                      marginTop={6}
                      borderRadius={20}
                      backgroundColor={COLOR_RANGE[i % COLOR_RANGE.length]}
                    ></Pane>

                    {user.name}
                  </Pane>

                  <Pane
                    className="u-title"
                    fontSize={12}
                    opacity={0.8}
                    paddingRight={60}
                  >
                    {user.title}
                  </Pane>
                  {userActivity[user.email] && (
                    <Pane
                      fontSize={10}
                      opacity={0.8}
                      position="absolute"
                      top={10}
                      right={0}
                      title="Last activity"
                    >
                      {dateFns.format(
                        dateFns.parseISO(userActivity[user.email]),
                        "yyyy/MM/dd"
                      )}
                    </Pane>
                  )}

                  <Pane
                    fontSize={14}
                    marginTop={12}
                    color="#475872"
                    position="absolute"
                    right={-30}
                    transform="scale(0.5)"
                    bottom={-22}
                  >
                    <Sparkline
                      ariaLabel="All activity"
                      margin={{
                        top: 24,
                        bottom: 24
                      }}
                      width={100}
                      height={70}
                      data={timeSeries[timeScale]}
                      valueAccessor={datum =>
                        datum[user.email] ? datum[user.email].overall : 0
                      }
                    >
                      <LineSeries
                        showArea={false}
                        stroke={allColors.grape[7]}
                      />
                    </Sparkline>
                  </Pane>

                  {/* <Pane */}
                  {/*   className="onHover" */}
                  {/*   position="absolute" */}
                  {/*   top={26} */}
                  {/*   right={40} */}
                  {/* > */}
                  {/*   <a */}
                  {/*     target="_blank" */}
                  {/*     href={`http://na49.lightning.force.com/lightning/r/Contact/${user.user_id}/view`} */}
                  {/*   > */}
                  {/*     <Icon */}
                  {/*       icon="link" */}
                  {/*       size={10} */}
                  {/*       color="muted" */}
                  {/*       title="Open in Salesforce" */}
                  {/*     /> */}
                  {/*   </a> */}
                  {/* </Pane> */}
                  {/* <Pane */}
                  {/*   className="onHover" */}
                  {/*   position="absolute" */}
                  {/*   top={26} */}
                  {/*   right={0} */}
                  {/* > */}
                  {/*   <a href={`mailto:${user.email}`}> */}
                  {/*     <Icon */}
                  {/*       icon="envelope" */}
                  {/*       size={10} */}
                  {/*       color="muted" */}
                  {/*       title={user.email} */}
                  {/*     /> */}
                  {/*   </a> */}
                  {/* </Pane> */}
                  {/* <Pane */}
                  {/*   className="onHover" */}
                  {/*   position="absolute" */}
                  {/*   top={26} */}
                  {/*   right={20} */}
                  {/* > */}
                  {/*   <a href={`tel:${user.traits.PHONE}`}> */}
                  {/*     <Icon */}
                  {/*       icon="phone" */}
                  {/*       size={10} */}
                  {/*       color="muted" */}
                  {/*       title={user.traits.PHONE} */}
                  {/*     /> */}
                  {/*   </a> */}
                  {/* </Pane> */}
                </Pane>
              </UserRow>
            );
          })}
      </Pane>
    </Panel>
  );
};

const ThreadSnip = styled(Pane)`
  background-color: white;
  padding: 14px 16px 8px;
  position: relative;
  font-size: 12px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.03), 0 1px 3px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.03);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-top: -6px;
  color: #374151;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${prop => prop.cursor || "default"};

  .thread-snip-snippet {
    font-weight: 400;
    color: rgba(71, 88, 114, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
    margin-right: 8px;
    flex-shrink: 1;
    flex-basis: 0;
    flex-grow: 1;
  }

  .reaction-holder {
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
  }

  &:hover .reaction-holder {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
`;

const OverflowPane = styled(Pane)`
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  transition: height 0.2s ease;
`;

const EmailMetaFrom = styled(Pane)`
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-right: 4px;
`;

const CalendarPanel = ({ calendar }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!calendar || !calendar.length) {
    return (
      <Panel position="relative">
        <Center>
          <H1>Meetings</H1>
          <Caption>No meetings yet.</Caption>
        </Center>
      </Panel>
    );
  }

  const validEvents = Object.values(
    calendar
      .filter(e => e.status === "confirmed")
      .reduce(
        (sum, curr) => ({
          ...sum,
          [curr.title + curr.start_time + curr.participants.sort().join()]: {
            ...curr,
            isInFuture: dateFns.isFuture(dateFns.fromUnixTime(curr.start_time))
          }
        }),
        {}
      )
  ).sort((a, b) => {
    if (a.start_time > b.start_time) {
      return -1;
    }
    if (a.start_time < b.start_time) {
      return 1;
    }
    return 0;
  });

  if (!validEvents || !validEvents.length) {
    return false;
  }

  console.log("validEvents", validEvents);

  // console.log(validEvents);

  const mostRecentEvent = validEvents[0];

  return (
    <>
      <Panel padding={16} position="relative" zIndex={1011}>
        <Center marginBottom={12}>
          <H1>Meetings</H1>
        </Center>

        <Pane fontSize={14} color="#374151">
          <Pane color="#374151">
            <Pane
              className="email-meta"
              fontSize={12}
              marginBottom={4}
              display="flex"
            >
              <EmailMetaFrom
                opacity={0.6}
                className="email-meta-from"
                flexGrow={1}
              >
                {Object.keys(
                  Object.values(
                    mostRecentEvent.raw_participants.reduce(
                      (sum, curr) => ({
                        ...sum,
                        [curr.email]: curr.name
                      }),
                      {}
                    )
                  )
                    .map(t => getDeCommadName(t))
                    .reduce(
                      (sum, curr) => ({
                        ...sum,
                        [curr]: true
                      }),
                      {}
                    )
                ).join(", ")}
              </EmailMetaFrom>
              <Pane
                className="email-meta-date"
                fontWeight={mostRecentEvent.isInFuture ? 600 : 400}
                color={
                  mostRecentEvent.isInFuture
                    ? "hsla(38, 63%, 54%, 1)"
                    : "inherit"
                }
                opacity={mostRecentEvent.isInFuture ? 1 : 0.6}
                flexShrink={0}
                title={getFormattedEmailDate(mostRecentEvent.start_time, true)}
              >
                {mostRecentEvent.isInFuture && `Upcoming `}
                {getFormattedEmailDate(mostRecentEvent.start_time)}
              </Pane>
            </Pane>
            <Pane className="email-content">
              <Pane
                className="email-content-subject"
                alignItems="baseline"
                display="flex"
              >
                <Pane fontWeight={600} marginY={3} marginRight={5}>
                  {mostRecentEvent.title}
                </Pane>
              </Pane>

              <Pane
                className="email-content-snippet"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={mostRecentEvent.description}
              >
                {mostRecentEvent.description}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Panel>
      <OverflowPane
        height={
          isExpanded
            ? validEvents.length > 14
              ? 422
              : (validEvents.length - 1) * 31 + 10
            : validEvents.length > 3
            ? 102
            : validEvents.length * 34
        }
        overflow={isExpanded ? "auto" : "hidden"}
      >
        {validEvents.slice(1, 1000).map((e, i) => (
          <ThreadSnip
            className="thread-snip"
            zIndex={1010 - i}
            key={e.event_id}
            title={e.description || e.title}
            marginBottom={isExpanded && i == validEvents.length - 2 ? 10 : 0}
          >
            <Row>
              <Pane overflow="hidden" textOverflow="ellipsis">
                {e.title}
              </Pane>
              <Pane className="thread-snip-snippet">{e.description}</Pane>
              <Pane
                flexShrink={0}
                fontWeight={e.isInFuture ? 600 : 400}
                color={e.isInFuture ? "hsla(38, 63%, 54%, 1)" : "inherit"}
                opacity={e.isInFuture ? 1 : 0.6}
                title={getFormattedEmailDate(e.start_time, true)}
              >
                {e.isInFuture && `Upcoming `}
                {getFormattedEmailDate(e.start_time)}
              </Pane>
            </Row>
            {/* <Pane */}
            {/*   position="absolute" */}
            {/*   className="reaction-holder" */}
            {/*   display="flex" */}
            {/*   right={0} */}
            {/*   top={6} */}
            {/*   bottom={0} */}
            {/*   alignItems="center" */}
            {/*   backgroundColor="white" */}
            {/*   paddingLeft={6} */}
            {/* > */}
            {/*   <Reaction>😄</Reaction> */}
            {/*   <Reaction>🙂</Reaction> */}
            {/*   <Reaction>😶</Reaction> */}
            {/*   <Reaction>😟</Reaction> */}
            {/* </Pane> */}
          </ThreadSnip>
        ))}
      </OverflowPane>
      {validEvents.length > 4 && (
        <ThreadSnip
          className="threads-show-all"
          zIndex={0}
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          title={`${
            validEvents.length - 4
          } more calendar events since ${getFormattedEmailDate(
            validEvents[validEvents.length - 1].start_time,
            true
          )}`}
        >
          {!isExpanded && (
            <Center opacity={0.8}>
              {validEvents.length - 4} more calendar events
            </Center>
          )}
          {isExpanded && (
            <Center opacity={0.8}>Show less calendar events</Center>
          )}
        </ThreadSnip>
      )}
    </>
  );
};

const Reaction = styled(Pane)`
  margin-right: 3px;
  padding-left: 3px;
  padding-right: 1px;
  font-size: 20px;

  text-shadow: 0 1px 5px rgba(0, 0, 0, 0);
  transition: text-shadow 0.2s ease;

  &:hover {
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  }
`;

const ControlledThread = ({ thread, i, isExpanded, threadsLength }) => {
  return (
    <ThreadSnip
      className="thread-snip"
      zIndex={1010 - i}
      key={thread.thread_id}
      title={thread.snippet}
      marginBottom={isExpanded && i == threadsLength - 2 ? 10 : 0}
      position="relative"
    >
      <Row>
        <Pane overflow="hidden" textOverflow="ellipsis">
          {thread.subject}
        </Pane>
        <Pane className="thread-snip-snippet">{thread.snippet}</Pane>
        <Pane
          flexShrink={0}
          opacity={0.6}
          title={getFormattedEmailDate(thread.last_message_timestamp, true)}
        >
          {getFormattedEmailDate(thread.last_message_timestamp)}
        </Pane>
      </Row>
      {/* <Pane */}
      {/*   position="absolute" */}
      {/*   className="reaction-holder" */}
      {/*   display="flex" */}
      {/*   right={0} */}
      {/*   top={6} */}
      {/*   bottom={0} */}
      {/*   alignItems="center" */}
      {/*   backgroundColor="white" */}
      {/*   paddingLeft={6} */}
      {/* > */}
      {/*   <Reaction>😄</Reaction> */}
      {/*   <Reaction>🙂</Reaction> */}
      {/*   <Reaction>😶</Reaction> */}
      {/*   <Reaction>😟</Reaction> */}
      {/* </Pane> */}
    </ThreadSnip>
  );
};

const EmailPanel3 = ({ threads, userEmail }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!threads || !threads.length) {
    return (
      <Panel position="relative">
        <Center>
          <H1>Emails</H1>
          <Caption>No emails yet.</Caption>
        </Center>
      </Panel>
    );
  }

  const sortedThreads = Object.values(
    [...threads]
      .filter(a =>
        [
          "Updated invitation",
          "Declined: ",
          "Accepted: ",
          "Proposed new time",
          "Invitation: ",
          "Notification: ",
          "New Event: ",
          "Acceptée : ",
          "Accepté: ",
          "Refusé: "
        ].every(e => !a.subject.includes(e))
      )
      .reduce(
        (sum, curr) => ({
          ...sum,
          [curr.subject + curr.snippet + curr.participants]: curr
        }),
        {}
      )
  ).sort((a, b) => {
    if (a.last_message_timestamp < b.last_message_timestamp) return 1;
    if (a.last_message_timestamp > b.last_message_timestamp) return -1;
    return 0;
  });

  // https://mail.google.com/mail/u/?authuser=user@gmail.com#inbox/FMfcgxwGDDqlmxKVHZjdCcBpphRZTtDw

  console.log("sortedThreads", sortedThreads);

  return (
    <>
      <Panel padding={16} position="relative" zIndex={1011}>
        <Center marginBottom={12}>
          <H1>Emails</H1>
        </Center>

        <Pane fontSize={14} color="#374151">
          <Thread3 thread={sortedThreads[0]} userEmail={userEmail} />
        </Pane>
      </Panel>
      {sortedThreads.length > 1 && (
        <OverflowPane
          height={
            isExpanded
              ? sortedThreads.length > 12
                ? 422
                : (sortedThreads.length - 1) * 31 + 10
              : sortedThreads.length > 3
              ? 102
              : sortedThreads.length * 34
          }
          overflow={isExpanded ? "auto" : "hidden"}
        >
          {sortedThreads.slice(1, 1000).map((thread, i) => (
            <ControlledThread
              thread={thread}
              i={i}
              key={thread.thread_id}
              isExpanded={isExpanded}
              threadsLength={sortedThreads.length}
            />
          ))}
        </OverflowPane>
      )}
      {sortedThreads.length > 4 && (
        <ThreadSnip
          className="threads-show-all"
          zIndex={0}
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          title={`${
            sortedThreads.length - 4
          } more emails since ${getFormattedEmailDate(
            sortedThreads[sortedThreads.length - 1].last_message_timestamp,
            true
          )}`}
        >
          {!isExpanded && (
            <Center opacity={0.8}>
              {sortedThreads.length - 4} more emails
            </Center>
          )}
          {isExpanded && <Center opacity={0.8}>Show less emails</Center>}
        </ThreadSnip>
      )}
    </>
  );
};

const getDeCommadName = name => {
  if (name.includes("@")) {
    return name;
  }
  let parts = name.split(",");
  if (parts.length <= 1) {
    parts = name.split(" ");

    if (parts.length <= 1) {
      return capitalise(name, true);
    }

    return (
      capitalise(parts[0].trim(), true, "-") +
      " " +
      capitalise(parts[1].trim(), true, "-")
    );
  }
  return (
    capitalise(parts[1].trim(), true, "-") +
    " " +
    capitalise(parts[0].trim(), true, "-")
  );
};

const Thread3 = ({ thread, userEmail }) => {
  //   const [isOpen, setIsOpen] = useState(false);
  //
  //   const mostRecentEmail = thread[0];
  //   const { participants, formattedDate } = getFormattedEmailData(
  //     mostRecentEmail
  //   );

  const isUserTheSender = thread.user_origin_id === userEmail;

  return (
    <Pane color="#374151">
      <Pane
        cursor={isUserTheSender ? "pointer" : "auto"}
        onClick={() => {
          if (isUserTheSender) {
            window.open(
              `https://mail.google.com/mail/u/?authuser=${userEmail}#inbox/${thread.thread_id}`,
              "_blank"
            );
          }
        }}
        // onClick={() => setIsOpen(!isOpen)}
        // marginBottom={18}
        // paddingBottom={18}
        // borderBottom="1px solid #eee"
      >
        <Pane
          className="email-meta"
          opacity={0.6}
          fontSize={12}
          marginBottom={4}
          display="flex"
        >
          <Pane className="email-meta-from" flexGrow={1}>
            {Object.keys(
              Object.values(
                thread.raw_participants.reduce(
                  (sum, curr) => ({
                    ...sum,
                    [curr.email]: curr.name
                  }),
                  {}
                )
              )
                .map(t => getDeCommadName(t))
                .reduce(
                  (sum, curr) => ({
                    ...sum,
                    [curr]: true
                  }),
                  {}
                )
            ).join(", ")}
          </Pane>
          <Pane
            className="email-meta-date"
            flexShrink={0}
            title={getFormattedEmailDate(thread.last_message_timestamp, true)}
          >
            {getFormattedEmailDate(thread.last_message_timestamp)}
          </Pane>
        </Pane>
        <Pane className="email-content">
          <Pane
            className="email-content-subject"
            alignItems="baseline"
            display="flex"
          >
            <Pane fontWeight={600} marginY={3} marginRight={5}>
              {thread.subject}
            </Pane>
            <Pane is="span" opacity={0.6} fontSize={12}>
              {thread.length > 1 && thread.length}
            </Pane>
          </Pane>
          <Pane
            className="email-content-snippet"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            title={he.decode(thread.snippet)}
          >
            {he.decode(thread.snippet)}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

const EmailPanel2 = ({ threads }) => {
  const mostRecentEmail = threads[0][0];
  const { formattedDate, participants } = getFormattedEmailData(
    mostRecentEmail
  );
  return (
    <>
      <Panel paddingY={2} position="relative" zIndex={100}>
        <Pane color="#374151" fontSize={14} marginTop={12}>
          <Pane cursor="pointer" marginBottom={12}>
            <Pane
              className="email-meta"
              opacity={0.6}
              fontSize={12}
              marginBottom={4}
              display="flex"
            >
              <Pane className="email-meta-from" flexGrow={1}>
                {participants.join(", ")}
              </Pane>
              <Pane
                className="email-meta-date"
                title={mostRecentEmail.gmail_date}
              >
                {formattedDate}
              </Pane>
            </Pane>
            <Pane className="email-content">
              <Pane
                className="email-content-subject"
                alignItems="baseline"
                display="flex"
              >
                <Pane fontWeight={600} marginY={3} marginRight={5}>
                  {mostRecentEmail.gmail_subject}
                </Pane>
                <Pane is="span" opacity={0.6} fontSize={12}>
                  {threads[0].length > 1 && threads[0].length}
                </Pane>
              </Pane>
              <Pane
                className="email-content-snippet"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={he.decode(mostRecentEmail.gmail_snippet)}
              >
                {he.decode(mostRecentEmail.gmail_snippet)}
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Panel>
      <Pane
        color="#374151"
        fontSize={12}
        marginTop={-2}
        paddingTop={2}
        backgroundColor="white"
      >
        {threads.slice(1).map((thread, i) => {
          const { formattedDate } = getFormattedEmailData(thread[0]);
          return (
            <Pane
              className="thread"
              boxShadow="0 1px 6px rgba(0,0,0,0.07)"
              backgroundColor="white"
              paddingX={24}
              paddingTop={7}
              paddingBottom={5}
              whiteSpace="nowrap"
              position="relative"
              zIndex={99 - i}
              borderBottomLeftRadius={6}
              borderBottomRightRadius={6}
              color="hsl(216, 13%, 51%)"
              display="flex"
            >
              <Pane overflow="hidden" textOverflow="ellipsis" paddingRight={12}>
                <Pane is="span" fontWeight={600} color="hsl(216, 23%, 28%)">
                  {thread[0].gmail_subject}
                </Pane>{" "}
                {he.decode(thread[0].gmail_snippet)}
              </Pane>
              <Pane
                flexGrow={1}
                flexShrink={0}
                className="email-meta-date"
                title={mostRecentEmail.gmail_date}
              >
                {formattedDate}
              </Pane>
            </Pane>
          );
        })}
      </Pane>
    </>
  );
};

const EmailPanel = ({ threads }) => (
  <Panel>
    <Center>
      <H1>Emails</H1>
    </Center>
    <Pane fontSize={14} marginTop={12}>
      {threads.map(thread => (
        <Thread thread={thread} />
      ))}
    </Pane>
  </Panel>
);

const getFormattedEmailDate = (time, getFullDate = false) => {
  const emailDateFunc = dateFns.fromUnixTime(time);

  let formattedDate;

  if (getFullDate) {
    if (dateFns.isToday(emailDateFunc)) {
      formattedDate = dateFns.format(emailDateFunc, "p");
    } else if (dateFns.isThisYear(emailDateFunc)) {
      formattedDate = dateFns.format(emailDateFunc, "MMM d p");
    } else {
      formattedDate = dateFns.format(emailDateFunc, "P p");
    }
  } else if (dateFns.isToday(emailDateFunc)) {
    formattedDate = dateFns.format(emailDateFunc, "p");
  } else if (dateFns.isThisYear(emailDateFunc)) {
    formattedDate = dateFns.format(emailDateFunc, "MMM d");
  } else {
    formattedDate = dateFns.format(emailDateFunc, "P");
  }
  return formattedDate;
};

const getFormattedEmailData = (email, fullDate) => {
  const formattedDate = getFormattedEmailDate(
    email.gmail_internal_date / 1000,
    fullDate
  );

  const reg = /\"(\S+)\,\s(\S+)\"/gm;

  const swapNameOrder = previousName =>
    Array.from(previousName.matchAll(reg)).reduce((sum, curr) => {
      return sum.replace(curr[0], `${curr[2]} ${curr[1]}`);
    }, previousName);

  const reFrom = swapNameOrder(email.gmail_from);

  const reTo = swapNameOrder(email.gmail_to);

  const participants = [reFrom, ...reTo.split(", ")].map(participant => {
    let name = participant;
    // emma@appcues.com
    // Emma Crimmins <emma@appcues.com>
    // "Emma Crimmins" <emma@appcues.com>
    // "Crimmins, Emma" <emma@appcues.com>
    // replace with regex
    const leftIndicatorCaret = participant.indexOf("<");
    if (leftIndicatorCaret > 0) {
      name = name.substring(0, leftIndicatorCaret).trim();
    }
    return name;
  });

  return {
    participants,
    formattedDate
  };
};

const Thread = ({ thread }) => {
  const [isOpen, setIsOpen] = useState(false);

  console.log("thread", thread);
  const mostRecentEmail = thread[0];
  const { participants, formattedDate } = getFormattedEmailData(
    mostRecentEmail
  );

  return (
    <Pane color="#374151">
      <Pane
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
        marginBottom={18}
        paddingBottom={18}
        borderBottom="1px solid #eee"
      >
        <Pane
          className="email-meta"
          opacity={0.6}
          fontSize={12}
          marginBottom={4}
          display="flex"
        >
          <Pane className="email-meta-from" flexGrow={1}>
            {participants.join(", ")}
          </Pane>
          <Pane className="email-meta-date" title={mostRecentEmail.gmail_date}>
            {formattedDate}
          </Pane>
        </Pane>
        <Pane className="email-content">
          <Pane
            className="email-content-subject"
            alignItems="baseline"
            display="flex"
          >
            <Pane fontWeight={600} marginY={3} marginRight={5}>
              {mostRecentEmail.gmail_subject}
            </Pane>
            <Pane is="span" opacity={0.6} fontSize={12}>
              {thread.length > 1 && thread.length}
            </Pane>
          </Pane>

          {isOpen && (
            <Pane marginLeft={20}>
              {thread.map(email => {
                const { participants, formattedDate } = getFormattedEmailData(
                  email,
                  true
                );

                return (
                  <Pane marginTop={14}>
                    <Pane
                      opacity={0.6}
                      fontSize={12}
                      marginBottom={4}
                      display="flex"
                    >
                      <Pane className="email-meta-from" flexGrow={1}>
                        {participants[0].split(" ")[0]} to{" "}
                        {participants[1].split(" ")[0]}
                      </Pane>
                      <Pane
                        className="email-meta-date"
                        title={email.gmail_date}
                      >
                        {formattedDate}
                      </Pane>
                    </Pane>

                    <Pane>{he.decode(email.gmail_snippet)}</Pane>
                  </Pane>
                );
              })}
            </Pane>
          )}
          {!isOpen && (
            <Pane
              className="email-content-snippet"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              title={he.decode(mostRecentEmail.gmail_snippet)}
            >
              {he.decode(mostRecentEmail.gmail_snippet)}
            </Pane>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};

const Hyperlink = styled.a`
  font-weight: 600;
  text-decoration: none;
  color: hsla(201, 100%, 47%, 1);
  transition: all 0.2s ease;
  &:hover {
    color: hsla(201, 100%, 62%, 1);
  }
`;

const AvatarFallback = styled(Pane)`
  width: 38px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 21px;
  height: 38px;
  flex-shrink: 0;
`;

const FEATURE_META = {
  "h1insights.com": {
    percent_of_average_weekly_profile_view: {
      field: "percent_of_average_weekly_profile_view",
      label: "Profile views this week",
      type: "int",
      static: true,
      suffix: "% of normal",
      defaultValue: 0
    },
    percent_of_average_weekly_search: {
      field: "percent_of_average_weekly_search",
      label: "Searches this week",
      type: "int",
      static: true,
      suffix: "% of normal",
      defaultValue: 0
    },
    count_active_users_last_week: {
      field: "count_active_users_last_week",
      label: "Active users last week",
      type: "int",
      static: true,
      defaultValue: 0
    },
    pct_of_users_in_last_30: {
      field: "pct_of_users_in_last_30",
      label: "% of users active in last 30 days",
      type: "%",
      static: true,
      defaultValue: 0
    },
    has_user_in_last_15: {
      field: "has_user_in_last_15",
      positiveLabel: "Active in last 15 days",
      negativeLabel: "Not active in last 15 days",
      type: "bool",
      static: true,
      defaultValue: 0
    }
  }
};

const PropertiesPanel = ({ account, owners, currentChampion, userDomain }) => {
  const [hadAvatarError, setHadAvatarError] = useState(false);
  let propertyList;

  if (userDomain === "h1insights.com") {
    propertyList = [
      // { key: "group_id", label: "Account ID", type: "id" },
      // { key: "ACCOUNT_PRIORITY_C", label: "Account Priority" },
      // { key: "PIPELINE_STATUS_C", label: "Pipeline Status" },
      // { key: "ACCOUNT_STATUS_C", label: "Account Status" },
      // { key: "TYPE", label: "Type" },
      {
        key: "OWNER",
        label: "Owner"
      },

      // { key: "current_stage", label: "Stage" },
      // { key: "current_mrr", label: "MRR", unit: "$" },
      // {
      //   key: "modify_time || latest_status_date",
      //   label: "Last Seen",
      //   type: "date"
      // },
      // { key: "renewal_date", label: "Next Renewal", type: "date" },
      // { key: "join_date", label: "Customer For", type: "date" },
      // { key: "assigned_csms", label: "Owner" },
      {
        key: "current_champion",
        label: "Current Champion"
      },
      {
        key: "_health_score",
        label: "Health"
      }
    ];
  } else if (userDomain === "upflow.io") {
    propertyList = [
      // { key: "current_stage", label: "Stage" },
      // { key: "current_mrr", label: "MRR", unit: "$" },
      // {
      //   key: "modify_time || latest_status_date",
      //   label: "Last Seen",
      //   type: "date"
      // },
      // { key: "renewal_date", label: "Next Renewal", type: "date" },
      // { key: "join_date", label: "Customer For", type: "date" },
      // { key: "assigned_csms", label: "Owner" },
      {
        key: "current_champion",
        label: "Current Champion"
      }
    ];
  } else if (userDomain === "shipbob.com") {
    propertyList = [
      // { key: "group_id", label: "Account ID", type: "id" },
      // { key: "ACCOUNT_PRIORITY_C", label: "Account Priority" },
      // { key: "PIPELINE_STATUS_C", label: "Pipeline Status" },
      // { key: "ACCOUNT_STATUS_C", label: "Account Status" },
      // { key: "TYPE", label: "Type" },
      {
        key: "MERCHANT_SUCCESS_MANAGER_C",
        label: "MSM"
      },
      // { key: "SDR_OWNER_C", label: "SDR Owner" },
      {
        key: "PHONE",
        label: "Phone"
      }

      // { key: "current_stage", label: "Stage" },
      // { key: "current_mrr", label: "MRR", unit: "$" },
      // {
      //   key: "modify_time || latest_status_date",
      //   label: "Last Seen",
      //   type: "date"
      // },
      // { key: "renewal_date", label: "Next Renewal", type: "date" },
      // { key: "join_date", label: "Customer For", type: "date" },
      // { key: "assigned_csms", label: "Owner" },
      // { key: "current_champion", label: "Current Champion" }
    ];
  } else if (userDomain === "quantif.ai") {
    propertyList = [
      {
        key: "account_id",
        label: "Account ID",
        type: "id"
      }
    ];
  } else {
    propertyList = [
      {
        key: "account_id",
        label: "Account ID",
        type: "id"
      }
    ];
    //       { key: "account_id", label: "Account ID", type: "id" },
    //
    //       { key: "current_stage", label: "Stage" },
    //       { key: "current_mrr", label: "MRR", unit: "$" },
    //       {
    //         key: "modify_time || latest_status_date",
    //         label: "Last Seen",
    //         type: "date"
    //       },
    //       { key: "renewal_date", label: "Next Renewal", type: "date" },
    //       { key: "join_date", label: "Customer For", type: "date" },
    //       { key: "assigned_csms", label: "Owner" },
    //       { key: "current_champion", label: "Current Champion" }
    //     ];
  }

  const ComputedPropertyValue = ({ propMeta, account }) => {
    let newValue;

    const accountMeta = account.traits ? account.traits : account;

    if (propMeta.key.includes(" || ")) {
      const fallbacks = propMeta.key.split(" || ");
      newValue = account[fallbacks.find(fallback => !!account[fallback])];
    } else {
      newValue = accountMeta[propMeta.key];
    }
    if (propMeta.type === "id") {
      newValue = (
        <Row alignItems="center">
          {account[propMeta.key]}
          {!account.traits && (
            <>
              <Pane is="span" marginX={4} fontSize={9} opacity={0.7}>
                &bull;
              </Pane>
              <Hyperlink
                target="_blank"
                href={`https://studio.appcues.com/?view_as=${
                  account[propMeta.key]
                }`}
              >
                Spoof
              </Hyperlink>
            </>
          )}
        </Row>
      );
    }
    if (propMeta.key === "current_stage") {
      newValue = <LifecycleStagePill stage={newValue} />;
    }
    if (propMeta.key === "assigned_csms") {
      const csm = CSMS.find(csm => csm.email === newValue[0]);
      const csmName = csm.firstName + " " + csm.lastName;

      newValue = (
        <Pane is="span" fontWeight={500}>
          {csmName}
        </Pane>
      );
    }
    if (propMeta.key === "OWNER") {
      const csm = owners.find(csm => csm.owner_id === account.traits.OWNER_ID);
      const csmName = csm && csm.traits.NAME;

      newValue = (
        <Pane is="span" fontWeight={500}>
          {csmName}
        </Pane>
      );
    }
    if (propMeta.key === "MERCHANT_SUCCESS_MANAGER_C") {
      const csm = owners.find(
        csm => csm.owner_id === account.traits.MERCHANT_SUCCESS_MANAGER_C
      );
      const csmName = csm && csm.traits.NAME;

      newValue = (
        <Pane is="span" fontWeight={500}>
          {csmName}
        </Pane>
      );
    }
    if (propMeta.key === "LINK_TO_OUTREACH_PROSPECT_C") {
      newValue = (
        <Pane
          is="a"
          href={account.traits.LINK_TO_OUTREACH_PROSPECT_C}
          target="_blank"
        >
          Contact link
        </Pane>
      );
    }
    if (propMeta.key === "current_champion") {
      const championUser = account[propMeta.key];
      newValue = (
        <Pane is="span" fontWeight={500}>
          {championUser && championUser.formattedName}
        </Pane>
      );
    }
    if (propMeta.key === "_health_score") {
      const timeSortedScores = [...(account.health_scores || [])]
        .sort((a, b) =>
          dateFns.compareDesc(
            dateFns.parseISO(a.timestamp),
            dateFns.parseISO(b.timestamp)
          )
        )
        .reduce((sum, curr, i, source) => {
          if (
            i === source.length - 1 ||
            !dateFns.isSameDay(
              dateFns.parseISO(curr.timestamp),
              dateFns.parseISO(source[i + 1].timestamp)
            )
          ) {
            return [...sum, curr];
          }

          return sum;
        }, []);

      const mostRecentHealthScoreMeta =
        account.health_scores.length > 0
          ? account.health_scores[0]
          : {
              health_score: 0
            };

      const mostRecentHealthScore = mostRecentHealthScoreMeta.health_score;

      let healthColor = "green";
      if (mostRecentHealthScore < 80) {
        healthColor = "yellow";
      }
      if (mostRecentHealthScore < 60) {
        healthColor = "red";
      }

      newValue = (
        <Row alignItems="center" justifyContent="center">
          <PriorityScore.ComboIcon
            priority={Math.round(mostRecentHealthScore)}
          />
          <Pane
            transform="scale(0.4)"
            marginLeft={-25}
            marginRight={-45}
            marginY={-25}
          >
            <Sparkline
              ariaLabel="Health score trend"
              width={140}
              height={70}
              data={[...timeSortedScores].slice(0, 12).reverse()}
              valueAccessor={({ health_score }) => health_score}
            >
              <LineSeries showArea={false} stroke={allColors[healthColor][7]} />
            </Sparkline>
          </Pane>
        </Row>
      );
    }
    if (propMeta.key === "current_mrr") {
      newValue = Math.round(newValue / 100);
    }
    if (propMeta.unit) {
      newValue = `${propMeta.unit}${newValue}`;
    }
    if (propMeta.type === "date" && !!newValue) {
      newValue = dateFns.formatDistanceToNow(newValue);
    }
    return newValue || "-";
  };

  const timeSortedScores = [...(account.health_scores || [])].sort((a, b) =>
    dateFns.compareDesc(
      dateFns.parseISO(a.timestamp),
      dateFns.parseISO(b.timestamp)
    )
  );

  const healthMeta =
    timeSortedScores && timeSortedScores.length
      ? timeSortedScores[0]
      : {
          health_score: 0
        };

  return (
    <Panel flexBasis="30%" paddingBottom={10}>
      <Row marginBottom={12} alignItems="center">
        <Pane flexGrow={1}>
          <H1>
            <Pane is="span" fontSize={20}>
              {account._computedName}
            </Pane>
          </H1>
          <Domain>
            {userDomain === "shipbob.com" && (
              <>
                <Pane
                  is="a"
                  color="#a0a9c5"
                  target="_blank"
                  href={`https://shipbob.lightning.force.com/lightning/r/Account/${account.metadata.group_id}/view`}
                >
                  Salesforce
                </Pane>{" "}
                &bull;{" "}
                <Pane
                  is="a"
                  color="#a0a9c5"
                  target="_blank"
                  href={account.metadata.traits.LINK_TO_OUTREACH_PROSPECT_C}
                >
                  Outreach.io
                </Pane>{" "}
                &bull;{" "}
              </>
            )}
            {userDomain === "h1insights.com" && (
              <>
                <Pane
                  is="a"
                  color="#a0a9c5"
                  target="_blank"
                  href={`http://na49.lightning.force.com/lightning/r/Account/${account.metadata.group_id}/view`}
                >
                  Salesforce
                </Pane>{" "}
                &bull;{" "}
              </>
            )}
            {account._computedDomain}
          </Domain>
        </Pane>
        {!hadAvatarError && (
          <CompanyLogo
            onError={setHadAvatarError}
            src={`https://logo.clearbit.com/${account._computedDomain}`}
          />
        )}
        {hadAvatarError && (
          <AvatarFallback backgroundColor={COLOR_RANGE[0 % COLOR_RANGE.length]}>
            {account.metadata.name[0]}
          </AvatarFallback>
        )}{" "}
      </Row>
      {propertyList.map((prop, i) => (
        <PropertyRow key={i}>
          <PropertyLabel flexGrow={1}>{prop.label}</PropertyLabel>
          <PropertyValue>
            <ComputedPropertyValue
              propMeta={prop}
              account={{
                current_champion: currentChampion,
                ...account.metadata,
                health_scores: account.health_scores
              }}
            />
          </PropertyValue>
        </PropertyRow>
      ))}
      {userDomain === "h1insights.com" && healthMeta.explanation && (
        <Pane marginTop={4}>
          {[...healthMeta.explanation]
            .sort((a, b) => {
              if (a.valence === "good" && b.valence !== "good") {
                return 1;
              }
              if (a.valence !== "good" && b.valence === "good") {
                return -1;
              }
              return 0;
            })
            .map((explanation, i) => (
              <Row
                color="#475872"
                marginBottom={6}
                fontSize={13}
                alignItems="center"
                key={i}
              >
                <Pane
                  borderRadius={3}
                  width={8}
                  height={8}
                  marginRight={11}
                  backgroundColor={
                    explanation.valence === "good" ? "#00bda5" : "#f2545b"
                  }
                  opacity={0.7}
                />
                <span>
                  <Pane is="span" fontWeight={500} color="#374151">
                    {FEATURE_META[userDomain][explanation.field].label}
                    {explanation.valence === "good" &&
                      FEATURE_META[userDomain][explanation.field].positiveLabel}
                    {explanation.valence === "bad" &&
                      FEATURE_META[userDomain][explanation.field].negativeLabel}
                  </Pane>
                  {explanation.value !== undefined &&
                    FEATURE_META[userDomain][explanation.field].type !==
                      "bool" &&
                    FEATURE_META[userDomain][explanation.field].static &&
                    " is "}
                  {explanation.value !== undefined &&
                    explanation.valence === "good" &&
                    !FEATURE_META[userDomain][explanation.field].static &&
                    (explanation.language || " went up to ")}
                  {explanation.value !== undefined &&
                    explanation.valence !== "good" &&
                    !FEATURE_META[userDomain][explanation.field].static &&
                    (explanation.language || " went down to ")}
                  {explanation.value
                    ? FEATURE_META[userDomain][explanation.field].prefix
                    : ""}
                  {FEATURE_META[userDomain][explanation.field].type === "int"
                    ? Math.round(
                        explanation.value ||
                          FEATURE_META[userDomain][explanation.field]
                            .defaultValue
                      )
                    : FEATURE_META[userDomain][explanation.field].type === "%"
                    ? Math.round(
                        100 *
                          (explanation.value ||
                            FEATURE_META[userDomain][explanation.field]
                              .defaultValue)
                      ) + "%"
                    : FEATURE_META[userDomain][explanation.field].type ===
                      "bool"
                    ? ""
                    : explanation.value ||
                      FEATURE_META[userDomain][explanation.field].defaultValue}
                  {explanation.value
                    ? FEATURE_META[userDomain][explanation.field].suffix
                    : ""}
                </span>
              </Row>
            ))}
          <Pane
            display="flex"
            justifyContent="flex-end"
            fontSize={SMALL_FONT_SIZE}
            color={FADED_GRAY}
            marginTop={20}
          >
            Health updated{" "}
            {dateFns.formatDistanceToNow(new Date(healthMeta.timestamp))} ago
          </Pane>
        </Pane>
      )}
      {/* <PropertyRow> */}
      {/*   <PropertyLabel flexGrow={1}>Users</PropertyLabel> */}
      {/* </PropertyRow> */}
      {/* <Pane fontSize={11} color="#475872"> */}
      {/*   {account.users.map((user, i) => { */}
      {/*     let name = user.traits.NAME || user.fullname || user.name; */}
      {/*     if (!name && user.first_name) { */}
      {/*       name = user.first_name + " " + user.last_name; */}
      {/*     } */}
      {/*     if (!name && user.firstname) { */}
      {/*       name = user.firstname + " " + user.lastname; */}
      {/*     } */}
      {/*     return ( */}
      {/*       <Row key={i}> */}
      {/*         <Pane>{name}</Pane> */}
      {/*       </Row> */}
      {/*     ); */}
      {/*   })} */}
      {/* </Pane> */}

      {/*         <PropertyRow> */}
      {/*           <PropertyLabel flexGrow={1}>Company Updates</PropertyLabel> */}
      {/*         </PropertyRow> */}
      {/*  */}
      {/*         <Pane fontSize={11} color="#475872"> */}
      {/*           {news.map(newsItem => ( */}
      {/*             <Row cursor="pointer"> */}
      {/*               <Pane marginLeft={11}>{newsItem.excerpt}</Pane> */}
      {/*             </Row> */}
      {/*           ))} */}
      {/*         </Pane> */}
    </Panel>
  );
};

const allShipBobStats = [
  {
    key: "average_mc_sat",
    label: "Average MC Satisfaction",
    type: "static"
  },
  {
    key: "change_vs_average_mc_sat",
    label: "MC Satisfaction",
    positiveLanguage: " last increased by ",
    negativeLanguage: " last decreased by ",
    suffix: "%"
  },
  {
    key: "average_sb_sat",
    label: "Average SB Satisfaction",
    type: "static"
  },
  {
    key: "change_vs_average_sb_sat",
    label: "SB Satisfaction",
    positiveLanguage: " last increased by ",
    negativeLanguage: " last decreased by ",
    neutralLanguage: " change is ",
    suffix: "%"
  },
  {
    key: "average_nps",
    label: "Average NPS",
    type: "static"
  },
  {
    key: "change_vs_average_nps",
    label: "NPS",
    positiveLanguage: " last increased by ",
    negativeLanguage: " last decreased by "
  },
  {
    key: "most_recent_average_cost_per_order_change",
    label: "Average cost per order change",
    positiveLanguage: " last decreased by ",
    negativeLanguage: " last increased by ",
    suffix: "%"
  },
  {
    key: "most_recent_case_order_ratio_change",
    label: "Case to order ratio change",
    positiveLanguage: " last decreased by ",
    negativeLanguage: " last increased by ",
    suffix: "%"
  }
];

const allUpflowStats = [
  {
    key: "count_active_users_last_week",
    label: "Active users last week"
  },
  {
    key: "days_since_last_key_event",
    label: "Days since last key event",
    negativeLabel: "No key event last week",
    type: "static"
  },
  {
    key: "has_c_level_activity",
    positiveLabel: "Has C-level activity",
    negativeLabel: "No C-level activity"
  },
  {
    key: "has_integration_data_source",
    positiveLabel: "Has integration data source",
    negativeLabel: "No integration data source"
  },
  {
    key: "average_nps",
    label: "NPS"
  }
];

const PriorityScorePanel2 = ({ userDomain, account }) => {
  const [hoveredBehavior, setHoveredBehavior] = useState();

  const BehaviorRow = styled(Row)`
    position: relative;
    &:hover,
    &.hover {
      background-color: rgba(0, 0, 0, 0.02);
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.02);
      border-radius: 2px;
    }
  `;

  const stats = account.stats;

  if (!stats || !account.health_scores) {
    return <></>;
  }

  let positiveStrength = 0;
  let negativeStrength = 0;

  // group_id: "0014100000BSWYAAA5"
  // num_cases: 188
  // revenue_this_quarter: 36025.53
  // revenue_last_quarter: 85174.61
  // shipments_this_quarter: 2953
  // shipments_last_quarter: 8111
  // all_average_cost_per_order_change: "[{"value":-5.0,"timestamp":1578110440000},{"value":6.0,"timestamp":1578986540000},{"value":7.0,"timestamp":1579587995000},{"value":7.0,"timestamp":1579673113000},{"value":6.0,"timestamp":1580265782000},{"value":6.0,"timestamp":1580800692000},{"value":6.0,"timestamp":1580787758000}]"
  // most_recent_average_cost_per_order_change: 6
  // all_average_revenue_change: "[{"value":-17.0,"timestamp":1578110440000},{"value":14.0,"timestamp":1578986540000},{"value":30.0,"timestamp":1579587995000},{"value":28.0,"timestamp":1579673113000},{"value":18.0,"timestamp":1580265782000},{"value":15.0,"timestamp":1580800692000},{"value":14.0,"timestamp":1580787758000}]"
  // most_recent_average_revenue_change: 14
  // all_case_order_ratio_change: "[{"value":0.0,"timestamp":1578110440000},{"value":-5200.0,"timestamp":1578986540000},{"value":-6400.0,"timestamp":1579587995000},{"value":-6600.0,"timestamp":1579673113000},{"value":-6400.0,"timestamp":1580265782000},{"value":-6800.0,"timestamp":1580800692000},{"value":-6800.0,"timestamp":1580787758000}]"
  // most_recent_case_order_ratio_change: -6800
  // all_sb_sats: "[{"value":5.0,"timestamp":1571194067000},{"value":5.0,"timestamp":1571364133000},{"value":5.0,"timestamp":1571694662000},{"value":5.0,"timestamp":1571956801000},{"value":5.0,"timestamp":1572299286000},{"value":5.0,"timestamp":1572915286000},{"value":5.0,"timestamp":1573616494000},{"value":5.0,"timestamp":1574315634000},{"value":5.0,"timestamp":1575944094000},{"value":5.0,"timestamp":1576625617000},{"value":5.0,"timestamp":1576798622000},{"value":5.0,"timestamp":1576896222000},{"value":5.0,"timestamp":1578534180000},{"value":5.0,"timestamp":1579043255000},{"value":5.0,"timestamp":1579221628000},{"value":5.0,"timestamp":1579651065000},{"value":5.0,"timestamp":1580973394000}]"
  // most_recent_sb_sat: 5
  // all_mc_sats: "[{"value":5.0,"timestamp":1559948013000},{"value":5.0,"timestamp":1560213526000},{"value":5.0,"timestamp":1560817720000},{"value":5.0,"timestamp":1561152278000},{"value":5.0,"timestamp":1561486038000},{"value":5.0,"timestamp":1561684533000},{"value":5.0,"timestamp":1561758613000},{"value":5.0,"timestamp":1562968530000},{"value":5.0,"timestamp":1564441161000},{"value":5.0,"timestamp":1564775710000},{"value":5.0,"timestamp":1565729697000},{"value":5.0,"timestamp":1566274474000},{"value":5.0,"timestamp":1566617401000},{"value":5.0,"timestamp":1566957811000},{"value":5.0,"timestamp":1567225490000},{"value":5.0,"timestamp":1567579610000},{"value":5.0,"timestamp":1568091290000},{"value":5.0,"timestamp":1568426279000},{"value":5.0,"timestamp":1568694120000},{"value":5.0,"timestamp":1569018216000},{"value":5.0,"timestamp":1569278304000}]"
  // most_recent_mc_sat: 5
  // most_recent_nps: null
  // computed_at: 1581028386641

  let allStats = [];
  if (userDomain === "shipbob.com") {
    allStats = allShipBobStats;
  } else if (userDomain === "upflow.io") {
    allStats = allUpflowStats;
  }

  const importantStats = Object.keys(stats).reduce((sum, currKey) => {
    if (stats[currKey] === null) {
      return sum;
    }
    const c = {
      ...allStats.find(s => s.key === currKey.toLowerCase()),
      value: stats[currKey]
    };

    const cp = [
      ...sum,
      {
        ...c,
        polarity: "positive"
      }
    ];
    const cn = [
      ...sum,
      {
        ...c,
        polarity: "negative"
      }
    ];

    if (userDomain === "shipbob.com") {
      if (currKey.toLowerCase() === "average_mc_sat") {
        if (stats[currKey] < 4.6) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 4.8) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "average_sb_sat") {
        if (stats[currKey] < 4.6) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 4.8) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "change_vs_average_mc_sat") {
        if (stats[currKey] < 0) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] > 0) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "change_vs_average_sb_sat") {
        if (stats[currKey] < 0) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] > 0) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "average_nps") {
        if (stats[currKey] < 7) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              ...c,
              polarity: "negative",
              value: Math.round(c.value * 10) / 10
            }
          ];
        } else if (stats[currKey] >= 8) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              ...c,
              polarity: "positive",
              value: Math.round(c.value * 10) / 10
            }
          ];
        }
      } else if (currKey.toLowerCase() === "change_vs_average_nps") {
        if (stats[currKey] < 0) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 0) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (
        currKey.toLowerCase() === "most_recent_average_cost_per_order_change"
      ) {
        if (stats[currKey] > 20) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] < -20) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (
        currKey.toLowerCase() === "most_recent_case_order_ratio_change"
      ) {
        if (stats[currKey] >= 20) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] < -20) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      }
    } else if (userDomain === "upflow.io") {
      if (currKey.toLowerCase() === "average_nps") {
        if (stats[currKey] < 7) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 8) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "has_integration_data_source") {
        if (!stats[currKey]) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "has_c_level_activity") {
        if (!stats[currKey]) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "days_since_last_key_event") {
        if (
          stats[currKey] === null ||
          stats[currKey] === undefined ||
          stats[currKey] > 7
        ) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "count_active_users_last_week") {
        if (stats[currKey] >= 1) {
          positiveStrength = positiveStrength + 1;
          return cp;
        } else {
          negativeStrength = negativeStrength + 1;
          return cn;
        }
      }
    }

    return sum;
  }, []);

  const totalStrength = positiveStrength + negativeStrength;

  const topNegativeFeatures = importantStats.filter(
    stat => stat.polarity === "negative"
  );
  const topPositiveFeatures = importantStats.filter(
    stat => stat.polarity === "positive"
  );
  const importantStatKeys = importantStats.map(k => k.key);
  const neutralFeatures = [
    ...allStats
      .filter(stat => !importantStatKeys.includes(stat.key))
      .map(stat => ({
        ...stat,
        value: stats[stat.key.toUpperCase()]
      }))
  ].sort((a, b) => {
    if (a.value !== undefined && b.value === undefined) {
      return -1;
    } else if (a.value === undefined && b.value !== undefined) {
      return 1;
    }
    return 0;
  });

  const mostRecentHealthScoreMeta =
    account.health_scores.length > 0
      ? account.health_scores[0]
      : {
          health_score: 0
        };

  const mostRecentHealthScore = mostRecentHealthScoreMeta.health_score;

  let healthColor = "green";
  if (mostRecentHealthScore < 80) {
    healthColor = "yellow";
  }
  if (mostRecentHealthScore < 60) {
    healthColor = "red";
  }

  return (
    <Panel flexBasis="30%">
      <Center>
        <H1>Health Score</H1>
        <Caption>Let's Break That Down</Caption>
      </Center>
      <div>
        <Pane marginTop={4} minWidth={330}>
          <Pane paddingLeft={36} paddingRight={36} paddingBottom={18}>
            <Pane marginTop={12} marginBottom={0}>
              <Center>
                <Row
                  alignItems="center"
                  justifyContent="center"
                  transform="scale(1.2)"
                >
                  <PriorityScore.ComboIcon
                    priority={Math.round(mostRecentHealthScore)}
                  />
                  <Pane
                    transform="scale(0.4)"
                    marginLeft={-25}
                    marginRight={-45}
                  >
                    <Sparkline
                      ariaLabel="All activity"
                      width={140}
                      // margin={{ top: 24, bottom: 24 }}
                      height={70}
                      data={[...account.health_scores].slice(0, 10).reverse()}
                      valueAccessor={datum => datum.health_score}
                    >
                      <LineSeries
                        showArea={false}
                        stroke={allColors[healthColor][7]}
                      />
                    </Sparkline>
                  </Pane>
                </Row>
              </Center>
            </Pane>
          </Pane>
        </Pane>
      </div>
      <Pane paddingX={21} paddingBottom={18}>
        {!!topPositiveFeatures.length && (
          <>
            <StyledHeader>Good</StyledHeader>

            {topPositiveFeatures.map((obj, i) => (
              <Pane marginBottom={17} key={i}>
                <BehaviorRow
                  color="#475872"
                  fontSize={14}
                  alignItems="center"
                  marginBottom={4}
                  cursor="pointer"
                  className={hoveredBehavior === i ? "hover" : ""}
                  onMouseEnter={() => setHoveredBehavior(i)}
                  onMouseLeave={() => setHoveredBehavior(null)}
                >
                  <ListItemBubble backgroundColor="#00bda5" />
                  <span>
                    {obj.positiveLabel && (
                      <Pane is="span" fontWeight={500}>
                        {obj.positiveLabel}
                      </Pane>
                    )}
                    {obj.label && !obj.positiveLabel && (
                      <>
                        <Pane is="span" fontWeight={500}>
                          {obj.label}
                        </Pane>
                        {obj.value !== undefined &&
                          obj.type === "static" &&
                          (obj.neutralLanguage || " is ")}
                        {obj.value !== undefined &&
                          obj.polarity === "positive" &&
                          obj.type !== "static" &&
                          (obj.positiveLanguage || " went up to ")}
                        {obj.value !== undefined &&
                          obj.polarity === "negative" &&
                          obj.type !== "static" &&
                          (obj.negativeLanguage || " went down to ")}
                        {obj.value ? obj.prefix : ""}
                        {obj.value}
                        {obj.value ? obj.suffix : ""}
                      </>
                    )}
                  </span>
                </BehaviorRow>
              </Pane>
            ))}
          </>
        )}

        {!!topNegativeFeatures.length && (
          <StyledHeader marginTop={!!topPositiveFeatures.length ? 32 : 0}>
            Bad
          </StyledHeader>
        )}

        {topNegativeFeatures.map((obj, i) => (
          <Pane marginBottom={17} key={i}>
            <BehaviorRow
              color="#475872"
              fontSize={14}
              alignItems="center"
              marginBottom={4}
              cursor="pointer"
              className={
                hoveredBehavior === i + topPositiveFeatures.length
                  ? "hover"
                  : ""
              }
              onMouseEnter={() =>
                setHoveredBehavior(i + topPositiveFeatures.length)
              }
              onMouseLeave={() => setHoveredBehavior(null)}
            >
              <ListItemBubble backgroundColor="#f2545b" />
              <span>
                {obj.label && !obj.negativeLabel && (
                  <>
                    <Pane is="span" fontWeight={500}>
                      {obj.label}
                    </Pane>
                    {obj.value !== undefined && obj.type === "static" && " is "}
                    {obj.value !== undefined &&
                      obj.polarity === "positive" &&
                      obj.type !== "static" &&
                      (obj.positiveLanguage || " went up to ")}
                    {obj.value !== undefined &&
                      obj.polarity === "negative" &&
                      obj.type !== "static" &&
                      (obj.negativeLanguage || " went down to ")}
                    {obj.value ? obj.prefix : ""}
                    {obj.value}
                    {obj.value ? obj.suffix : ""}
                  </>
                )}
                {obj.negativeLabel && (
                  <Pane is="span" fontWeight={500}>
                    {obj.negativeLabel}
                  </Pane>
                )}
              </span>
            </BehaviorRow>
          </Pane>
        ))}

        {!!neutralFeatures.length && (
          <StyledHeader
            marginTop={
              !!topPositiveFeatures.length || !!topNegativeFeatures.length
                ? 32
                : 0
            }
          >
            Neutral
          </StyledHeader>
        )}

        {neutralFeatures.map((obj, i) => (
          <Pane marginBottom={17} key={i}>
            <BehaviorRow
              color="#475872"
              fontSize={14}
              alignItems="center"
              marginBottom={4}
              cursor="pointer"
              opacity={obj.value === undefined ? 0.6 : 1}
            >
              <ListItemBubble backgroundColor="#ccc" />
              <span>
                <Pane is="span" fontWeight={500}>
                  {obj.label}
                </Pane>{" "}
                {obj.neutralLanguage || " is "}
                {obj.value === undefined
                  ? "unknown"
                  : `normal (${obj.value}${obj.suffix || ""})`}
              </span>
            </BehaviorRow>
          </Pane>
        ))}
      </Pane>
      <Pane
        display="flex"
        justifyContent="flex-end"
        fontSize={SMALL_FONT_SIZE}
        color={FADED_GRAY}
      >
        Last updated{" "}
        {dateFns.formatDistanceToNow(
          new Date(mostRecentHealthScoreMeta.timestamp)
        )}{" "}
        ago
      </Pane>
    </Panel>
  );
};

const PriorityScorePanel3 = ({ userDomain, account }) => {
  const [hoveredBehavior, setHoveredBehavior] = useState();

  const BehaviorRow = styled(Row)`
    position: relative;
    &:hover,
    &.hover {
      background-color: rgba(0, 0, 0, 0.02);
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.02);
      border-radius: 2px;
    }
  `;

  const stats = account.stats;
  let positiveStrength = 0;
  let negativeStrength = 0;

  // group_id: "0014100000BSWYAAA5"
  // num_cases: 188
  // revenue_this_quarter: 36025.53
  // revenue_last_quarter: 85174.61
  // shipments_this_quarter: 2953
  // shipments_last_quarter: 8111
  // all_average_cost_per_order_change: "[{"value":-5.0,"timestamp":1578110440000},{"value":6.0,"timestamp":1578986540000},{"value":7.0,"timestamp":1579587995000},{"value":7.0,"timestamp":1579673113000},{"value":6.0,"timestamp":1580265782000},{"value":6.0,"timestamp":1580800692000},{"value":6.0,"timestamp":1580787758000}]"
  // most_recent_average_cost_per_order_change: 6
  // all_average_revenue_change: "[{"value":-17.0,"timestamp":1578110440000},{"value":14.0,"timestamp":1578986540000},{"value":30.0,"timestamp":1579587995000},{"value":28.0,"timestamp":1579673113000},{"value":18.0,"timestamp":1580265782000},{"value":15.0,"timestamp":1580800692000},{"value":14.0,"timestamp":1580787758000}]"
  // most_recent_average_revenue_change: 14
  // all_case_order_ratio_change: "[{"value":0.0,"timestamp":1578110440000},{"value":-5200.0,"timestamp":1578986540000},{"value":-6400.0,"timestamp":1579587995000},{"value":-6600.0,"timestamp":1579673113000},{"value":-6400.0,"timestamp":1580265782000},{"value":-6800.0,"timestamp":1580800692000},{"value":-6800.0,"timestamp":1580787758000}]"
  // most_recent_case_order_ratio_change: -6800
  // all_sb_sats: "[{"value":5.0,"timestamp":1571194067000},{"value":5.0,"timestamp":1571364133000},{"value":5.0,"timestamp":1571694662000},{"value":5.0,"timestamp":1571956801000},{"value":5.0,"timestamp":1572299286000},{"value":5.0,"timestamp":1572915286000},{"value":5.0,"timestamp":1573616494000},{"value":5.0,"timestamp":1574315634000},{"value":5.0,"timestamp":1575944094000},{"value":5.0,"timestamp":1576625617000},{"value":5.0,"timestamp":1576798622000},{"value":5.0,"timestamp":1576896222000},{"value":5.0,"timestamp":1578534180000},{"value":5.0,"timestamp":1579043255000},{"value":5.0,"timestamp":1579221628000},{"value":5.0,"timestamp":1579651065000},{"value":5.0,"timestamp":1580973394000}]"
  // most_recent_sb_sat: 5
  // all_mc_sats: "[{"value":5.0,"timestamp":1559948013000},{"value":5.0,"timestamp":1560213526000},{"value":5.0,"timestamp":1560817720000},{"value":5.0,"timestamp":1561152278000},{"value":5.0,"timestamp":1561486038000},{"value":5.0,"timestamp":1561684533000},{"value":5.0,"timestamp":1561758613000},{"value":5.0,"timestamp":1562968530000},{"value":5.0,"timestamp":1564441161000},{"value":5.0,"timestamp":1564775710000},{"value":5.0,"timestamp":1565729697000},{"value":5.0,"timestamp":1566274474000},{"value":5.0,"timestamp":1566617401000},{"value":5.0,"timestamp":1566957811000},{"value":5.0,"timestamp":1567225490000},{"value":5.0,"timestamp":1567579610000},{"value":5.0,"timestamp":1568091290000},{"value":5.0,"timestamp":1568426279000},{"value":5.0,"timestamp":1568694120000},{"value":5.0,"timestamp":1569018216000},{"value":5.0,"timestamp":1569278304000}]"
  // most_recent_mc_sat: 5
  // most_recent_nps: null
  // computed_at: 1581028386641

  let allStats = [];
  if (userDomain === "shipbob.com") {
    allStats = allShipBobStats;
  } else if (userDomain === "upflow.io") {
    allStats = allUpflowStats;
  }

  if (!stats) {
    return <></>;
  }

  const importantStats = Object.keys(stats).reduce((sum, currKey) => {
    if (stats[currKey] === null) {
      return sum;
    }
    const c = {
      ...allStats.find(s => s.key === currKey.toLowerCase()),
      value: stats[currKey]
    };

    const cp = [
      ...sum,
      {
        ...c,
        polarity: "positive"
      }
    ];
    const cn = [
      ...sum,
      {
        ...c,
        polarity: "negative"
      }
    ];

    if (userDomain === "shipbob.com") {
      if (currKey.toLowerCase() === "average_mc_sat") {
        if (stats[currKey] < 4.6) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 4.8) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "average_sb_sat") {
        if (stats[currKey] < 4.6) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 4.8) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "change_vs_average_mc_sat") {
        if (stats[currKey] < 0) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] > 0) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "change_vs_average_sb_sat") {
        if (stats[currKey] < 0) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] > 0) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "average_nps") {
        if (stats[currKey] < 7) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              ...c,
              polarity: "negative",
              value: Math.round(c.value * 10) / 10
            }
          ];
        } else if (stats[currKey] >= 8) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              ...c,
              polarity: "positive",
              value: Math.round(c.value * 10) / 10
            }
          ];
        }
      } else if (currKey.toLowerCase() === "change_vs_average_nps") {
        if (stats[currKey] < 0) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 0) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (
        currKey.toLowerCase() === "most_recent_average_cost_per_order_change"
      ) {
        if (stats[currKey] > 20) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] < -20) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (
        currKey.toLowerCase() === "most_recent_case_order_ratio_change"
      ) {
        if (stats[currKey] >= 20) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] < -20) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      }
    } else if (userDomain === "upflow.io") {
      if (currKey.toLowerCase() === "average_nps") {
        if (stats[currKey] < 7) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else if (stats[currKey] >= 8) {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "has_integration_data_source") {
        if (!stats[currKey]) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "has_c_level_activity") {
        if (!stats[currKey]) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "days_since_last_key_event") {
        if (
          stats[currKey] === null ||
          stats[currKey] === undefined ||
          stats[currKey] > 7
        ) {
          negativeStrength = negativeStrength + 1;
          return cn;
        } else {
          positiveStrength = positiveStrength + 1;
          return cp;
        }
      } else if (currKey.toLowerCase() === "count_active_users_last_week") {
        if (stats[currKey] >= 1) {
          positiveStrength = positiveStrength + 1;
          return cp;
        } else {
          negativeStrength = negativeStrength + 1;
          return cn;
        }
      }
    }

    return sum;
  }, []);

  const totalStrength = positiveStrength + negativeStrength;

  const topNegativeFeatures = importantStats.filter(
    stat => stat.polarity === "negative"
  );
  const topPositiveFeatures = importantStats.filter(
    stat => stat.polarity === "positive"
  );
  const importantStatKeys = importantStats.map(k => k.key);
  const neutralFeatures = [
    ...allStats
      .filter(stat => !importantStatKeys.includes(stat.key))
      .map(stat => ({
        ...stat,
        value: stats[stat.key.toUpperCase()]
      }))
  ].sort((a, b) => {
    if (a.value !== undefined && b.value === undefined) {
      return -1;
    } else if (a.value === undefined && b.value !== undefined) {
      return 1;
    }
    return 0;
  });

  const mostRecentHealthScoreMeta =
    account.health_scores.length > 0
      ? account.health_scores[0]
      : {
          TOTAL_NORMALIZED_SCORE: 0
        };

  let mostRecentHealthScore = mostRecentHealthScoreMeta.TOTAL_NORMALIZED_SCORE;

  if (mostRecentHealthScore) {
    mostRecentHealthScore = 100 * mostRecentHealthScore;
  }

  let healthColor = "green";
  if (mostRecentHealthScore < 80) {
    healthColor = "yellow";
  }
  if (mostRecentHealthScore < 60) {
    healthColor = "red";
  }

  return (
    <Panel flexBasis="30%">
      <Center>
        <H1>Health Score</H1>
        <Caption>Let's Break That Down</Caption>
      </Center>
      <div>
        <Pane marginTop={4} minWidth={330}>
          <Pane paddingLeft={36} paddingRight={36} paddingBottom={18}>
            <Pane marginTop={12} marginBottom={0}>
              <Center>
                <Row
                  alignItems="center"
                  justifyContent="center"
                  transform="scale(1.2)"
                >
                  <PriorityScore.ComboIcon
                    priority={Math.round(mostRecentHealthScore)}
                  />
                  <Pane
                    transform="scale(0.4)"
                    marginLeft={-25}
                    marginRight={-45}
                  >
                    <Sparkline
                      ariaLabel="All activity"
                      width={140}
                      // margin={{ top: 24, bottom: 24 }}
                      height={70}
                      data={[...account.health_scores].slice(0, 10).reverse()}
                      valueAccessor={datum => datum.TOTAL_NORMALIZED_SCORE}
                    >
                      <LineSeries
                        showArea={false}
                        stroke={allColors[healthColor][7]}
                      />
                    </Sparkline>
                  </Pane>
                </Row>
              </Center>
            </Pane>
          </Pane>
        </Pane>
      </div>
      <Pane paddingX={21} paddingBottom={18}>
        {!!topPositiveFeatures.length && (
          <>
            <StyledHeader>Good</StyledHeader>

            {topPositiveFeatures.map((obj, i) => (
              <Pane marginBottom={17} key={i}>
                <BehaviorRow
                  color="#475872"
                  fontSize={14}
                  alignItems="center"
                  marginBottom={4}
                  cursor="pointer"
                  className={hoveredBehavior === i ? "hover" : ""}
                  onMouseEnter={() => setHoveredBehavior(i)}
                  onMouseLeave={() => setHoveredBehavior(null)}
                >
                  <ListItemBubble backgroundColor="#00bda5" />
                  <span>
                    {obj.label && (
                      <>
                        <Pane is="span" fontWeight={500}>
                          {obj.label}
                        </Pane>
                        {obj.value !== undefined &&
                          obj.type === "static" &&
                          " is "}
                        {obj.value !== undefined &&
                          obj.polarity === "positive" &&
                          obj.type !== "static" &&
                          (obj.positiveLanguage || " went up to ")}
                        {obj.value !== undefined &&
                          obj.polarity === "negative" &&
                          obj.type !== "static" &&
                          (obj.negativeLanguage || " went down to ")}
                        {obj.value ? obj.prefix : ""}
                        {obj.value}
                        {obj.value ? obj.suffix : ""}
                      </>
                    )}
                    {obj.positiveLabel && (
                      <Pane is="span" fontWeight={500}>
                        {obj.positiveLabel}
                      </Pane>
                    )}
                  </span>
                </BehaviorRow>
              </Pane>
            ))}
          </>
        )}

        {!!topNegativeFeatures.length && (
          <StyledHeader marginTop={!!topPositiveFeatures.length ? 32 : 0}>
            Bad
          </StyledHeader>
        )}

        {topNegativeFeatures.map((obj, i) => (
          <Pane marginBottom={17} key={i}>
            <BehaviorRow
              color="#475872"
              fontSize={14}
              alignItems="center"
              marginBottom={4}
              cursor="pointer"
              className={
                hoveredBehavior === i + topPositiveFeatures.length
                  ? "hover"
                  : ""
              }
              onMouseEnter={() =>
                setHoveredBehavior(i + topPositiveFeatures.length)
              }
              onMouseLeave={() => setHoveredBehavior(null)}
            >
              <ListItemBubble backgroundColor="#f2545b" />
              <span>
                {obj.label && (
                  <>
                    <Pane is="span" fontWeight={500}>
                      {obj.label}
                    </Pane>
                    {obj.value !== undefined && obj.type === "static" && " is "}
                    {obj.value !== undefined &&
                      obj.polarity === "positive" &&
                      obj.type !== "static" &&
                      (obj.positiveLanguage || " went up to ")}
                    {obj.value !== undefined &&
                      obj.polarity === "negative" &&
                      obj.type !== "static" &&
                      (obj.negativeLanguage || " went down to ")}
                    {obj.value ? obj.prefix : ""}
                    {obj.value}
                    {obj.value ? obj.suffix : ""}
                  </>
                )}
                {obj.negativeLabel && (
                  <Pane is="span" fontWeight={500}>
                    {obj.negativeLabel}
                  </Pane>
                )}
              </span>
            </BehaviorRow>
          </Pane>
        ))}

        {!!neutralFeatures.length && (
          <StyledHeader
            marginTop={
              !!topPositiveFeatures.length || !!topNegativeFeatures.length
                ? 32
                : 0
            }
          >
            Neutral
          </StyledHeader>
        )}

        {neutralFeatures.map((obj, i) => (
          <Pane marginBottom={17} key={i}>
            <BehaviorRow
              color="#475872"
              fontSize={14}
              alignItems="center"
              marginBottom={4}
              cursor="pointer"
              opacity={obj.value === undefined ? 0.6 : 1}
            >
              <ListItemBubble backgroundColor="#ccc" />
              <span>
                <Pane is="span" fontWeight={500}>
                  {obj.label}
                </Pane>
                {obj.neutralLanguage || " is "}
                {obj.value === undefined
                  ? "unknown"
                  : `normal (${obj.value}${obj.suffix || ""})`}
              </span>
            </BehaviorRow>
          </Pane>
        ))}
      </Pane>
      <Pane
        display="flex"
        justifyContent="flex-end"
        fontSize={SMALL_FONT_SIZE}
        color={FADED_GRAY}
      >
        Last updated{" "}
        {dateFns.formatDistanceToNow(
          new Date(mostRecentHealthScoreMeta.UPDATED_AT)
        )}{" "}
        ago
      </Pane>
    </Panel>
  );
};

// const PriorityScorePanel = ({ account, behaviors }) => {
//   const [hoveredBehavior, setHoveredBehavior] = useState();
//
//   const FeatureName = styled.span`
//     border-bottom: 1px solid hsla(0, 0%, 90%, 1);
//     font-weight: 600;
//   `;
//
//   const computedBehaviorName = obj => {
//     let pretext = "",
//       text,
//       posttext = "",
//       anyPreamble = true;
//
//     if (obj.time.period === "last30day") {
//       pretext += "Recently, ";
//     } else if (obj.time.period === "last90day") {
//       pretext += "In the last 3 months, ";
//     } else if (
//       obj.time.period === "postsignup" ||
//       obj.time.period === "first30day"
//     ) {
//       pretext += "During onboarding, ";
//     } else {
//       anyPreamble = false;
//     }
//
//     const b = behaviors.find(b => b.name === obj.name.original);
//     if (b.data_type === "integer" || b.data_type === "float") {
//       if (anyPreamble) {
//         pretext += "the number of ";
//       } else {
//         pretext += "The number of ";
//       }
//     }
//     text = <FeatureName>{b.readable_base_name}</FeatureName>;
//
//     if (!!obj.value && obj.value.lower !== null) {
//       const v = b.bins.indexOf(obj.value.lower);
//       if (v === b.bins.length / 2) {
//         posttext += " is average.";
//       } else if (v > b.bins.length / 2) {
//         posttext += " has been higher than most customers.";
//       } else {
//         posttext += " has been lower than most customers.";
//       }
//     } else {
//       posttext += " has been whack.";
//     }
//
//     // {obj.feature_value !== null && obj.feature_value.real !== null && (
//     //   <span>
//     //     {" "}
//     //     (<span style={{ fontWeight: 700 }}>{obj.feature_value.real}</span>)
//     //   </span>
//     // )}
//
//     return (
//       <span>
//         {pretext}
//         {text}
//         {posttext}
//       </span>
//     );
//   };
//
//   // const topFeatures = account.computed.health.explanation.features
//   //   .filter(feat => feat.name.base !== "mrr")
//   //   .slice(0, 4)
//   //   .map(feat => feat);
//   // const topPositiveFeatures = topFeatures.filter(
//   //   feat => feat.contribution.normalized >= 0
//   // );
//   // const topNegativeFeatures = topFeatures.filter(
//   //   feat => feat.contribution.normalized < 0
//   // );
//
//   const topFeatures = account.computed.health.explanation.features
//     .filter(
//       feat => feat.name.base !== "mrr" && feat.contribution.normalized < 0
//     )
//     .slice(0, 4)
//     .map(feat => feat);
//   const topPositiveFeatures = [];
//   const topNegativeFeatures = topFeatures;
//
//   const totalContribution = topFeatures.reduce(
//     (sum, curr) => sum + Math.abs(curr.contribution.normalized),
//     0
//   );
//   console.log("topFeatures", topFeatures);
//   console.log(totalContribution);
//
//   const BehaviorRow = styled(Row)`
//     position: relative;
//     &:hover,
//     &.hover {
//       background-color: rgba(0, 0, 0, 0.02);
//       box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.02);
//       border-radius: 2px;
//     }
//   `;
//
//   const FeedbackIconButton = styled(Icon)`
//     opacity: 0.5;
//     transition: opacity 0.1s ease;
//     &:hover {
//       opacity: 1;
//     }
//   `;
//
//   const FeedbackPanel = ({ accountId, behavior }) => (
//     <Pane
//       position="absolute"
//       bottom={-3}
//       right={0}
//       paddingLeft={6}
//       backgroundColor="#FAFAFA"
//     >
//       <FeedbackIconButton
//         icon="thumbs-up"
//         size={11}
//         color="muted"
//         marginRight={8}
//         onClick={() => {
//           window.analytics.track("Clicked thumbs up", {
//             accountId,
//             ...behavior
//           });
//         }}
//       />
//       <FeedbackIconButton
//         icon="thumbs-down"
//         size={11}
//         color="muted"
//         marginRight={8}
//         onClick={() => {
//           window.analytics.track("Clicked thumbs down", {
//             accountId,
//             ...behavior
//           });
//         }}
//       />
//       <FeedbackIconButton
//         icon="help"
//         size={11}
//         color="muted"
//         opacity={0.6}
//         onClick={() => {
//           window.analytics.track("Clicked feedback", {
//             accountId,
//             ...behavior
//           });
//         }}
//       />
//     </Pane>
//   );
//
//   return (
//     <Panel flexBasis="30%">
//       <Center>
//         <H1>Health Score</H1>
//         <Caption>Let's Break That Down</Caption>
//       </Center>
//       <div>
//         <Pane marginTop={4} minWidth={330}>
//           <Pane paddingLeft={36} paddingRight={36} paddingBottom={18}>
//             <Pane marginTop={22} marginBottom={22}>
//               <Center>
//                 {/* <PriorityScore.ComboIcon */}
//                 {/*   priority={Math.round(account.computed.health.score)} */}
//                 {/* /> */}
//               </Center>
//             </Pane>
//
//             <Row marginBottom={18}>
//               {topPositiveFeatures.map((obj, i) => (
//                 <Block
//                   key={i}
//                   index={i}
//                   className={hoveredBehavior === i ? "hover" : ""}
//                   size={
//                     (100 * Math.abs(obj.contribution.normalized)) /
//                     totalContribution
//                   }
//                   onMouseEnter={() => setHoveredBehavior(i)}
//                   onMouseLeave={() => setHoveredBehavior(null)}
//                 />
//               ))}
//               {topNegativeFeatures.map((obj, i) => (
//                 <Block
//                   key={i}
//                   index={i + topPositiveFeatures.length}
//                   onMouseEnter={() =>
//                     setHoveredBehavior(i + topPositiveFeatures.length)
//                   }
//                   onMouseLeave={() => setHoveredBehavior(null)}
//                   className={
//                     hoveredBehavior === i + topPositiveFeatures.length
//                       ? "hover"
//                       : ""
//                   }
//                   size={
//                     (100 * Math.abs(obj.contribution.normalized)) /
//                     totalContribution
//                   }
//                 />
//               ))}
//             </Row>
//           </Pane>
//         </Pane>
//       </div>
//
//       {!!topPositiveFeatures.length && (
//         <>
//           <StyledHeader>The Good</StyledHeader>
//
//           {topPositiveFeatures.map((behavior, i) => (
//             <Pane marginBottom={17} key={i}>
//               <BehaviorRow
//                 color="#475872"
//                 fontSize={14}
//                 alignItems="center"
//                 marginBottom={4}
//                 cursor="pointer"
//                 className={hoveredBehavior === i ? "hover" : ""}
//                 onMouseEnter={() => setHoveredBehavior(i)}
//                 onMouseLeave={() => setHoveredBehavior(null)}
//               >
//                 <ListItemBubble backgroundColor={COLOR_RANGE[i]} />
//                 <span>{computedBehaviorName(behavior)}</span>
//                 {hoveredBehavior === i && (
//                   <FeedbackPanel
//                     accountId={account.metadata.account_id}
//                     behavior={behavior}
//                   />
//                 )}
//               </BehaviorRow>
//             </Pane>
//           ))}
//         </>
//       )}
//
//       {!!topNegativeFeatures.length && (
//         <StyledHeader marginTop={!!topPositiveFeatures.length ? 8 : 0}>
//           The Bad
//         </StyledHeader>
//       )}
//
//       {topNegativeFeatures.map((behavior, i) => (
//         <Pane marginBottom={17} key={i}>
//           <BehaviorRow
//             color="#475872"
//             fontSize={14}
//             alignItems="center"
//             marginBottom={4}
//             cursor="pointer"
//             className={
//               hoveredBehavior === i + topPositiveFeatures.length ? "hover" : ""
//             }
//             onMouseEnter={() =>
//               setHoveredBehavior(i + topPositiveFeatures.length)
//             }
//             onMouseLeave={() => setHoveredBehavior(null)}
//           >
//             <ListItemBubble
//               backgroundColor={COLOR_RANGE[i + topPositiveFeatures.length]}
//             />
//             <span>{computedBehaviorName(behavior)}</span>
//             {hoveredBehavior === i + topPositiveFeatures.length && (
//               <FeedbackPanel
//                 accountId={account.metadata.account_id}
//                 behavior={behavior}
//               />
//             )}
//           </BehaviorRow>
//         </Pane>
//       ))}
//     </Panel>
//   );
// };

const RegimeMap = ({ regimes }) => {
  const startDate = dateFns.parseISO(regimes[0].left);
  const endDate = dateFns.parseISO(regimes[regimes.length - 1].right);

  const days = dateFns.differenceInCalendarDays(endDate, startDate);
  console.log("totalDays", days);

  return regimes.map((regime, i) => {
    const regimeDays = dateFns.differenceInCalendarDays(
      dateFns.parseISO(regime.right),
      dateFns.parseISO(regime.left)
    );
    const percentage = regimeDays / days;

    // className={classNames(selectedRegimeIndex === i && "selected")}
    // onClick={() => onClickRegime(i)}

    return (
      <RegimePane
        key={i}
        height={`calc(${percentage * 100}% - 4px)`}
        backgroundColor={`hsl(156, 43%, ${Math.floor(
          100 * (regime.rank / (regimes.length + 1))
        )}%)`}
      />
    );
  });
};

const RegimePane = styled(Pane)`
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;

  &:hover {
    z-index: 9;
    box-shadow: rgba(16, 112, 202, 0.14) 0px 0px 0px 3px,
      rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset,
      rgba(67, 90, 111, 0.14) 0px -1px 1px 0px inset;
  }

  &.selected {
    box-shadow: rgba(16, 112, 202, 0.24) 0px 0px 0px 3px,
      rgba(67, 90, 111, 0.4) 0px 0px 0px 1px inset,
      rgba(67, 90, 111, 0.24) 0px -1px 1px 0px inset;
  }
`;

const AvatarPlaceholder = styled(Pane)`
  width: 19px;
  height: 19px;
  border-radius: 41px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-top: 1px;
  cursor: default;
  flex-shrink: 0;
`;

const EventText = styled(Pane)`
  strong {
    font-weight: 600;
    color: hsl(216, 23%, 32%);
  }
  &.positive {
    color: hsla(151, 49%, 40%, 1);
  }
  &.negative {
    color: hsla(30, 73%, 46%, 1);
  }
`;

const getKeyEventLabel = keyEvent => {
  if (keyEvent === "CAMPAIGN_RECIPIENT_ACTIVITY") {
    return "Email activity (Mailchimp)";
  } else if (keyEvent === "Search" || keyEvent === "search") {
    return "Search";
  } else if (keyEvent === "search-Keyword") {
    return "Search (keyword)";
  } else if (keyEvent === "search-Name") {
    return "Search (name)";
  } else if (keyEvent === "Clear All - Search") {
    return "Search cleared";
  } else if (keyEvent === "user.search.loadingMessage.dismiss") {
    return "Search loading message dismissed";
  } else if (keyEvent === "Start New Session" || keyEvent === "signin") {
    return "Login";
  } else if (keyEvent === "provile.viewContactDetails") {
    return "Viewed contact details";
  } else if (keyEvent === "ViewProfile" || keyEvent === "profile.view") {
    return "Viewed profile";
  } else if (keyEvent === "SUBMITTED_SUPPORT_TICKET") {
    return "Submitted a support ticket";
  } else if (keyEvent === "INSTALLED_CRX") {
    return "Installed chrome extension";
  } else if (keyEvent === "CONTENT_CREATED") {
    return "Created a flow";
  } else if (keyEvent === "PUBLISHED_FLOW") {
    return "Published a flow";
  } else if (keyEvent === "SUBMITTED_A_SUPPORT_TICKET") {
    return "Submitted a support ticket";
  } else if (keyEvent === "INVITED_A_USER") {
    return "Invited a user";
  } else if (keyEvent === "sent_email") {
    return "Email sent (Salesforce)";
  } else if (keyEvent === "received_email") {
    return "Email received (Salesforce)";
  }
  return keyEvent;
};

const HighlightsPanel = ({
  users,
  highlights,
  mostActiveUserChangeEvents,
  aggregatedKeyEvents,
  keyEventTypes,
  userDomain,
  sessions,
  emails
}) => {
  const [filteredUsers, setFilteredUsers] = useState({});
  const [filteredEvents, setFilteredEvents] = useState({});
  const [timeScale, setTimeScale] = useState(
    userDomain === "appcues.com" ? TIME_SCALE.RAW : TIME_SCALE.DAILY
  );

  if (!keyEventTypes) {
    return (
      <Panel
        flexBasis="40%"
        alignSelf="stretch"
        overflow="hidden"
        minHeight={700}
        position="relative"
      >
        <Center>
          <H1>Customer Highlights</H1>
          <Caption>No events yet</Caption>
        </Center>
      </Panel>
    );
  }

  const HighlightBox = ({ highlight, index, highlights }) => {
    const TooltipContainer = ({ children }) => {
      if (highlight.metadata && !!highlight.metadata.PREVIEW) {
        return (
          <Tooltip
            appearance="card"
            content={
              <Pane margin={20}>
                <Paragraph fontSize={12}>
                  <strong>{highlight.metadata.SUBJECT}</strong>
                </Paragraph>
                <Paragraph fontSize={12} marginBottom={10}>
                  <Pane is="span" fontStyle="italic" opacity={0.6}>
                    {highlight.metadata.CREATED_BY_EMAIL}
                  </Pane>
                </Paragraph>
                <Paragraph
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize={12}
                >
                  {highlight.metadata.PREVIEW}
                  &hellip;
                </Paragraph>
              </Pane>
            }
            position={Position.LEFT}
          >
            {children}
          </Tooltip>
        );
      } else if (
        highlight.event === "sent_email" ||
        highlight.event === "received_email"
      ) {
        return (
          <Tooltip
            appearance="card"
            content={
              <Pane margin={20}>
                <Paragraph fontSize={12}>
                  <strong>{highlight.content.SUBJECT}</strong>
                </Paragraph>
                <Paragraph fontSize={12} marginBottom={10}>
                  <Pane is="span" fontStyle="italic" opacity={0.6}>
                    {highlight.from_address}
                  </Pane>
                </Paragraph>
                <Paragraph
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize={12}
                >
                  {highlight.content.TEXT_BODY}
                  &hellip;
                </Paragraph>
              </Pane>
            }
            position={Position.LEFT}
          >
            {children}
          </Tooltip>
        );
      } else if (highlight.metadata && !!highlight.metadata.FLOW_NAME) {
        return (
          <Tooltip
            appearance="card"
            content={
              <Pane margin={20}>
                <Paragraph fontSize={12}>
                  <strong>{highlight.metadata.FLOW_NAME}</strong>
                </Paragraph>
              </Pane>
            }
            position={Position.LEFT}
          >
            {children}
          </Tooltip>
        );
      } else {
        return children;
      }
    };

    if (!Array.isArray(highlight.email)) {
      highlight.email = [highlight.email];
    }

    const highlightEmail = highlight.email;

    let highlightUsers;

    if (userDomain === "appcues.com") {
      highlightUsers = users.filter(user => user.id === highlight.user_id);
    } else if (
      highlightEmail.length &&
      highlightEmail[0] &&
      highlightEmail[0].includes("@")
    ) {
      highlightUsers = users.filter(user =>
        highlightEmail.includes(user.email)
      );
    } else {
      highlightUsers = users.filter(
        user => highlight.source_user_id === user.user_id
      );

      if (highlight.properties && highlight.properties.CONTACT_ID) {
        console.log(highlight.properties.CONTACT_ID, users);
        highlightUsers = users.filter(
          user => highlight.properties.CONTACT_ID === user.user_id
        );
      }
      if (
        !highlightUsers.length &&
        highlight.source_user_id === users[0].group_id
      ) {
        highlightUsers = [
          {
            ...users[0],
            email: "-",
            traits: {
              ...users[0].traits,
              NAME: highlight._override_name || "Company"
            }
          }
        ];
      }
    }

    if (!highlightUsers.length) return <></>;

    let nameTooltipContent = highlightUsers.map(
      user =>
        user.fullname ||
        user.name ||
        (user.firstname ? user.firstname + " " + user.lastname : "") ||
        (user.traits && user.traits.NAME) ||
        user.email
    );

    // if (user.email) {
    //   nameTooltipContent = nameTooltipContent
    //     ? `${nameTooltipContent} (${user.email})`
    //     : user.email;
    // }

    const { eventLabel, eventIcon } = getDisplayPropertiesFromEvent(highlight);
    if (!eventLabel) {
      return <></>;
    }

    let isSameDayAsNextAndPrevious = false;
    if (index > 0 && index < highlights.length - 1) {
      const dateNext = dateFns.parseISO(highlights[index + 1].timestamp);
      const datePrevious = dateFns.parseISO(highlights[index - 1].timestamp);
      if (dateFns.differenceInCalendarDays(datePrevious, dateNext) === 0) {
        isSameDayAsNextAndPrevious = true;
      }
    }

    console.log("eventIcon", eventIcon);

    return (
      <StyledHighlightBox>
        <Row>
          {/* <Pane> */}
          {/*   <Tooltip content={nameTooltipContent} position={Position.LEFT}> */}
          {/*     <AvatarPlaceholder */}
          {/*       backgroundColor={ */}
          {/*         COLOR_RANGE[ */}
          {/*           account.users.findIndex( */}
          {/*             user => user.email === highlight.email */}
          {/*           ) % COLOR_RANGE.length */}
          {/*         ] */}
          {/*       } */}
          {/*     > */}
          {/*       {user.fullname || user.name || user.email || user.traits.NAME */}
          {/*         ? (user.fullname || */}
          {/*             user.name || */}
          {/*             user.email || */}
          {/*             user.traits.NAME)[0] */}
          {/*         : "-"} */}
          {/*     </AvatarPlaceholder> */}
          {/*   </Tooltip> */}
          {/* </Pane> */}
          <TooltipContainer>
            <Pane width="100%">
              <Pane display="flex" alignItems="baseline">
                <Pane
                  position="absolute"
                  left={-10}
                  top={11}
                  width={19}
                  height={19}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="white"
                  borderWidth={1}
                  borderStyle="solid"
                  borderColor="#ccc"
                  borderRadius={40}
                >
                  <Icon
                    icon={eventIcon.icon}
                    size={9}
                    color={
                      "muted" //eventIcon.hue ? `hsl(${eventIcon.hue}, 53%, 54%)` : "muted"
                    }
                    title={eventIcon.title}
                  />
                </Pane>
                <EventText className={eventIcon.netPolarity}>
                  {eventLabel}{" "}
                  {highlight.frequency > 0 && (
                    <Pane
                      is="span"
                      opacity={0.5}
                      marginLeft={5}
                      fontWeight={700}
                      fontSize={11}
                    >
                      x{highlight.frequency + 1}
                    </Pane>
                  )}
                </EventText>

                <Pane
                  is="span"
                  opacity={0.7}
                  marginLeft={5}
                  fontSize={11}
                  flexGrow={1}
                  flexShrink={0}
                  textAlign="right"
                  whiteSpace="nowrap"
                  title={dateFns.format(
                    dateFns.parseISO(highlight.timestamp),
                    "yyyy/MM/dd h:mm a"
                  )}
                >
                  {isSameDayAsNextAndPrevious
                    ? dateFns.format(
                        dateFns.parseISO(highlight.timestamp),
                        "h:mm a"
                      )
                    : dateFns.format(
                        dateFns.parseISO(highlight.timestamp),
                        "yyyy/MM/dd h:mm a"
                      )}
                </Pane>

                {highlight.metadata && highlight.metadata.PREVIEW && (
                  <Pane is="span" marginLeft={5}>
                    <Icon
                      icon="info-sign"
                      size={11}
                      color="muted"
                      opacity={0.6}
                    />
                  </Pane>
                )}
              </Pane>
              {/* <Pane>{JSON.stringify(highlight)}</Pane> */}
              <Row fontSize={11} color="#7E89A9" alignItems="center">
                {highlight.email.map((email, i) => (
                  <Row alignItems="center" key={email + i}>
                    <Pane
                      width={5}
                      height={5}
                      marginRight={4}
                      borderRadius={20}
                      backgroundColor={
                        COLOR_RANGE[
                          users.findIndex(user =>
                            userDomain == "h1insights.com"
                              ? user.email === email
                              : user.id === highlight.user_id
                          ) % COLOR_RANGE.length
                        ]
                      }
                    ></Pane>

                    {nameTooltipContent[i]}
                    {i !== highlight.email.length - 1 && (
                      <Pane is="span" marginRight={5}>
                        ,{" "}
                      </Pane>
                    )}
                    {/* {dateFns.isThisYear(dateFns.parseISO(highlight.timestamp)) */}
                    {/*   ? dateFns.format( */}
                    {/*       dateFns.parseISO(highlight.timestamp), */}
                    {/*       "MMM d" */}
                    {/*     ) */}
                    {/*   : dateFns.format( */}
                    {/*       dateFns.parseISO(highlight.timestamp), */}
                    {/*       "MMM d yyyy" */}
                    {/*     )} */}
                  </Row>
                ))}
              </Row>
            </Pane>
          </TooltipContainer>
        </Row>
      </StyledHighlightBox>
    );
  };

  const StyledHighlightBox = styled.div`
    padding: 10px 16px;
    margin-left: 25px;

    font-size: 14px;
    color: #475872;
    position: relative;
    cursor: default;

    & + &:before {
      top: 0;
    }

    &:last-child {
      padding-bottom: 50px;
    }

    &:last-child:before {
      bottom: 72px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      background-color: #ddd;
      left: -1px;
      top: 18px;
      bottom: 0;
    }

    &:after {
      /*content: "";*/
      position: absolute;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 40px;
      background-color: #475872;
      margin-left: -3px;
      top: 18px;
    }
  `;

  const RegimeMapContainer = styled(Pane)`
    position: absolute;
  `;

  const RegimeRow = styled(Row)`
    &:last-child {
      border-bottom-left-radius: 6px;
      overflow: hidden;
    }
  `;

  const RegimePane = styled(Pane)`
    align-items: center;
    display: flex;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #475872;
  `;

  //   highlights.regimes.global &&
  //     highlights.regimes.global.map((regime, i, regimes) => {
  //       const startDate = dateFns.parseISO(regimes[0].left);
  //       const endDate = dateFns.parseISO(regimes[regimes.length - 1].right);
  //
  //       const days = dateFns.differenceInCalendarDays(endDate, startDate);
  //
  //       const regimeDays = dateFns.differenceInCalendarDays(
  //         dateFns.parseISO(regime.right),
  //         dateFns.parseISO(regime.left)
  //       );
  //       const percentage = regimeDays / days;
  //
  //       const rankPercentage = Math.floor(
  //         100 * (regime.rank / (regimes.length + 1))
  //       );
  //
  //       regime._percentage = percentage;
  //       regime._rankPercentage = rankPercentage;
  //     });
  //
  //   const eventsByRegime = highlights.regimes.global
  //     ? highlights.regimes.global.map(regime =>
  //         highlights.key_events.filter(event =>
  //           dateFns.isWithinInterval(dateFns.parseISO(event.timestamp), {
  //             start: dateFns.parseISO(regime.left),
  //             end: dateFns.parseISO(regime.right)
  //           })
  //         )
  //       )
  //     : [];
  //   console.log("eventsByRegime", eventsByRegime);
  //
  //   const AGGREGATE_BY = {
  //     PUBLISHED_CRX: "FLOW_ID"
  //   };
  //
  //   const keyEventAggsByRegime = eventsByRegime.map(regime =>
  //     regime.reduce((sum, curr) => {
  //       if (!AGGREGATE_BY[curr.event]) {
  //         return sum;
  //       }
  //
  //       const eventName = curr.event;
  //       const aggEvent = sum[eventName];
  //
  //       const eventFlowName = curr.event;
  //
  //       const doesAggEventAlreadyExist = !!aggEvent;
  //       const doesFlowNameAlreadyExist = !!(aggEvent && aggEvent[eventFlowName]);
  //       const newEventFlowName = {
  //         [eventFlowName]: doesFlowNameAlreadyExist
  //           ? {
  //               frequency: aggEvent[eventFlowName].frequency + 1,
  //               ...aggEvent[eventFlowName]
  //             }
  //           : { frequency: 1, metadata: { ...curr } }
  //       };
  //
  //       const a = {
  //         [eventName]: doesAggEventAlreadyExist
  //           ? { ...aggEvent, ...newEventFlowName }
  //           : newEventFlowName
  //       };
  //
  //       return {
  //         ...sum,
  //         ...a
  //       };
  //       //
  //       //         [curr.event]: {
  //       //           ...sum[curr.event],
  //       //           curr.metadata[AGGREGATE_BY[curr.event]]: curr.metadata[AGGREGATE_BY[curr.event]] ? {
  //       //
  //       //           } : {
  //       //
  //       //           }
  //       //         }
  //       //       };
  //     }, {})
  //   );
  //
  //   console.log("keyEventAggsByRegime", keyEventAggsByRegime);

  let timeScaleText = " at a glance";
  if (timeScale === TIME_SCALE.DAILY) {
    timeScaleText = " by day";
  } else if (timeScale === TIME_SCALE.WEEKLY) {
    timeScaleText = " by week";
  } else if (timeScale === TIME_SCALE.MONTHLY) {
    timeScaleText = " by month";
  } else if (timeScale === TIME_SCALE.ALL_TIME) {
    timeScaleText = " over all-time";
  } else if (timeScale === TIME_SCALE.RAW) {
    timeScaleText = " by event";
  }

  return (
    <Panel
      flexBasis="40%"
      alignSelf="stretch"
      overflow="hidden"
      minHeight={700}
      position="relative"
    >
      <Pane position="absolute" right={56} top={16}>
        <SelectMenu
          isMultiSelect
          title="Select user"
          position="bottom-right"
          options={users.map(user => ({
            label:
              getNameForUser(user) ||
              user.email ||
              (user.traits ? user.traits.EMAIL : ""),
            value: user.email || (user.traits ? user.traits.EMAIL : "")
          }))}
          onSelect={({ value }) =>
            setFilteredUsers({
              ...filteredUsers,
              [value]: true
            })
          }
          onDeselect={({ value }) => {
            setFilteredUsers(
              Object.keys(filteredUsers)
                .filter(user => user !== value)
                .reduce(
                  (sum, curr) => ({
                    ...sum,
                    [curr]: true
                  }),
                  {}
                )
            );
          }}
          selected={Object.keys(filteredUsers)}
        >
          <IconButton icon="user" />
        </SelectMenu>
      </Pane>
      <Pane position="absolute" right={16} top={16}>
        <SelectMenu
          isMultiSelect
          title="Select events"
          position="bottom-right"
          options={Object.keys(keyEventTypes)
            .map(eventType => ({
              label: getKeyEventLabel(keyEventTypes[eventType]),
              value: eventType
            }))
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0;
            })}
          onSelect={({ value }) =>
            setFilteredEvents({
              ...filteredEvents,
              [value]: true
            })
          }
          onDeselect={({ value }) => {
            setFilteredEvents(
              Object.keys(filteredEvents)
                .filter(event => event !== value)
                .reduce(
                  (sum, curr) => ({
                    ...sum,
                    [curr]: true
                  }),
                  {}
                )
            );
          }}
          selected={Object.keys(filteredEvents)}
        >
          <IconButton icon="filter" />
        </SelectMenu>
      </Pane>
      <Center>
        <H1>Customer Highlights</H1>

        {!["appcues.com", "shipbob.com"].includes(userDomain) && (
          <SegmentedControl
            width={360}
            marginY={14}
            options={Object.values(TIME_SCALE).map(bucket => ({
              label: bucket,
              value: bucket
            }))}
            value={timeScale}
            onChange={value => setTimeScale(value)}
          />
        )}
      </Center>

      <Column height="100%" marginBottom={-23}>
        <Column marginTop={4} minWidth={330} height="100%">
          <RefreshContainer
            filteredEvents={filteredEvents}
            filteredUsers={filteredUsers}
            highlights={highlights}
            HighlightBox={HighlightBox}
            sessions={sessions}
            timeScale={timeScale}
            users={users}
            userDomain={userDomain}
            aggregatedKeyEvents={aggregatedKeyEvents}
            mostActiveUserChangeEvents={mostActiveUserChangeEvents}
          />
        </Column>
      </Column>
    </Panel>
  );
};

const Column = styled(Pane)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const ScrollIndicator = styled.div`
  position: absolute;
  height: 25px;
  width: 2px;
  background-color: #333;
  top: -6;
  right: 0;
  transition: margin-right 0.5s ease;
  z-index: 10;
  margin-right: 0;
`;

const RefreshContainer = ({
  filteredEvents,
  filteredUsers,
  highlights,
  HighlightBox,
  aggregatedKeyEvents,
  sessions,
  timeScale,
  users,
  userDomain,
  mostActiveUserChangeEvents
}) => {
  // const [scrollMarker, setScrollMarker] = useState(0);

  // useEffect(() => {
  //   console.log("scrolin", scrollMarker);
  // }, [scrollMarker]);

  const ScrollPaneComponent =
    timeScale === TIME_SCALE.RAW ? ScrollPane : ScrollPane2;

  return (
    <Column height="100%" position="relative">
      {/* <ScrollIndicator */}
      {/*   scrollMarker={scrollMarker} */}
      {/*   style={{ marginRight: `${scrollMarker}%` }} */}
      {/* /> */}
      {/* <Pane display="flex" position="absolute" left={-23} right={-23} top={-6}> */}
      {/*   {highlights.regimes.global.map((regime, i) => ( */}
      {/*     <Pane */}
      {/*       height={6} */}
      {/*       marginLeft={1} */}
      {/*       marginRight={1} */}
      {/*       key={i} */}
      {/*       width={`calc(${regime._percentage * 100}% - 2px)`} */}
      {/*       backgroundColor={`hsl(156, 43%, ${regime._rankPercentage}%)`} */}
      {/*     /> */}
      {/*   ))} */}
      {/* </Pane> */}
      <ScrollPaneComponent
        filteredEvents={filteredEvents}
        filteredUsers={filteredUsers}
        HighlightBox={HighlightBox}
        aggregatedKeyEvents={aggregatedKeyEvents}
        mostActiveUserChangeEvents={mostActiveUserChangeEvents}
        sessions={sessions}
        users={users}
        userDomain={userDomain}
        timeScale={timeScale}
      />
    </Column>
  );
};

const SessionBlock = styled(Pane)`
  padding: 8px 0;
  margin: 0 8px;
  font-size: 12px;
  position: relative;
  color: #374151;

  &:after {
    content: "";
    left: 10px;
    right: 0;
    position: absolute;
    height: 1px;
    background-color: #eee;
    top: 0;
    margin-top: ${props => props.afterTop || 15}px;
  }

  svg {
    overflow: visible;
  }
`;

const getUserByEmail = (users, email) => {
  return {
    user: users.find(user => user.email === email),
    index: users.findIndex(user => user.email === email)
  };
};

const capitalise = (str, strict = false, delimiter = " ") => {
  try {
    return !str
      ? str
      : !strict
      ? str[0].toUpperCase() + str.slice(1)
      : str
          .split(delimiter)
          .map(s => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(delimiter);
  } catch (e) {
    return capitalise;
  }
};

const ScrollPane2 = ({
  aggregatedKeyEvents,
  mostActiveUserChangeEvents,
  HighlightBox,
  filteredEvents,
  filteredUsers,
  // setScrollMarker,
  sessions,
  timeScale,
  userDomain,
  users
}) => (
  <Pane
    marginTop={24}
    paddingBottom={50}
    position="absolute"
    height="100%"
    overflow="auto"
    marginX={-16}
    paddingX={16}
    top={0}
    bottom={0}
    left={0}
    right={0}
  >
    {Object.keys(sessions[timeScale])
      .sort((a, b) =>
        dateFns.compareDesc(
          dateFns.parseISO(sessions[timeScale][a].isoDate),
          dateFns.parseISO(sessions[timeScale][b].isoDate)
        )
      )
      .filter(sessionDate => {
        if (
          !Object.keys(filteredUsers).length &&
          !Object.keys(filteredEvents).length
        ) {
          return true;
        }

        if (
          Object.keys(filteredUsers).length &&
          !Object.keys(sessions[timeScale][sessionDate].aggregate).some(
            userKey => filteredUsers[userKey]
          )
        ) {
          return false;
        }

        if (
          Object.keys(filteredUsers).length &&
          !Object.keys(sessions[timeScale][sessionDate].aggregate).some(
            userKey => filteredUsers[userKey]
          )
        ) {
          return false;
        }

        if (
          Object.keys(filteredEvents).length &&
          !Object.keys(filteredUsers).length &&
          !Object.keys(
            sessions[timeScale][sessionDate].aggregate
          ).some(userKey =>
            Object.values(
              sessions[timeScale][sessionDate].aggregate[userKey]
            ).some(item => filteredEvents[item.value.event])
          )
        ) {
          return false;
        }

        if (
          Object.keys(filteredEvents).length &&
          Object.keys(filteredUsers).length &&
          !Object.keys(sessions[timeScale][sessionDate].aggregate)
            .filter(userKey => filteredUsers[userKey])
            .some(userKey =>
              Object.values(
                sessions[timeScale][sessionDate].aggregate[userKey]
              ).some(item => filteredEvents[item.value.event])
            )
        ) {
          return false;
        }

        return true;
      })
      .map((sessionDate, i) => {
        const sessionDateItems = Object.keys(
          sessions[timeScale][sessionDate].aggregate
        )
          .sort()
          .filter(userKey => {
            if (
              !Object.keys(filteredUsers).length &&
              !Object.keys(filteredEvents).length
            ) {
              return true;
            }
            if (Object.keys(filteredUsers).length && !filteredUsers[userKey]) {
              return false;
            }
            if (
              Object.keys(filteredEvents).length &&
              !Object.values(
                sessions[timeScale][sessionDate].aggregate[userKey]
              ).some(item => filteredEvents[item.value.event])
            ) {
              return false;
            }
            return true;
          });

        const mappedSessionDateItems = sessionDateItems
          .map((userKey, j) => {
            const userData = getUserByEmail(users, userKey);
            const index = userData.index || 0;
            const _user = userData.user;
            let user = _user;

            if (!_user && users.length && userKey === users[0].group_id) {
              user = {
                ...users[0],
                email: "-",
                traits: {
                  ...users[0].traits,
                  NAME: "Company"
                }
              };
            }

            if (!user) {
              return false;
            }

            const items = Object.values(
              sessions[timeScale][sessionDate].aggregate[userKey]
            )
              .filter(
                item =>
                  !Object.keys(filteredEvents).length ||
                  filteredEvents[item.value.event]
              )
              .map((item, k) => ({
                ...item,
                ...getDisplayPropertiesFromEvent(item.value, true)
              }));

            if (items.every(item => !item.eventLabel)) {
              return false;
            }

            return {
              items,
              user,
              userKey,
              index
            };
          })
          .filter(item => !!item);

        if (!mappedSessionDateItems.length) {
          return false;
        }

        // items, user, userKey, index

        return (
          <SessionBlock key={sessionDate + i}>
            <Pane
              fontWeight={700}
              position="relative"
              zIndex={2}
              fontSize={11}
              paddingBottom={6}
            >
              <Pane is="span" backgroundColor="white" paddingRight={10}>
                <Pane opacity={0.7} is="span">
                  {sessionDate}
                </Pane>
              </Pane>
            </Pane>
            {mappedSessionDateItems.map(
              ({ user, userKey, index, items }, j) => (
                <Pane marginLeft={10} marginBottom={14} key={userKey + j}>
                  <Row fontSize={14} alignItems="center">
                    <Pane
                      width={5}
                      height={5}
                      marginRight={4}
                      borderRadius={20}
                      backgroundColor={COLOR_RANGE[index % COLOR_RANGE.length]}
                    ></Pane>

                    {getUserTraits(user, userDomain).name}
                  </Row>
                  {items.map(({ eventLabel, eventIcon, ...item }, k) => (
                    <Pane
                      display="flex"
                      alignItems="baseline"
                      color="#6B7280"
                      marginTop={3}
                      marginBottom={3}
                      key={k}
                    >
                      <Icon
                        icon={eventIcon.icon}
                        size={9}
                        color={(() => {
                          if (eventIcon.netPolarity === "positive") {
                            return "success";
                          }
                          if (eventIcon.netPolarity === "negative") {
                            return "warning";
                          }
                          return "muted";
                        })()}
                        marginLeft={12}
                        marginRight={6}
                        title={eventIcon.title}
                      />
                      <EventText className={eventIcon.netPolarity}>
                        {eventLabel}{" "}
                        {item.frequency > 0 && (
                          <Pane
                            is="span"
                            opacity={0.5}
                            marginLeft={3}
                            fontWeight={700}
                            fontSize={11}
                          >
                            x{item.frequency + 1}
                          </Pane>
                        )}
                      </EventText>
                    </Pane>
                  ))}
                </Pane>
              )
            )}
          </SessionBlock>
        );
      })}

    {/* {[...aggregatedKeyEvents] */}
    {/*   .filter(ev => { */}
    {/*     if ( */}
    {/*       !Object.keys(filteredUsers).length && */}
    {/*       !Object.keys(filteredEvents).length */}
    {/*     ) { */}
    {/*       return true; */}
    {/*     } */}
    {/*     if (Object.keys(filteredUsers).length && filteredUsers[ev.email]) { */}
    {/*       return true; */}
    {/*     } */}
    {/*     if (Object.keys(filteredEvents).length && filteredEvents[ev.event]) { */}
    {/*       return true; */}
    {/*     } */}
    {/*     return false; */}
    {/*   }) */}
    {/*   .sort((a, b) => */}
    {/*     dateFns.compareDesc( */}
    {/*       dateFns.parseISO(a.timestamp), */}
    {/*       dateFns.parseISO(b.timestamp) */}
    {/*     ) */}
    {/*   ) */}
    {/*   .map((highlight, i) => ( */}
    {/*     <HighlightBox key={i} index={i} highlight={highlight} /> */}
    {/*   ))} */}
  </Pane>
);

const getDisplayPropertiesFromEvent = (highlight, aggregate = false) => {
  let eventLabel;
  let eventIcon = {
    icon: "",
    title: ""
  };

  if (!highlight.event) {
    return {
      eventLabel,
      eventIcon
    };
  }

  if (highlight.type === "PAGE") {
    eventLabel = "Viewed page";
  } else if (
    highlight.event === "CAMPAIGN_RECIPIENT_ACTIVITY" ||
    highlight.event === "received_email" ||
    highlight.event === "sent_email"
  ) {
    eventIcon.icon = "envelope";
    eventIcon.hue = 258;

    if (highlight.event === "CAMPAIGN_RECIPIENT_ACTIVITY") {
      if (aggregate) {
        eventIcon.title = "Email activity (Mailchimp)";
        eventLabel = <span>Email activity (Mailchimp)</span>;
      } else if (highlight.type === "open") {
        eventIcon.title = "Opened email";
        eventLabel = (
          <span>
            Opened email{" "}
            <Pane is="strong">"{highlight.properties.SUBJECT_LINE}"</Pane>
          </span>
        );
      } else if (highlight.type === "click") {
        eventIcon.title = "Clicked link in email";
        eventLabel = (
          <span>
            Clicked link in email{" "}
            <Pane is="strong">"{highlight.properties.SUBJECT_LINE}"</Pane>
          </span>
        );
      }
    } else if (highlight.event === "received_email") {
      if (aggregate) {
        eventIcon.title = "Email received (Salesforce)";
        eventLabel = <span>Email activity (Salesforce)</span>;
      } else {
        eventIcon.title = "Received email";
        eventLabel = (
          <span>
            Received email{" "}
            <Pane is="strong">"{highlight.content.SUBJECT}"</Pane>
          </span>
        );
      }
    } else if (highlight.event === "sent_email") {
      if (aggregate) {
        eventIcon.title = "Email sent (Salesforce)";
        eventLabel = <span>Email activity (Salesforce)</span>;
      } else {
        eventIcon.title = "Sent email";
        eventLabel = (
          <span>
            Sent email <Pane is="strong">"{highlight.content.SUBJECT}"</Pane>
          </span>
        );
      }
    }
  } else if (highlight.event === "provile.viewContactDetails") {
    eventIcon.icon = "person";
    eventIcon.title = "Viewed contact details";
    eventLabel = "Viewed contact details";
  } else if (highlight.event === "Clear All - Search") {
    eventIcon.icon = "search";
    eventIcon.title = "Cleared search";
    eventLabel = "Cleared search";
  } else if (highlight.event === "user.search.loadingMessage.dismiss") {
    eventIcon.icon = "search";
    eventIcon.title = "Search loading message dismissed";
    eventLabel = "Search loading message dismissed";
  } else if (highlight.event === "PUBLISHED_FLOW") {
    eventIcon.icon = "small-tick";
    eventIcon.title = "Published a flow";
    eventLabel = `Published a flow ${
      highlight.metadata.FLOW_NAME ? `"${highlight.metadata.FLOW_NAME}"` : ""
    }`;
  } else if (highlight.event === "CONTENT_CREATED") {
    eventIcon.icon = "add";
    eventIcon.title = "Created a new flow";
    eventLabel = `Created a new flow "${highlight.metadata.FLOW_NAME}"`;
  } else if (highlight.event === "INSTALLED_CRX") {
    eventIcon.icon = "download";
    eventIcon.title = "Installed the CRX";
    eventLabel = `Installed the CRX`;
  } else if (highlight.event === "CLICKED_CREATE_NEW_FLOW") {
    eventIcon.icon = "hand-up";
    eventIcon.title = "Clicked create new flow";
    eventLabel = `Clicked create new flow`;
  } else if (highlight.event === "Search") {
    eventIcon.hue = 209;
    eventIcon.icon = "search";
    eventIcon.title = "Searched";

    if (aggregate) {
      eventLabel = <span>Searched</span>;
    } else {
      eventLabel = (
        <span>
          Searched for
          <Pane is="strong">"{highlight.properties._query}"</Pane>
        </span>
      );
    }
  } else if (
    highlight.event === "search-Keyword" ||
    highlight.event === "search-Name"
  ) {
    eventIcon.hue = 209;
    eventIcon.icon = "search";
    eventIcon.title = "Searched";

    const searchType =
      highlight.properties.SEARCH_TYPE === "Keyword"
        ? "by keyword "
        : "by name ";
    eventLabel = (
      <span>
        Searched {searchType}
        {!aggregate && <Pane is="strong">"{highlight.properties.QUERY}"</Pane>}
      </span>
    );
  } else if (highlight.event === "_QUANTIFAI_CHAMPION_CHANGE") {
    eventLabel = highlight.metadata.EVENT_TEXT;
    eventIcon.icon = "star";
    eventIcon.title = "New champion";
  } else if (highlight.event === "_QUANTIFAI_HEALTH_CHANGE") {
    eventLabel = highlight.metadata.EVENT_TEXT;
    eventIcon.icon =
      highlight.polarity === "positive" ? "trending-up" : "trending-down";
    eventIcon.title = "Health change";
    eventIcon.netPolarity = highlight.netPolarity;
  } else if (
    highlight.event === "Start New Session" ||
    highlight.event === "signin"
  ) {
    eventIcon.hue = 338;
    eventIcon.icon = "log-in";
    eventIcon.title = "Logged in";
    eventLabel = "Logged in";
  } else if (
    highlight.event === "ViewProfile" ||
    highlight.event === "profile.view"
  ) {
    eventIcon.hue = 38;
    eventIcon.icon = "person";
    eventIcon.title = "Viewed profile";

    const profileName = highlight.properties._kolName
      ? highlight.properties._kolName
      : `${highlight.properties.PROFILE_FIRST_NAME} ${highlight.properties.PROFILE_LAST_NAME}`;

    eventLabel = (
      <span>
        Viewed profile {!aggregate && <Pane is="strong">"{profileName}"</Pane>}
      </span>
    );
  } else if (highlight.event === "SUBMITTED_SUPPORT_TICKET") {
    eventIcon.hue = 88;
    eventIcon.icon = "help";
    eventIcon.title = "Submitted a support ticket";
    if (aggregate) {
      eventLabel = `Submitted a support ticket`;
    } else {
      eventLabel = `Submitted a support ticket "${highlight.properties.SUBJECT}"`;
    }
  } else if (highlight.event === "SUBMITTED_A_SUPPORT_TICKET") {
    eventIcon.hue = 88;
    eventIcon.icon = "help";
    eventIcon.title = "Submitted a support ticket";
    if (aggregate) {
      eventLabel = `Submitted a support ticket"`;
    } else {
      eventLabel = `Submitted a support ticket "${highlight.metadata.SUBJECT}"`;
    }
  } else if (highlight.event === "INVITED_A_USER") {
    eventIcon.hue = 88;
    eventIcon.icon = "person";
    eventIcon.title = "Invited a user";
    if (aggregate) {
      eventLabel = `Invited a user "${highlight.metadata.NAME}"`;
    } else {
      eventLabel = `Invited a user`;
    }
  } else if (highlight.properties && highlight.properties.value !== undefined) {
    eventIcon.icon = "refresh";
    eventIcon.title = "Average cost per order changed";

    let _eventName = highlight.event;

    if (highlight.event.includes("_CHANGE_C")) {
      _eventName = highlight.event.slice(
        0,
        highlight.event.indexOf("_CHANGE_C")
      );
    } else if (highlight.event.includes("_C")) {
      _eventName = highlight.event.slice(0, highlight.event.indexOf("_C"));
    } else if (highlight.event.includes("__c")) {
      _eventName = highlight.event.slice(0, highlight.event.indexOf("__c"));
    }

    // _eventName = _eventName
    //   .toLowerCase()
    //   .split("_")
    //   .join(" ");
    // _eventName = _eventName[0].toUpperCase() + _eventName.slice(1);
    // eventLabel = _eventName + " changed to " + highlight.properties.value;

    // eventLabel = `Average cost per order changed to ${highlight.properties.value}`;

    // } else if (highlight.event === "AVERAGE_COST_PER_ORDER_CHANGE_C") {
    //   eventIcon.icon = "refresh";
    //   eventIcon.title = "Average cost per order changed";
    //   eventLabel = `Average cost per order changed to ${highlight.properties.value}`;
  } else if (highlight.type === "CASE") {
    if (highlight.event === "CREATED") {
      eventIcon.hue = 88;
      eventIcon.icon = "help";
      eventIcon.title = "Case created";
      eventLabel = `Case created "${highlight.properties.SUBJECT}"`;
    }
  } else if (highlight.type === "QBR") {
    if (highlight.event === "QBR") {
      eventIcon.hue = 88;
      eventIcon.icon = "bookmark";
      eventIcon.title = "QBR";
      eventLabel = `QBR held`;
    }
  } else if (highlight.type === "TRACK") {
    eventIcon.icon = "select";
    eventIcon.title = highlight.event;
    eventLabel = highlight.event;

    if (highlight.event === "App Open") {
      eventIcon.icon = "globe-network";
    } else if (highlight.event === "Add Mass Payment Success") {
      eventIcon.icon = "dollar";
    }
  } else if (
    highlight.source === "INTERCOM" &&
    highlight.type === "CONVERSATION"
  ) {
    console.log(highlight);
    eventIcon.icon = "help";
    eventIcon.title = "Intercom message";
    eventLabel = `Intercom message "${highlight.properties.MESSAGE_SUBJECT}"`;
  } else {
    // console.log(highlight);
    eventIcon.icon = "dot";
    eventLabel = highlight.event;

    if (highlight.event.includes("Open")) {
      eventIcon.icon = "globe-network";
    } else if (highlight.event === "Add Mass Payment Success") {
      eventIcon.icon = "dollar";
    } else if (highlight.event === "NPS") {
      eventIcon.icon = "comment";
    }
  }

  return {
    eventLabel,
    eventIcon
  };
};

const ScrollPane = ({
  aggregatedKeyEvents,
  mostActiveUserChangeEvents,
  HighlightBox,
  filteredEvents,
  filteredUsers
  // setScrollMarker
}) => (
  <Pane
    marginX={-16}
    marginTop={24}
    position="absolute"
    height="100%"
    overflow="auto"
    top={0}
    bottom={0}
    left={0}
    right={0}
    // onScroll={({ currentTarget }) =>
    //   setScrollMarker(
    //     Math.round((100 * currentTarget.scrollTop) / currentTarget.scrollHeight)
    //   )
    // }
  >
    {[...aggregatedKeyEvents, ...mostActiveUserChangeEvents]
      .filter(ev => {
        if (
          !Object.keys(filteredUsers).length &&
          !Object.keys(filteredEvents).length
        ) {
          return true;
        }
        if (Object.keys(filteredUsers).length && filteredUsers[ev.email]) {
          return true;
        }
        if (Object.keys(filteredEvents).length && filteredEvents[ev.event]) {
          return true;
        }
        return false;
      })
      .sort((a, b) =>
        dateFns.compareDesc(
          dateFns.parseISO(a.timestamp),
          dateFns.parseISO(b.timestamp)
        )
      )
      .map((highlight, i, highlights) => (
        <HighlightBox
          key={i}
          index={i}
          highlight={highlight}
          highlights={highlights}
        />
      ))}
  </Pane>
);

const Domain = styled.div`
  font-size: 11px;
  color: #a0a9c5;
`;

const CompanyLogo = styled.img`
  border-radius: 6px;
  max-width: 38px;
  max-height: 38px;
  margin-left: 21px;
`;

export default AccountDetail;
