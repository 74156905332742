import Dexie from "dexie";
import axios from "axios";
import * as dateFns from "date-fns";
import { useState, useEffect } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { API_HOST } from "../constants";

const LOCAL_ONLY = false;
const VERSION = 7;

const db = new Dexie("QuantifaiDB");
db.version(1).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value"
});
db.version(2).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value"
});
db.version(3).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value",
  userPreferences: "id, value"
});
db.version(4).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value",
  userPreferences: "id, value",
  owners: "id,value"
});
db.version(5).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value",
  userPreferences: "id, value",
  owners: "id,value",
  emails: "id,value"
});
db.version(6).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value",
  userPreferences: "id, value",
  owners: "id,value",
  emails: "id,value",
  threads: "id,value"
});
db.version(7).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value",
  userPreferences: "id, value",
  owners: "id,value",
  emails: "id,value",
  threads: "id,value",
  calendar: "id,value"
});
db.version(8).stores({
  accounts: "id,value",
  account: "id,value",
  highlights: "id,value",
  behaviors: "id,value",
  userPreferences: "id, value",
  owners: "id,value",
  emails: "id,value",
  threads: "id,value",
  calendar: "id,value",
  auth: "id,value"
});
db.version(8).stores({
  accounts: "id,value,date",
  account: "id,value,date",
  highlights: "id,value,date",
  behaviors: "id,value,date",
  userPreferences: "id,value",
  owners: "id,value,date",
  emails: "id,value,date",
  threads: "id,value,date",
  calendar: "id,value,date",
  auth: "id,value,date"
});
db.version(9).stores({
  accounts: "id,value,date",
  account: "id,value,date",
  highlights: "id,value,date",
  behaviors: "id,value,date",
  userPreferences: "id,value",
  owners: "id,value,date",
  emails: "id,value,date",
  threads: "id,value,date",
  calendar: "id,value,date",
  auth: "id,value,date",
  tickets: "id,value,date"
});

const LOGGING = true;

export const getLocalData = async (table, id) => await db[table].get(id);

export const setLocalData = (table, id, value) => {
  db[table].add({ id, value }).catch(e => db[table].put({ id, value }));
};

export const useFetchAPI = (
  table,
  path,
  initialValue,
  options = {},
  condition = true,
  refresh = null
) => {
  const { getTokenSilently } = useAuth0();
  const [data, setData] = useState(initialValue);

  const getData = async () => {
    const allOptions = {
      method: "GET",
      body: null,
      identifier: null,
      fetchOnly: false,
      getDate: false,
      autoRefresh: false,
      ...options
    };

    const token = await getTokenSilently();
    await fetchData(
      table,
      path,
      allOptions.method,
      allOptions.body,
      allOptions.identifier,
      token,
      allOptions.fetchOnly,
      allOptions.getDate,
      allOptions.autoRefresh,
      refresh,
      setData
    );
  };

  useEffect(() => {
    if (!!condition) {
      getData();
    }
  }, [condition, refresh]);

  return data;
};

export const fetchData = async (
  table,
  path,
  method = "GET",
  body = null,
  identifier = null,
  token = null,
  fetchOnly = false,
  getDate = false,
  autoRefresh = false,
  refresh = 0,
  callback = null
) => {
  let pathWithVersion = path;
  if (pathWithVersion.includes("?")) {
    pathWithVersion += "&";
  } else {
    pathWithVersion += "?";
  }
  pathWithVersion += "v=" + VERSION;

  let pathWithVersionAndIdentifier = pathWithVersion;
  if (identifier) {
    pathWithVersionAndIdentifier = pathWithVersion + identifier;
  }

  if (LOGGING) {
    console.log("LOG: Fetching data [1]", pathWithVersionAndIdentifier);
  }

  const options = {};
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  const items =
    !fetchOnly && (await db[table].get(pathWithVersionAndIdentifier));
  if (items) {
    if (LOGGING) {
      console.log("LOG: Fetching data [2]", pathWithVersionAndIdentifier);
      console.log("items", items);
    }

    const stale =
      !items.date ||
      !!refresh ||
      dateFns.differenceInHours(
        Date.now(),
        dateFns.fromUnixTime(items.date / 1000)
      ) > 0;

    if (!LOCAL_ONLY && stale) {
      if (method === "POST") {
        axios
          .post(API_HOST + pathWithVersion, body, options)
          .then(responseJson => {
            const items = {
              id: pathWithVersionAndIdentifier,
              value: responseJson.data,
              date: Date.now()
            };
            db[table].put(items);

            if (autoRefresh) {
              if (getDate) {
                callback(items);
              } else {
                callback(items.value);
              }
            }
          })
          .catch(e => {
            console.error("Error in fetch POST. Unable to save response.", e);
          });
      } else if (method === "PUT") {
        axios
          .put(API_HOST + pathWithVersion, body, options)
          .then(responseJson =>
            db[table].put({
              id: pathWithVersionAndIdentifier,
              value: responseJson.data,
              date: Date.now()
            })
          )
          .catch(e => {
            console.error("Error in fetch PUT. Unable to save response.", e);
          });
      } else {
        axios
          .get(API_HOST + pathWithVersion, options)
          .then(responseJson => {
            const items = {
              id: pathWithVersionAndIdentifier,
              value: responseJson.data,
              date: Date.now()
            };
            db[table].put(items);

            if (autoRefresh) {
              if (getDate) {
                callback(items);
              } else {
                callback(items.value);
              }
            }
          })
          .catch(e => {
            console.error("Error in fetch GET. Unable to save response.", e);
          });
      }
    }

    if (getDate) {
      return callback(items);
    }

    return callback(items.value);
  }

  if (LOGGING) {
    console.log("LOG: Fetching data [3]", pathWithVersionAndIdentifier);
  }

  let responseJson;
  if (method === "POST") {
    responseJson = (await axios.post(API_HOST + pathWithVersion, body, options))
      .data;
  } else if (method === "PUT") {
    responseJson = (await axios.put(API_HOST + pathWithVersion, body, options))
      .data;
  } else {
    const response = await fetch(API_HOST + pathWithVersion, options);
    if (LOGGING) {
      console.log("LOG: Fetching data [4]", pathWithVersionAndIdentifier);
    }
    responseJson = await response.json();
  }
  if (LOGGING) {
    console.log("LOG: Fetching data [5]", pathWithVersionAndIdentifier);
  }
  console.log(responseJson);
  if (
    !responseJson ||
    (responseJson.message &&
      responseJson.message.includes("Internal Server Error"))
  ) {
    return callback(null);
  }

  let itemsRetry = false;

  if (!fetchOnly) {
    db[table]
      .add({
        id: pathWithVersionAndIdentifier,
        value: responseJson,
        date: Date.now()
      })
      .catch(async e => {
        console.log("RETRYING");
        itemsRetry = await db[table].get(pathWithVersionAndIdentifier);
        if (itemsRetry) {
          if (LOGGING) {
            console.log(
              "LOG: Fetching data retry [5.5]",
              pathWithVersionAndIdentifier
            );
            console.log("items", itemsRetry);
          }
        }
      });

    if (itemsRetry) {
      if (getDate) {
        return callback(itemsRetry);
      }

      return callback(itemsRetry.value);
    }
  }

  if (LOGGING) {
    console.log("LOG: Fetching data [6]");
  }

  if (getDate) {
    return callback({ value: responseJson, date: Date.now() });
  }

  return callback(responseJson);
};
